.QwbJ_ {
  height: 58px;
  padding: 0 32px;
}

._2SV9j {
  position: relative;
  padding: 0 16px;
}

._2ntd0 {
  margin-top: -2px;
}

._2dVCY {
  position: absolute;
  left: 24px;
}

._1wC7t {
  position: absolute;
  top: -2px;
  right: 12px;
}

._1UK13 {
  margin-right: 32px;
}
