._3KA2m {
  width: 100%;
  height: 189px;
  background-color: var(--greyLight20);
  border-radius: 8px;
}
._2Xf-2 {
  width: 124px;
  height: 141px;
}
