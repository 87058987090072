._2g0E6 {
  font-weight: 300;
}

._2g0E6.sffEF {
  position: relative;
}

._2g0E6.sffEF:before {
  content: ' ';
  top: 50%;
  left: 0;
  position: absolute;

  width: 100%;
  height: 0;

  border-bottom: 1px solid;
}
