.R_VHY {
  width: 84px;
  height: 18px;
}

._2pzic {
  width: 18px;
  height: 18px;
}

._1v4-x svg {
  width: 110px;
  height: 24px;
}

@media (orientation: landscape) and (max-width: 720px) {
  ._38LPq {
    margin-left: calc(max(15px, env(safe-area-inset-left)) - 15px);
  }
}
