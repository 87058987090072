._4fY37 {
  position: relative;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.GEksn {
  object-fit: contain;
  min-height: 182px;
  border-radius: 8px;
}

._3j_E- span {
  color: var(--grey);
}

._3j_E-:hover span {
  color: var(--black);
}

._3ACXW {
  transform: rotate(270deg);
}

._3asym {
  transform: rotate(90deg);
}
