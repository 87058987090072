.KzuOJ {
  width: 440px;
}

._34gD2 {
  width: 100%;
}

.s193M {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: var(--blue);
  vertical-align: -2px;
}

.s193M._2kLTp {
  color: var(--red);
}

._3ywGz {
  margin: -10px 0 -10px 16px;
}

._--N7k {
  width: 18px;
  margin-right: 8px;
}

._1r00b {
  width: 350px;
  margin: -10px 0 -10px 28px;
}
