._18BqK {
  padding: 16px 8px;
}

._1VSxm {
  margin: 0 6px;
}

@media (max-width: 720px) {
  ._18BqK {
    padding: 16px 24px;
  }
}

@media (orientation: landscape) and (max-width: 720px) {
  ._18BqK {
    padding-left: max(8px, env(safe-area-inset-left));
  }
}
