._1CpFp {
  position: relative;
  padding: 0px 3px 3px 3px;
  border-radius: 12px;
  min-width: 240px;
  width: 240px;
  max-width: 240px;
  box-sizing: border-box;
  min-height: 414px;
  background: transparent;
  z-index: 1;
}

._1CpFp::before,
._2pMor::before,
.pAZJX::before,
.rYcIS::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: 12px;
  z-index: -1;
}

._1CpFp::before {
  opacity: 1;
  background: var(--white);
}

._2pMor::before {
  background: var(--chatfuel-gradient);
  animation: _3_UB3 1.2s;
}

.pAZJX::before {
  background: var(--red);
  animation: _3_UB3 1.2s;
}

.rYcIS::before {
  background: var(--grey);
  animation: _3_UB3 1.2s;
}

._2A8_G {
  border-radius: 10px;
  width: 100%;
  flex: auto;
  background: var(--white);
  position: relative;
}

@keyframes _3_UB3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
