._28quz {
  display: inline-block;
}

.FJTwP {
  border-radius: 1em;
  padding: 5px var(--bubble-padding-horizontal, 12px) 6px;
  margin-right: var(--space-between-bubbles);
  margin-bottom: var(--space-between-bubbles);
  word-break: break-all;
  font-size: 16px;
}

.Svj6d {
  background-color: var(--red);
  color: var(--white);
}

._2Q_Ub {
  background-color: #e9e8e8;
  color: var(--black);
}

._11k8L {
  background-color: transparent;
}
