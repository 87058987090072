._3WQNk {
  width: 100%;
}

.Iz3fb {
  padding: 0.5rem 0;
}

._643WJ {
  width: 24px;
}

._1OiG1 {
  color: var(--c-base-normal);
}

.nB6DH {
  width: 100px;
}

._2V8kf {
  margin-top: 0.25rem;
  width: 100%;
}

._2V8kf td {
  padding: 0.5rem 0.25rem;
}

._2V8kf td:first-child {
  padding-left: 0;
}

._2V8kf td:last-child {
  padding-right: 0;
}
