._1Ji2N,
._1o8WO {
  resize: vertical !important;
  height: auto;
}

._1Ji2N {
  min-height: 37px !important;
  max-height: 120px !important;
}

._1o8WO {
  min-height: 144px !important;
  height: 144px;
}

._3YUNm,
._1BCz3,
._2TbEM {
  width: 105px !important;
}

._1BCz3 div {
  overflow: hidden;
}

._3w-L1 {
  width: auto;
}
