._3AUkW {
  background: var(--chatfuel-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  min-height: 56px;
  overflow: hidden;
}

._189ND {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._1BDDU {
  flex: none;
}

@media (max-width: 720px) {
  ._189ND {
    white-space: normal;
    text-align: center;
  }

  ._1BDDU {
    display: none;
  }
}
