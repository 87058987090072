._3pOpF {
  min-width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: var(--cobaltSub);
}

._2u1EA {
  background-color: var(--blue);
}

._24ku1 {
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
}
