._12z5H {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

._1Cmgd {
  grid-column: 1;
  flex: none;
  position: relative;
  background-color: var(--white);
  min-width: 320px;
  padding: 0 32px;
}

._3wEti {
  width: 100%;
  height: 100%;
}

.ktrgp {
  text-align: center;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
      24.09% 47.38% at 51.21% 65.42%,
      #4ddcb2 5.64%,
      rgba(22, 196, 140, 0) 100%
    ),
    radial-gradient(
      30.92% 12.94% at 50% 19.84%,
      #54e5b7 0%,
      rgba(84, 229, 183, 0) 100%
    ),
    radial-gradient(
      22.62% 25.42% at 34.84% 51.6%,
      #54e5b8 25.44%,
      rgba(84, 229, 184, 0) 100%
    ),
    radial-gradient(
      33.58% 32.06% at 54.02% 34.88%,
      #1ecc94 40.24%,
      rgba(30, 204, 148, 0) 100%
    ),
    radial-gradient(
      27.4% 14.39% at 28.11% 11.54%,
      #92cbff 33.68%,
      rgba(146, 203, 255, 0) 99.48%
    ),
    radial-gradient(
      39.13% 23.64% at 55.18% 9.42%,
      #1ecc94 44.59%,
      rgba(30, 204, 148, 0) 100%
    ),
    radial-gradient(
      29.55% 15.33% at 70.34% 44.02%,
      #0ebc83 35.28%,
      rgba(14, 188, 131, 0) 100%
    ),
    #208ef0;
}

._3aZpc {
  flex-grow: 2;
  padding-top: 68px;
}

._3QXeK {
  width: 300px;
}

._1OC0O {
  flex: none;
  padding: 24px 0 24px;
}

@media (max-width: 768px) {
  ._12z5H {
    grid-template-columns: 100%;
  }

  .ktrgp {
    grid-column: unset;
    display: none;
  }
}

@media (max-width: 1028px) {
  ._1Cmgd {
    padding: 0 16px;
  }
}
