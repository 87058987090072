.p9naA {
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 8px 32px 0 rgba(32, 32, 32, 0.08),
    0 0 0 1px rgba(16, 16, 16, 0.04);
  overflow: hidden;
  z-index: var(--zindex-popover-next);
  width: 100%;
}

.f5GuV {
  display: flex;
  flex-wrap: wrap;
}

._37vuv {
  width: 100%;
  position: relative;
  padding: 4px 12px;
  cursor: text;
}

._29Ia8 {
  position: relative;
}

._3iXX0 {
  padding-bottom: 4px;
  padding-left: 8px;
}

._3iXX0 input {
  height: 20px !important;
  font-size: 15px !important;
}

._2JgVn {
  margin-top: 3px;
  margin-bottom: 5px;
  padding-left: 0;
}

._2B64c {
  color: var(--blue);
}

.HTgU0 {
  width: 100%;
}

._1bQab {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.09px;
  line-height: 16px;
  text-overflow: ellipsis;
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  margin: 1px 4px -17px;
}

._1bQab ._3q7Iv {
  cursor: pointer;
  opacity: 0.3;
}

._1bQab ._3q7Iv:hover {
  opacity: 0.6;
}

._1bQab ._3q7Iv ._3V-r7 {
  text-decoration: none;
  border-bottom: 1px dotted var(--black);
}

._1aNi3 {
  padding: 4px 0;
}

._3Vk-e {
  flex-grow: 2;
  margin-top: -5px;
}
