._2u3Kh {
  margin-bottom: 40px;
}

._36nus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 707px;
  background-color: var(--white);
  padding: 40px 32px;
  border: 1px solid #efeeee;
  box-sizing: border-box;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.ZMohh {
  position: relative;
  width: 100%;
  text-align: left;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

._89zVM {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Om37y {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
}

._3y1pk {
  font-size: 15px;
  line-height: 22px;
  opacity: 0.5;
}

._2ilWw {
  font-size: 12px;
  line-height: 16px;
  color: var(--black);
  margin-top: 8px;
  opacity: 0.4;
}

._3Fnn- {
  margin-top: 24px;
  margin-bottom: 24px;
}

.hBwJF {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 24px;
}

._1MaEq {
  width: 202px;
}

._2H0Db {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

._1E36Y {
  color: var(--blue);
  font-size: 44px;
  font-weight: bold;
  line-height: 48px;
  margin-right: 20px;
  position: relative;
  top: -6px;
}

._3Lij3 {
  /* sorry this is the difference between 1 and 2 */
  position: relative;
  left: -5px;
}
