.PzMrF {
  padding: 4px;
  height: 44px;
  background-color: var(--white);
  border-radius: 4px;
  align-items: stretch;
}

.GoOt_ {
  min-width: 160px;
  padding: 0 8px;
  height: 36px;
  border-radius: 4px;
  flex-grow: 2;
}

.GoOt_._3vCQ0 {
  min-width: 130px;
}

.GoOt_._3t1P8._3GbiG {
  background: linear-gradient(238.9deg, #6358de 1.4%, #208ef0 100%);
}

.GoOt_._2yziX._3GbiG {
  background: linear-gradient(251.68deg, #cb39a6 0%, #fe5440 50%, #ffd854 100%);
}
