._398No {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

._1kzve {
  margin-top: 2em;
  margin-bottom: 2em;
  padding-left: 20px;
}

._1kzve > li + li {
  margin-top: 1em;
}
