._3XtUA {
  --border-radius: 12px;
  --border-radius-joint: 4px;

  border-radius: var(--border-radius);
  white-space: break-spaces;
  position: relative;
  overflow: hidden;
}

._3muTH {
  cursor: pointer;
  max-height: 350px;
  max-width: calc(min(800px, 100%));
  object-fit: cover;
  object-position: 50% 50%;
  float: right;
}

/**
 * By western convention, outgoing messages are aligned to the right edge,
 * and incoming messages are alinged to the left edge
 */
._3l2q4 {
  border-bottom-right-radius: var(--border-radius-joint);
  border-top-right-radius: var(--border-radius-joint);
}

._2e1Qn {
  border-bottom-left-radius: var(--border-radius-joint);
  border-top-left-radius: var(--border-radius-joint);
}

._3l2q4._10AkA {
  border-top-right-radius: var(--border-radius);
}

._3l2q4.qLyAj {
  border-bottom-right-radius: var(--border-radius);
}

._2e1Qn._10AkA {
  border-top-left-radius: var(--border-radius);
}

._2e1Qn.qLyAj {
  border-bottom-left-radius: var(--border-radius);
}

._340y3 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

._3l2q4._2WOqK {
  background-color: var(--blue);
  color: var(--white);
}
