._2rztb {
  display: flex;
}

._3MFdE {
  padding: 62px 40px 40px 12px;
  flex: 1;
}

._2j9q5 {
  background: var(--white);
  padding: 62px;
  padding-top: 77px;
  border-radius: 0 12px 12px 0;
  display: flex;
  flex-direction: column;
  flex: 1.7;
}

.m27-8 {
  margin-top: -32px;
}

._2BCYO {
  width: calc(50% - 16px);
  margin-top: 7px;
}

._2sQ0Q {
  width: 100%;
  margin-top: 7px;
}

._3gcgW {
  margin-left: auto;
}

.aKrLv {
  width: 100%;
  padding-left: 12px;
}

._2Frrd {
  margin-top: 12px;
}

.aKrLv input {
  background: transparent;
}

.aKrLv input:disabled {
  opacity: 0.7;
}

@media (max-width: 720px) {
  ._2rztb {
    flex-direction: column;
  }

  ._1xxfI {
    font-size: 24px !important;
    line-height: normal !important;
  }

  ._3502k {
    font-size: 15px !important;
    line-height: normal !important;
    font-weight: 500 !important;
  }

  ._3MFdE {
    align-items: center;
    text-align: center;
    padding: 20px;
    flex: none;
  }

  ._2j9q5 {
    border-radius: 0;
    padding: 32px 20px;
    flex: auto;
  }

  .m27-8 {
    margin-top: -16px;
  }

  ._2BCYO {
    width: 100%;
  }

  ._2BCYO,
  ._2sQ0Q {
    margin-top: 16px;
  }
}
