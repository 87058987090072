._3ylxL {
  padding: 12px 8px;
}

._2KQcE {
  padding: 2px;
}

._3AhXJ {
  padding: 2px 12px;
}
