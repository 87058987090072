._3OAkh {
  display: flex;
  align-items: center;
  color: #373636;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  height: 24px;
  margin: 10px 0 0;
}

._1bod1 {
  color: #c3c2c2;
  margin: 0 8px 0 0;
}

._3UVOr {
  z-index: var(--zindex-popover);
  min-width: unset !important;
  padding: 4px 0 0;
}

._2Sqkz {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  margin: 0 0 -4px -20px;
  padding: 4px 0 0;
}

.zkYOh {
  width: 14px;
  height: 14px;
  opacity: 0.5;
  cursor: pointer;
}

._2TSmQ {
  cursor: pointer;
}
