._2CjDL {
  max-height: 390px;
  padding: 0 2px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

._2CjDL > * {
  margin: 8px 0;
}

._4msu {
  font-size: 15px;
  line-height: 16px;
  color: var(--greyDark);
  margin-top: 16px;
  margin-bottom: 0 !important;
}

._3BMZx {
  margin: 1px 0;
}

._1F0pc {
  justify-content: flex-end;
}

.Z6269 {
  margin-right: 16px;
  font-weight: 600;
}

._1fr2C {
  color: var(--red);
}

@media (max-width: 720px) {
  ._2CjDL {
    max-height: none;
  }

  ._2ZnhK {
    width: 100%;
  }
}
