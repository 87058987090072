._2Cba1 {
  color: var(--red);
  --stroke-color: var(--white);
}

@media (max-width: 720px) {
  ._2Cba1 {
    left: 6px;
  }
}
