.oYdwj {
  z-index: var(--zindex-tooltip);
}

._2No4X {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: var(--white);
  height: 427px;
  width: 354px;
}

/*
 * These 4 styles below are needed to fix a bug in chrome
 * details: https://github.com/missive/emoji-mart/issues/435#issuecomment-706586380
 */
.emoji-mart-emoji {
  position: initial !important;
}

.emoji-mart-category .emoji-mart-emoji span {
  cursor: pointer !important;
  z-index: none !important;
  position: initial !important;
}

.emoji-mart-category-list > li:hover {
  border-radius: 50% !important;
  background-color: #f4f4f4 !important;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  content: none !important;
}
