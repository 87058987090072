._1q9ql {
  border: none;
  padding: 0;
  color: #666666;
  background-color: transparent;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 600;
}

._1eXbJ {
  vertical-align: middle;
}

.focus-not-visible ._1q9ql:focus {
  outline: none;
}
