.IGAf3 {
  position: relative;
  margin: 0 8px 0 0;
}

.FGegB {
  width: 105px;
  height: 68px;
  border-radius: 6px;
  background-color: var(--blue);
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  padding: 15px 0 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  color: var(--white);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dd58A {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 68px;
  padding: 0px 14px;
  border-radius: 6px;

  background-color: var(--blue);
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

._2WOBC {
  max-width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._3rmsJ {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.23;
  color: var(--white);
  width: max-content;
}

.FGegB._2yiYq {
  pointer-events: none;
  opacity: 0.6;
}

._1ZZ1a {
  margin: 0 auto 7px;
}

._3QbQR {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--zindex-popover);
  width: 241px;
  height: auto;
  border-radius: 4px;
  background-color: #053748;
  padding: 24px;
  font-size: 13px;
  line-height: 1.31;
  color: var(--white);
  transform: translateY(calc(-100% - 11px));
}

._3QbQR::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 40px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 0 11px;
  border-color: #053748 transparent transparent transparent;
}
