._39T-A {
  margin: 0 16px 0 24px;
}

._3tyxc {
  width: 1px;
  height: 20px;
  background-color: var(--grey);
}

._21VjM {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4px;
}
