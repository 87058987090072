._1WoBz {
  gap: var(--unit);
}

._2wfRN {
  margin: 0 -9px;
  transform: rotate(90deg);
}

.PR4nD {
  margin: 0 -9px;
  transform: rotate(-90deg);
}
