._23QNI {
    display: inline-block;
}

._2uSgi {
  margin-bottom: -12px!important;
}

._2lehE {
  background-color: var(--c-base-extralight);
  border-radius: 4px;
  padding: 12px;
}
