.oKOCi {
  flex: none;
}

._258jB {
  margin-top: 44px;
  margin-bottom: 26px;
  gap: 60px;
}

._2Azcs {
  gap: 12px;
}

.ryzOy {
  width: 100%;
}

._1xkLf {
  background-color: var(--c-base-extralight);
}

._1NX4W {
  padding: 0 20px;
}

.N1chf {
  margin-top: 100px;
  margin-left: auto;
}

._19rUk {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 100px;
}

@media (max-width: 720px) {
  ._258jB {
    margin-top: 18px;
    margin-bottom: 38px;
    gap: 38px;
  }

  ._2Azcs {
    gap: 16px;
  }

  .oKOCi {
    margin-left: auto;
    width: 116px;
    order: 1;
  }

  .N1chf {
    margin-top: 10px;
    margin-left: 0;
  }

  ._3q0Po {
    flex: auto;
  }
}
