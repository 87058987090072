._29Rm3 {
  border: 1px solid rgba(16, 16, 16, 0.08);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 8px 16px 16px;
  flex-shrink: 0;
}

._2dYLY {
  line-height: 24px;
  -webkit-appearance: none;
          appearance: none;
  border: none;
  resize: none;
  background-color: transparent;
  outline: none;
  flex: 1 1 auto;
  align-self: center;
  max-height: 200px;
  margin-left: 12px;
}

button._2CL-0._2CL-0 {
  min-width: 70px;
}
