._30E4l {
  padding: 0 20px;
}

.vPsMp {
  background-color: var(--c-base-extralight);
  color: var(--c-accent1-normal);
}

.vPsMp:enabled:hover,
.vPsMp:hover {
  background-color: var(--c-accent1-light);
}

._3tnvQ {
  color: var(--c-accent1-normal);
}

.NdiNK {
  pointer-events: none;
  opacity: 0.3;
}
