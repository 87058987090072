.bxmaM {
  padding: 0 12px;
  height: 100%;
}

._1e0Uc {
  flex: auto;
  overflow: hidden;
  height: 100%;
}

._3Cz-R,
._1q1S9 {
  margin-top: -2px !important;
  margin-right: 12px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

._3Cz-R {
  margin-left: auto !important;
}

.oMho8 {
  width: 32px;
  height: 32px;
}
