._1Q742 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 2px 12px 6px 12px;
}

._1Q742 input {
  font-size: 15px;
  color: black;
  border: none;
  padding: 0;
}

._1Q742 input::placeholder {
  color: var(--grey);
  opacity: 1;
}

._1Q742 input {
  display: flex;
  padding-right: 4px;
  padding-top: 4px;
}

._1hkSq {
  margin-right: 4px;
  margin-top: 4px;
}

._1OXOC {
  display: inline-block;
  vertical-align: middle;
  background-color: #eee;
  border-radius: 4px;
  padding: 2px 5px;
}

._1anIi {
  resize: vertical !important;
  min-height: 290px;
  height: 290px;
}
