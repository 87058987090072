._3awoy {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

._1KkaN {
  height: 100%;
  width: 100%;
}
