.oh5Bo {
  min-height: 75px;
  min-width: 220px;
  width: 100%;
  background-color: var(--greyLight30);
  border-radius: 4px;
  padding: 8px 4px 0 8px;
  align-items: center;
  justify-content: center;
}

._9oZD {
  width: 24px;
  height: 24px;
  background: var(--white);
  box-shadow: 0 1px 5px rgba(77, 77, 77, 0.1);
  border-radius: 4px;
  margin: 0 10px 8px 0;
}

._2W4kl {
  object-fit: cover;
  width: 12px;
  height: 12px;
}

.YJ6aU {
  flex: none;
  transform: rotate(-90deg);
  margin: -8px -3px 0 -13px;
}
