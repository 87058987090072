._3F5jk {
  width: 100%;
}

._3iVFd {
  flex: auto;
}

._2tjpI {
  width: 100%;
}

@media (max-width: 720px) {
  ._2tjpI {
    padding: 0 16px;
  }
}
