.j6jEn {
  flex: none;
  width: 100%;
  background-color: var(--c-base-normal) !important;
  padding: 0 !important;
  overflow: hidden;
  border-radius: 12px;
}

._1FBFc {
  display: inline-block;
  margin: 28px 32px;
}

._3FEi7 {
  flex: none;
}

@media (max-width: 1024px) {
  ._3FEi7 {
    display: none;
  }
}
