._2eJDf {
  --border-radius: var(--message-border-radius, 18px);
  --border-radius-joint: var(--message-border-radius-joint, 4px);
  --message-extra-border-color: var(--greyLight20);

  border: 1px solid var(--message-extra-border-color);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

._1ub1q {
  border-bottom-left-radius: var(--border-radius-joint);
}

._1blTY {
  border-bottom-right-radius: var(--border-radius-joint);
}

.xyTWC {
  background: var(--greyLight20);
}

._30PvE {
  height: 1px;
  background-color: var(--message-extra-border-color);
}
