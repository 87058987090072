.H9ZHF {
  margin-bottom: 16px;
}

._25Z5Q {
  display: inline-block;
  font-size: 15px;
  line-height: 16px;
  color: var(--black);
  margin-bottom: 8px;
  font-weight: 500;
}
