._291j8 {
}

.UCTqM {
  padding: 48px 0 16px;
}

.Zo9Yl {
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
  font-weight: 500;
}

._3OstF {
  width: 1080px;
  margin: 0 auto;
  padding: 64px 24px 48px 32px;
}

._3QvAY {
  font-size: 15px;
  line-height: 16px;
  color: var(--grey);
  padding-left: 4px;
}

._1Pd0d {
  background-color: var(--white);
  border-radius: 4px;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

._1Pd0d tr:not(:last-child),
._1Pd0d thead tr {
  border-bottom: 1px solid #f1f1f1;
}

._1Pd0d th {
  height: 48px;
  width: 25%;

  font-size: 18px;
  line-height: 24px;
  color: var(--black);
  padding: 0 24px;
  font-weight: 500;
}

._1Pd0d td {
  padding: 0 24px;
}

._1Pd0d tfoot {
  background-color: var(--greyLight30);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

._1Pd0d tfoot td {
  height: 72px;
  font-size: 18px;
  line-height: 24px;
  color: var(--grey);
}

._1Pd0d tbody td {
  font-size: 18px;
  line-height: 24px;
  color: var(--black);
  padding-top: 18px;
  padding-bottom: 18px;
}

._3JeKM {
  color: var(--black);
}

._1Pu3r {
  color: var(--blue);
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
}

.rM5mI {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
}

._1Pu3r:hover {
  text-decoration: underline;
}

._2b3Qo {
  margin-bottom: 8px;
}

._2JgSA {
  font-size: 15px;
  line-height: 20px;
  color: var(--grey);
}
