.s44JE {
  width: 100px;
  height: 100px;
  margin: 44px auto 64px;
  display: block;
}

.jukU8 {
  font-size: 23px;
  line-height: 26px;
  color: var(--black);
  margin-bottom: 24px;
}
._2kT38 {
  font-size: 15px;
  line-height: 20px;
  color: var(--greyDark);
  margin-bottom: 40px;
}
