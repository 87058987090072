._5jyLv {
  --space-between-bubbles: 4px;
  --bubble-padding-horizontal: 12px;

  padding-top: var(--space-between-bubbles);
  margin-left: var(--space-between-bubbles);
  line-height: 21px;
}

._2eSFI {
  pointer-events: none;
  position: absolute;
  left: calc(
    var(--bubble-padding-horizontal) + 2px
  ); /* 2px - padding for cursor*/
  right: var(--bubble-padding-horizontal);
  white-space: nowrap;
  overflow: hidden; /* do not ellipsize, because ellipsis has different color */
}
