.Fbt67 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 24px;
}

._32PSI {
  margin: 16px 0 0;
  position: relative;
}
