._3Kws5 {
  position: absolute;
  z-index: var(--zindex-over-content);
  bottom: 16px;
  right: 24px;
}

div._3FSrD {
  width: 600px;
  height: 528px;
  overflow: hidden;
}

div._3C27i {
  padding: 0;
  height: 100%;
}
