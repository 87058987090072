._26OMv {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: var(--blueLight30);
  border-radius: 4px;
}

._1REov {
  padding: 16px;
}

._3rWwP {
  max-height: 42px;
  padding: 12px 20px;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

._3PVnh {
  margin-left: 10px;
}

@media (max-width: 720px) {
  ._3rWwP {
    max-height: none;
    width: 100%;
  }
}
