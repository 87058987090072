.uoVYl {
  width: 100%;
  background-color: var(--white);
  border-radius: 4px;
  padding: 20px 24px;
}

._3mtT- {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._2CrVt {
  transform: rotate(180deg);
  transition-duration: 200ms;
}
