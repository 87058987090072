._312A9 {
  margin: -10px 0;
}

._2xyMh svg {
  width: 18px;
  height: 18px;
}

/* восклицательный знак в иконке warning */
._2xyMh path:nth-child(2) {
  fill: var(--cobaltSub);
}

@media (max-width: 1024px) and (min-width: 721px) {
  ._312A9 {
    width: 44px;
    height: 44px;
    margin: 0;
    background-color: var(--redSemilight);
    border-radius: 4px;
  }

  ._2xyMh svg {
    width: 28px;
    height: 28px;
  }

  ._2xyMh path:nth-child(2) {
    fill: var(--redSemilight);
  }
}
