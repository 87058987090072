.tTDxd {
  display: flex;
  flex-direction: column;

  --button-size: 28px;
  --total-width: 303px;
}

._3Laj7 {
  margin-bottom: calc(2 * var(--unit));
  display: flex;
}

.aSU_F {
  width: 100px;
}

.fhrLF {
  font-size: 12px;
  line-height: 16px;

  color: var(--greyDark);
}

._3qvgH {
  font-size: 15px;
  line-height: 21px;
  color: var(--black);
}

._1MaqD {
  width: calc(var(--total-width) + var(--unit) + var(--button-size));
  position: relative;
}

._3-s7i {
  width: var(--total-width);
  background-color: var(--greyLight30);
  border-radius: 12px;

  padding: calc(4 * var(--unit));

  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
}

._3qohS {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: calc(4 * var(--unit));
}

._1n8G_ {
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;

  min-height: 86px;
}

._2xcWj {
  display: none;
  position: absolute;
  width: var(--button-size);
  height: var(--button-size);
  min-height: var(--button-size);
  top: 0;
  right: 0;
}

._2xcWj span span {
  margin: 0;
}

._1MaqD:hover ._2xcWj {
  display: inline-block;
}
