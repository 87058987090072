._3NWLY {
  --popupWidth: 616px;
  width: var(--popupWidth);
  height: 528px;
}

._1FOtq {
  width: var(--popupWidth) !important;
}

.LUqrp {
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  align-items: center;
}

._1khDq {
  flex-grow: 1;
  margin: 0 16px 0 0;
}

._1gfd0 {
  pointer-events: none;
  opacity: 0.3;
}

._3sRL_ {
  font-size: 15px;
  line-height: 16px;
  color: #919191;
  margin: 0 0 16px;
}

._2jU2E {
  position: relative;
  font-size: 15px;
  line-height: 16px;
  color: #919191;
  margin: 0 0 12px;
}

.UoQUD {
  position: absolute;
  right: -6px;
  top: -10px;
  background-color: transparent !important;
  box-shadow: none !important;
}
