._3IyR6 {
  width: 400px;
  height: 44px;
  font-size: 24px;
  font-weight: bold;
}

._2_EQ7 {
  width: 100%;
}

@media (max-width: 720px) {
  ._18eh- {
    width: 100%;
  }

  ._3IyR6 {
    width: 100%;
    height: 36px;
    font-size: 15px;
  }
}
