._2490R {
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  background-color: var(--white);
}

._7EKpQ,
._1Fo_l {
  background-color: var(--greyLight20);
}

._1Fo_l {
  box-shadow: none;
}

._2UxkC {
  color: var(--greyDark);
}
