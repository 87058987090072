._3P8nc {
  display: contents;
}

._3q8CX {
  grid-column: message;
  overflow: hidden;
}

.n0Fp3 {
  max-width: 100%;
  min-height: 28px;
  background-color: var(--greyLight10);
  border-radius: 16px;
  padding: 4px 12px 5px;
}

._29u7D {
  grid-column: frequency;
}

._3G8sc {
  grid-column: triangle;
}

._35x3E {
  width: 294px;
}

._30GeA {
  position: relative;
  grid-column: selectorsBox;
}

._1Jj5y {
  width: 100%;
}

._1G4vf {
  position: absolute;
  z-index: var(--zindex-popover);
  top: 50%;
  left: 100%;
  height: 36px;
  margin-top: -18px;
}

._1lHFC {
  display: contents;
  cursor: default;
}

._3-Cej {
  flex-grow: 1;
}

.PEAHR {
  height: 1px;
  width: 100%;
  background-color: var(--greyLight10);
}

@media (max-width: 830px) {
  ._30GeA {
    grid-column: unset;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  ._1Jj5y {
    flex-direction: column;
  }
}
