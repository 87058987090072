.n0UUg {
  position: relative;
  width: 560px;
}

._2SrdY {
  height: calc(560px - 96px); /* dialog height - distance to its top */
  min-height: calc(560px - 96px);
  padding: 0 1px;
}
._2SrdY::-webkit-scrollbar {
  display: none;
}
._2SrdY {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

._10YIc {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

._1wEh7 {
  width: 227px;
}

.hmzRm {
  height: 22px;
  max-height: 22px;
  box-sizing: border-box;
}

._3uwnq {
  height: 36px;
  width: 227px;
}

._3uwnq input::-webkit-outer-spin-button,
._3uwnq input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

._3uwnq input[type='number'] {
  -moz-appearance: textfield;
}

._2qRKc {
  position: relative;
}

._1GBiU {
  transform: rotateX(180deg);
}

._1SSwH {
  position: absolute;
  left: 0;
  top: 37px;
  z-index: 1;
}

._3jPG4 {
  width: 100%;
  height: 36px;
  border: 1px dashed var(--grey);
  border-radius: 4px;
}

._1A6R0 {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
