._3zAE8 {
  padding: 20px;
  width: 100%;
}

.bxS6T {
  gap: 16px;
  opacity: 1 !important;
}

._3wod8 {
  min-width: 340px;
  max-width: 880px;
  width: 100%;
  height: min-content;
}

._2h766 {
  position: relative;
}

._2h766:after {
  content: '';
  display: block;
  position: absolute;
  z-index: var(--zindex-over-content);
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
}

._2h766 > * {
  opacity: 0.5;
}

._2s1_O {
  width: 100%;
}

@media (max-width: 830px) {
  .bxS6T {
    flex-direction: column;
    align-items: stretch;
  }
  ._2h766:after {
    top: 100px;
  }
}
