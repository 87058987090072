._3S_Y4 {
  width: 520px;
}

._1q9Fs {
  top: 34px;
  right: 34px;
}

._21nxD {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 192px;
  margin: 0 0 24px;
}

._6Mm20 {
  height: 156px;
}

._1y_uA {
  padding: 16px 0;
  text-align: center;
}

.uuwY8 {
  font-size: 15px;
  color: var(--greyDark);
  line-height: 1.33;
  padding: 16px 0;
  width: 256px;
  margin: 0 auto;
}

._1Ql46 {
  padding: 0 40px 16px;
}

._190qr {
  overflow-y: auto;
  height: 396px;
}

._3x1qi {
  width: 415px;
  align-items: end;
}
