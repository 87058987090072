.KHvXz {
  background-color: var(--blueLight20);
  padding: 4px 8px;
}

.KHvXz,
._1IPQ3 {
  border-radius: 12px;
}

._1dhFO {
  padding: 4px 0;
}

.GvWoz {
  margin-bottom: 4px;
}

._2BmhG {
  padding: 0 16px;
  border-left: 1px solid var(--blueLight20);
}
