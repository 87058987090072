.U0F5o {
  width: 230px;
}

._3LdQO {
  background-color: var(--white);
  border: 1px solid var(--greyLight30);
  border-radius: 50%;
}

._3IHxq {
  margin-top: auto;
}

.T6Nbj {
  width: 100%;
  height: 100%;
  margin: auto;
}

._3sA_F > svg {
  width: 24px;
  height: 24px;
}

._2w84P {
  width: 100%;
  height: 170px;
  background: var(--blueLight30);
  border-radius: 8px;
}

._2w84P:focus,
._2w84P:hover {
  background-color: var(--blueLight30);
  border: none;
}

@media (max-width: 720px) {
  ._2w84P {
    height: 120px;
  }
}
