._2ar06 {
  box-shadow: var(--c-box-shadow);
  border-radius: 12px;
  padding: 20px 24px 0px;
  background-color: var(--white);
}

._2P0SH {
  min-height: 68px;
  padding-top: 14px;
}

._2P0SH:not(:last-child) {
  border-bottom: 1px solid var(--greyLight10);
}

._2XuAX {
  margin-bottom: 16px;
}

._2XuAX:last-child {
  margin-bottom: 20px;
}

._1_hJW {
  width: 35%;
  flex: none;
}

.Y4OE- {
  overflow: hidden;
  text-overflow: ellipsis;
}

._2OtmL {
  margin: auto 0 !important;
}

._1XpnF {
  background: var(--red);
}

._1-KIa {
  min-width: 36px !important;
  padding: 10px;
}

._1AkZP {
  display: inline-block;
}

@media (max-width: 720px) {
  ._2ar06 {
    width: 100%;
  }
}
