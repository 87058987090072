._1JM3D {
  position: relative;
  width: 240px;
  padding: 0px 3px 3px 3px;
  flex-shrink: 0;
  border-radius: 12px;
  box-sizing: border-box;
  background: transparent;
  z-index: 1;
  border: 1px solid #f3f2f2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.e7v91 {
  border-radius: 10px;
  width: 100%;
  flex: auto;
  background: var(--white);
  position: relative;
}

._1JM3D::before,
._25mmf::before,
._3QHml::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: 12px;
  z-index: -1;
}

._1JM3D::before {
  opacity: 1;
  background: var(--white);
}

._25mmf {
  border: 1px solid transparent;
  box-shadow: none;
}

._25mmf::before {
  background: var(--chatfuel-gradient);
  animation: _3ZacS 1.2s;
}

._3QHml::before {
  background: var(--grey);
  animation: _3ZacS 1.2s;
}

@keyframes _3ZacS {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

._2CZ_g {
  margin: 0;
  padding-left: 24px;
  width: 192px;
}

._1id6o {
  margin-bottom: 4px;
}
