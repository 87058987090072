._185Vw {
  resize: vertical;
  max-height: 290px;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.LmBsQ {
  margin-left: auto !important;
}
