._3STjq {
  width: 456px;
  text-align: center;
}

._3otBP {
  width: 100%;
}

._6tbwR {
  width: 100%;
}
