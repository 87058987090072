._3Q0m4 {
  margin-right: 12px;
}

._2BAC- {
  flex: none;
  width: 18px;
  height: 18px;
}

._3mkah {
  width: 214px;
  white-space: normal;
  word-wrap: break-word;
}

._2Dw-w {
  align-items: flex-start;
  width: 214px;
  height: auto;
}

._2_Idc {
  object-fit: contain;
  width: 100%;
  height: auto;
  display: block;
}
