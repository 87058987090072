:root {
  /* FIXME: need to fix all z-indexes and then this can be removed; */
  --zindex-add: 100000000;

  --zindex-sub-content-background: -3;
  --zindex-sub-content-back: -2;
  --zindex-sub-content-mask: -1;
  --zindex-callout: 2;
  --zindex-over-content-controls: 2;
  --zindex-user-menu: 20;
  --zindex-templates-modal-buttons: 100;
  --zindex-left-navigation-panel: 110;
  --zindex-over-content: calc(2 + var(--zindex-add));
  --zindex-mask: calc(10 + var(--zindex-add));
  --zindex-over-mask: calc(50 + var(--zindex-add));
  --zindex-dropdown: calc(1000 + var(--zindex-add));
  --zindex-navigation: calc(1050 + var(--zindex-add));
  --zindex-popover: calc(1100 + var(--zindex-add));
  --zindex-popover-next: calc(1200 + var(--zindex-add));
  --zindex-popover-next-1: calc(1201 + var(--zindex-add));
  --zindex-left-navigation: calc(1500 + var(--zindex-add));
  --zindex-modal-background: calc(2000 + var(--zindex-add));
  --zindex-modal: calc(2100 + var(--zindex-add));
  --zindex-tooltip: calc(2200 + var(--zindex-add));
  --zindex-overlay: calc(2300 + var(--zindex-add));

  /**
    moved from modal component
    TODO: merge with --zindex-add
  */
  --z-index-base: 100000000;
  --z-index-mini-onboarding-overlay: calc(
    var(--zindex-left-navigation-panel) - 1
  );
  --z-index-modal: calc(var(--z-index-base) + 2000);
  --z-index-modal-scroll: calc(var(--z-index-base) + 2001);
  --z-index-modal-dialog: calc(var(--z-index-base) + 2002);
}
