.OD8Ky,
.Fh1HP,
._3Yk0K {
  background-color: var(--c-base-dark);
  border-radius: 4px;
}

.OD8Ky {
  padding: 20px 24px 24px;
}

.Fh1HP {
  flex: auto;
  overflow: hidden;
}

._1D5O8 {
  min-height: 125px;
  width: 130%;
  margin-left: -20%;
  margin-right: -10%;
}

._184EY {
  margin-top: auto;
  padding: 0 20px 20px;
}

._3Yk0K {
  padding: 16px 20px 20px;
}

._2VrIv {
  margin-right: 20px;
}

.mvNU3 {
  position: relative;
  overflow: hidden;
  background: linear-gradient(240deg, #0e68d9 0%, #002876 100%);
  padding: 24px 20px 20px;
  border-radius: 4px;
}

._1b7a3 {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.BoIe7 {
  position: relative;
  margin-left: 4px !important;
  margin-right: 130px !important;
}

._2nqOA {
  position: relative;
  margin-right: auto;
}

._24u0C {
  color: var(--c-accent1-normal);
}

@media (max-width: 720px) {
  ._2nqOA {
    margin-right: 0;
  }
}
