._36u6h {
  max-width: 182px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

._1MBcf {
  color: #dadada;
}
