.jK0oW {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jK0oW::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  transition: background-color 300ms ease-in-out;
}

.jK0oW:hover::before {
  background-color: var(--grey);
}
