._2pitP {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: unset;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--overlay);
  z-index: var(--z-index-modal);
  padding-top: 10px;
  padding-bottom: 10px;
}

._1I-eh {
  z-index: var(--z-index-modal-dialog);
}

._1I-eh._2QXTP > .pane {
  background: var(--mobile-dialog-background);
}

.BTJ36 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-modal-scroll);
}

[data-reach-dialog-content] {
  outline: none;
  margin: auto;
}
