._37oGt {
  display: flex;
  position: relative;
  background-color: var(--c-base-normal);
  border-radius: 12px;
  animation: _1S_hu 300ms ease-in-out;
}

._2bUum {
  position: relative;
  z-index: 1;
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

._1z3J- {
  position: absolute;
  top: -32px;
  right: -28px;
  z-index: 2;
}

@media (max-width: 720px) {
  ._37oGt {
    display: none;
  }

  ._1z3J- {
    position: relative;
    right: 0;
    padding-top: 0;
    margin: auto;
  }
}

@keyframes _1S_hu {
  0% {
    transform: scale(1, 0.9);
    opacity: 0;
  }
  30% {
    transform: scale(1, 1);
  }
  100% {
    opacity: 1;
  }
}
