.Cz689 {
  margin-bottom: 24px;
}

._1rJHA {
  width: 200px;
}

._1C6za {
  max-width: 840px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.iUxXk {
  color: var(--black);
}

._2OLWz {
  display: flex;
}

.UefTk {
  margin-left: 4px;
}

._1Kmx7 {
  padding-top: 24px;
  background-color: white;
}
