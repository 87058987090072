._2NDZE {
  min-width: 0;
  flex: none;
  justify-content: space-between;
}

._2Hmrs {
  flex: unset;
}

.Ib7cF {
  flex: none;
}

._3HJqF {
  min-width: 0;
  flex: none;
}

._1le-2 {
  flex: unset;
}
