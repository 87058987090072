._1BBpJ {
  margin: 0 auto;

  --c-box-shadow: 0 4px 4px rgb(0 0 0 / 4%), 0 4px 8px rgb(0 0 0 / 4%);
}

._1qsWP {
  flex-grow: 1;
  width: 600px;
}

._1qsWP._2j1R5 {
  min-height: 384px;
}

._1qsWP._1_LUa {
  min-height: 600px;
}

._3U3U3 {
  box-shadow: var(--c-box-shadow);
  border-radius: 12px;
}

._3U3U3._1qsWP {
  flex-shrink: 1;
}

.HBDGy {
  margin: 8px 24px 4px 24px;
}

._3l4Km {
  width: 77px;
}

._3Yiqb {
  width: 320px !important;
  background: white;
  padding: 4px;
}
