._1rqHc {
  background: var(--c-base-extralight);
  padding: 10px 12px 12px 12px;
  border-radius: 8px;
}

.EiHHj {
  resize: vertical;
  min-height: 100%;
  border: 0;
  max-height: 290px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px 12px;
}

._2Ip_B {
  flex-basis: 60px;
  flex-shrink: 0;
}

._3KSQX {
  flex-grow: 1;
  overflow: hidden;
}
