.SHY5O {
  display: flex;
  align-items: center;
  justify-content: center;
}

._1rZCQ {
  width: 8px;
  height: 8px;
}

._2nhuG {
  width: 12px;
  height: 12px;
}

.AIPr8 {
  width: 14px;
  height: 14px;
}

._2duOt {
  width: 16px;
  height: 16px;
}

._2eoa- {
  width: 20px;
  height: 20px;
}

._3S-3- {
  width: 24px;
  height: 24px;
}

._3OPSD {
  width: 28px;
  height: 28px;
}

._9h9f- {
  width: 32px;
  height: 32px;
}

._2mptH {
  width: 52px;
  height: 52px;
}

.TziGt {
  width: 60px;
  height: 60px;
}

.OzLt2 {
  width: 120px;
  height: 120px;
}
