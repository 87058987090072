._3Z5m_ {
  height: 48px;
  width: 60px;
  border-radius: 8px;
  background-color: var(--white);
  color: var(--greyDark);
  font-size: 11px;
  font-weight: 600;
  margin-left: 12px;
  position: relative;
  text-align: center;
  padding-top: 5px;
}

.GoIf1 {
  position: absolute;
  left: 38px;
  top: 32px;
  z-index: 2;
}
