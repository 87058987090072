._3dnWC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  position: relative;
  cursor: pointer;
  padding-right: 24px;
}

._3dnWC:focus {
  outline: none;
}

.wh4MR {
  position: relative;
  min-height: 33px;
}

._3dnWC::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

._3dnWC._21sg4::before,
._3dnWC.DFmHr::before {
  background-color: var(--greyLight30);
}

.yDG9K {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  overflow: hidden;
}

._14jbq {
  color: var(--black);
  outline: none;
}

._1AwGC {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

._1j9zF {
  overflow: hidden;
  max-width: 234px;
}

._3dnWC._21sg4 ._1j9zF,
._3dnWC.DFmHr ._1j9zF {
  max-width: 176px;
}

.ZZlOr {
  display: flex;
  align-items: center;
  position: relative;
  outline: none;
  top: 1px;
  margin-right: 2px;
}

.-CtpI {
  min-height: 4px;
  position: relative;
  padding-bottom: 4px;
}

._1IDwc:focus {
  box-shadow: none !important;
}
