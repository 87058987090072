._2ttg1 {
  --control-decorator-min-height: 36px;
  --control-decorator-border-radius: 4px;

  position: relative;
  /* SuggestUI render popup inside control decorator */
  overflow: visible;

  display: flex;
  min-height: var(--control-decorator-min-height);
  /* border-radius: var(--control-decorator-border-radius); */
  border-top-left-radius: var(
    --input-border-top-left-radius,
    var(--control-decorator-border-radius)
  );
  border-top-right-radius: var(
    --input-border-top-right-radius,
    var(--control-decorator-border-radius)
  );
  border-bottom-left-radius: var(
    --input-border-bottom-left-radius,
    var(--control-decorator-border-radius)
  );
  border-bottom-right-radius: var(
    --input-border-bottom-right-radius,
    var(--control-decorator-border-radius)
  );
  background-color: #fffefe;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._2ttg1.nZHLF {
  box-shadow: none;
}

._2ttg1.mlnXX {
  background-color: #f3f2f2;
}

._2ttg1._3-zIl {
  background-color: var(--cobaltSub);
}

._2ttg1:focus-within._3-zIl,
._2wYzP {
  z-index: 1;
  background-color: var(--cobaltSub);
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4),
    0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._2ttg1:focus-within,
._2wYzP {
  z-index: 1;
  background-color: var(--white);
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4),
    0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._2ttg1:focus-within ::-webkit-input-placeholder,
._2wYzP ::-webkit-input-placeholder {
  color: #adadad;
}

._1SzXC {
  color: #f06040;
  box-shadow: 0 0 0 2px rgba(240, 96, 64, 0.4), 0 0 0 1px rgba(16, 16, 16, 0.08),
    0 1px 0 0 rgba(16, 16, 16, 0.04);
}
._1SzXC ::-webkit-input-placeholder {
  color: #f06040;
}

._1SzXC:focus-within,
._1SzXC._2wYzP {
  color: inherit;
  box-shadow: 0 0 0 2px rgba(240, 96, 64, 0.4), 0 0 0 1px rgba(16, 16, 16, 0.08),
    0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._2TgLu {
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

._12Xq2 {
  display: flex;
  margin: 4px;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  flex: none;
}

._12Xq2 svg {
  display: block;
}

._1vre- {
  opacity: 0.4;
  pointer-events: none;
}

._1jNcs {
  color: var(--grey);
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04) !important;
}
