._1y6Kv {
  opacity: 0.3;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

._1kr5q {
  margin: 16px -24px -24px;
  padding: 20px 24px;
  border-top: 1px solid rgba(16, 16, 16, 0.08);
}

._32xQW {
  color: var(--blue);
  margin-right: 8px;
  margin-top: 3px;
}

._2qIbr {
  margin-left: 8px;
  position: relative;
  bottom: -1px;
}

._3-8i7 {
  width: 100%;
}

._3-8i7 thead {
  margin-bottom: 12px;
}

._1gtbA {
  border-top: 1px solid rgb(236, 236, 236);
  height: 56px;
}

._3-8i7 thead th {
  height: 28px;
  vertical-align: middle;
}

._3-8i7 tbody td {
  vertical-align: top;
  padding-top: 16px;
}

._3-8i7 tfoot tr {
  border-top: 1px solid rgb(236, 236, 236);
}

._3-8i7 tfoot td {
  padding-top: 12px;
}

._2AxWS {
  width: 44px;
  text-align: right;
}

._1O7j8 {
  color: var(--black);
  margin-bottom: 2px;
  text-decoration: underline;
}

._1O7j8:first-letter {
  text-transform: uppercase;
}

._2yKz6 {
  text-align: right;
  padding-right: 24px;
}

.Jcuzy {
  text-align: right;
}

.Z2JTX {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: -3px;
  color: var(--red);
}

.oJf2d {
  padding-top: 20px;
  border-top: 1px solid rgba(16, 16, 16, 0.08);
}
