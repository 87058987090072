._22Wsj,
._22Wsj * {
  pointer-events: none !important;
  cursor: default;
}

._3cUXX {
  text-decoration: none;
  color: var(--white);
  display: block;
}
