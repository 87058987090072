.bbHO5 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-gap: 24px;
}

._1koz_ {
  background: var(--meta-gradient);
}

._30BbY {
  background: var(--c-accent2-tertiary);
}

.d-StM {
  background: var(--youtube-gradient);
}
