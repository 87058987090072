._1fLoo {
  flex: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--c-base-dark);
  overflow: hidden;
  margin-top: 6px;
}

._2qSwu {
  background: var(--instagram-gradient);
}

._21gGF {
  background: var(--c-accent2-normal);
}

.y6QE8 {
  background: var(--meta-gradient);
}
