._1Nwff {
  width: 100%;
  height: 100vh;
  padding: 20px;
  padding-top: 58px;

  --c-box-shadow: 0 4px 4px rgb(0 0 0 / 4%), 0 4px 8px rgb(0 0 0 / 4%);
}

._2oIrz {
  width: 370px;
}

.TrP7f {
  width: 370px;
}

._1O3_Z {
  width: 530px;
}

._1tiFs {
  margin: 8px 24px 0px 24px;
}

@media (max-width: 720px) {
  ._1Nwff {
    width: 100%;
    padding-top: 20px;
  }

  ._1tiFs {
    margin: 8px 0 0 0;
    border-radius: 0;
  }
}
