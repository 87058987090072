._3ZGqe {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 6px 12px 6px 0;
}

._3ZGqe input {
  display: flex;
  flex-grow: 1;
  padding-left: 0px;
  padding-bottom: 2px;
  font-size: 15px;
  color: black;
  border: none;
}

._3ZGqe input::placeholder {
  color: var(--grey);
  opacity: 1;
}

._3ZGqe input:disabled {
  background-color: var(--white);
}
