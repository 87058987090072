._3Oe74 {
  position: absolute;
  right: 80px;
  bottom: 44px;
  width: 370px;
  height: 600px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  mix-blend-mode: normal;
  box-shadow: 0 8px 20px rgba(20, 20, 20, 0.16);
  border-radius: 8px;
}

._3YgJX {
  background: var(--blue);
  border-radius: 8px 8px 0 0;
  height: 56px;
  padding: 16px;
}

._2XaMb {
  flex: auto;
}

@media (max-width: 700px) {
  ._3YgJX {
    border-radius: 0;
  }
  ._3Oe74 {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: none;
    border-radius: 0;
    width: auto;
    height: auto;
  }
}
