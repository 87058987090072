._1I_gU {
  width: 100%;
  background: var(--c-accent2-light);
  border-radius: 8px;
  padding: 24px 20px;
}

._1I_gU._1gM2Q {
  position: absolute;
  width: calc(100% - 32px);
  height: calc(100% - 202px);
  border-radius: 8px;
}

._28ZRG {
  width: 340px;
}

._1mTFY {
  padding: 4px 8px;
  gap: 4px;
  height: 32px;
  border: 1px solid var(--c-accent2-dark);
  border-radius: 4px;
  white-space: nowrap;
}
