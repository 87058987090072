._2lesR {
  width: 310px;
  background: rgba(255, 255, 255, 0.96);
  border: 1px solid rgba(16, 16, 16, 0.02);
  box-shadow: 0 8px 32px rgba(32, 32, 32, 0.08), 0 0 0 rgba(16, 16, 16, 0.04);
  border-radius: 4px;
  padding: 20px 16px 16px;
  position: absolute;
  z-index: var(--zindex-over-content);
}

._2Comz {
  position: relative;
}

._1O5nP {
  position: absolute;
  z-index: var(--zindex-over-content);
  top: 13px;
  left: 13px;
}
