.S0SCF {
  min-height: auto !important; /** в базовом border-box большая специфичность селектора */
}

._1ZylB {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;
}

._2QmLS {
  transform: rotate(90deg);
  transition: transform 0.2s;
}

._2QmLS._2UtCP {
  transform: rotate(270deg);
}

._1iIro {
  flex: auto;
  margin-right: 18px;
}

._3jlUo {
  opacity: 0;
}

._2FJI9 {
  opacity: 1;
  transition: opacity 300ms;
}

._3PBrL {
  opacity: 1;
}

._2UaU_ {
  opacity: 0;
  transition: opacity 300ms;
}

._3xMI6 {
  margin-left: auto;
}
