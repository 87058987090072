._1oQM2 {
  display: block;
  width: 16px;
  height: 16px;
  pointer-events: none !important;
}

._1oQM2 * {
  pointer-events: none !important;
}

._32xY_ {
  width: 150px;
  height: 80px;
  position: relative;
}

._2hjQa {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 11px 12px 8px;
  color: var(--greyDark);
}

._2hjQa:not(:first-child) {
  border-top: 1px solid var(--greyLight20);
  margin-top: 12px;
}

._1apmX {
  max-width: 400px;
}

cf_link {
  color: var(--blue) !important;
}

._2WVM8 {
  margin: 8px 16px;
}

.HlSuA {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

._2ZDgB {
  height: 36px;
  padding: 0 16px;
}

._2ZDgB > span {
  line-height: 32px !important;
}

._3rpGA {
  height: 36px;
}

._1Oux4 {
  border-radius: 0px 0px 4px 4px !important;
}

._2hA7m {
  margin-bottom: auto;
  margin-top: 4px;
  margin-right: 8px;
}
