.f-LpI {
  -webkit-appearance: none;
  width: 100%;
  margin: 7px 0;
  background: none;
  box-sizing: border-box;
  height: 20px;
  border-radius: 10px;
  position: relative;
  padding: 0 0 0 4px;
  -webkit-clip-path: polygon(
    3px -1px,
    3px 7px,
    1.9px 7.21px,
    0.88px 7.88px,
    0.24px 8.83px,
    0px 10px,
    0.22px 11.13px,
    0.82px 12.06px,
    1.79px 12.75px,
    3px 13px,
    3px 25px,
    110% 25px,
    110% -1px,
    3px -1px
  );

  --fill: calc(-100vw - 20px) 0 0 100vw var(--blue);
  --clipFill: polygon(
    0.5px 7px,
    -100vw 7px,
    -100vw 13px,
    0.5px 13px,
    1px 25px,
    25px 25px,
    25px 0px,
    1px 0px
  );
  --default-shadow: 2px 2px 4px rgba(8, 35, 48, 0.2),
    0 0 0 1px inset var(--white), 0 0 0 6px inset var(--white);
  --hover-shadow: 2px 2px 4px rgba(8, 35, 48, 0.2), 0 0 0 1px inset #cbcbcc,
    0 0 0 6px inset #f1f1f1;
  --focus-shadow: 2px 2px 4px rgba(8, 35, 48, 0.2), 0 0 0 1px inset #a3c5e5,
    0 0 0 6px inset var(--greyLight30);
}

.f-LpI:focus {
  outline: none;
}

.f-LpI::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: var(--greyLight20);
  border-radius: 3px;
}

.f-LpI::-webkit-slider-thumb {
  box-shadow: var(--default-shadow), var(--fill);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: var(--blue);
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-clip-path: var(--clipFill);
  z-index: 2;
  margin-top: -7px;
  margin-left: -2px;
}

.f-LpI:focus::-webkit-slider-runnable-track {
  background: var(--greyLight20);
}

.f-LpI::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: var(--greyLight20);
  border-radius: 3px;
}

.f-LpI::-moz-range-progress {
  height: 6px;
  background: var(--blue);
  border-radius: 3px;
}

.f-LpI::-moz-range-thumb {
  box-shadow: var(--default-shadow);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: var(--blue);
  cursor: pointer;
}

.f-LpI::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.f-LpI::-ms-fill-lower {
  background: var(--blue);
  border-radius: 6px;
}

.f-LpI::-ms-fill-upper {
  background: var(--greyLight20);
  border-radius: 6px;
}

.f-LpI::-ms-thumb {
  box-shadow: var(--default-shadow);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: var(--blue);
  cursor: pointer;
}

.f-LpI:focus::-ms-fill-lower {
  background: var(--blue);
}

.f-LpI:focus::-ms-fill-upper {
  background: var(--blue);
}

.f-LpI:hover::-webkit-slider-thumb {
  box-shadow: var(--hover-shadow), var(--fill);
}

.f-LpI:focus::-webkit-slider-thumb {
  box-shadow: var(--focus-shadow), var(--fill);
}

.f-LpI:hover::-moz-range-thumb {
  box-shadow: var(--hover-shadow);
}

.f-LpI:focus::-moz-range-thumb {
  box-shadow: var(--focus-shadow);
}

.f-LpI:hover::-ms-thumb {
  box-shadow: var(--hover-shadow);
}

.f-LpI:focus::-ms-thumb {
  box-shadow: var(--focus-shadow);
}
