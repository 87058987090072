._2Z4kA {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  z-index: var(--zindex-navigation);
  flex-direction: column;
}

._17n5v {
  position: relative;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 32px;
}

._2SVFl {
  background-color: var(--white);
  border-bottom: 1px solid var(--greyLight20);
}

.oXYjl {
  margin-left: 7px;
  margin-right: 7px;
}

._3ZLOd {
  flex: 1 1 100%;
  padding-right: 24px;
}

._2U3ez {
  background-color: var(--cobalt);
  border-bottom: 1px solid var(--cobalt);
}

.IdQ96 {
  flex: 0 0 auto;
}

._3buHq {
  flex: 0 0 64px;
}

._2Hwdg {
  margin-right: 32px;
}

@media (orientation: landscape) and (max-width: 720px) {
  ._17n5v {
    padding-right: max(24px, env(safe-area-inset-right));
  }
}
