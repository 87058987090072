._1N_2n {
  background-color: var(--blueLight30);
}

._3hZRm {
  width: 100%;
}

._2NJDt {
  max-width: 1020px;
}

._1eh8n {
  width: 168px;
}
