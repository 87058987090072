._2YlP3 {
  align-items: center;
}

@media (max-width: 720px) {
  ._2YlP3 {
    align-items: normal;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }
}
