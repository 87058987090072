.G1NXo {
  padding: 0 32px;
}

._1ut_G {
  display: inline-block;
  color: var(--blueDark10);
  text-decoration: none;
  white-space: nowrap;
}

._1ut_G:hover {
  text-decoration: underline;
}

._3oR4F {
  width: 305px;
}
