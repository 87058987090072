._2WSYf {
  border-top: 1px solid #EFF0F2;
}

._20qFK {
  width: 214px;
}

._1EJFF{
  border-radius: 4px;
  overflow: hidden;
}

._3HcIX {
  width: 100%;
}

