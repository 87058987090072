:root {
  --gap: 16px;
}

._3xKVi {
  width: 326px;
  padding: var(--gap);

  background-color: var(--white);
}

.vn61j {
  width: 100%;
  padding: 0 var(--gap);
  height: 32px;
  cursor: pointer;
  white-space: nowrap;
}

.vn61j ._2DKs7 {
  display: none;
}

.vn61j:hover {
  background-color: var(--blue);
}

.vn61j:hover span {
  color: var(--white) !important;
}

/*use CSS for show/hide item buttons (react Hover dont work correctly in this case)*/
.vn61j:hover .tDZiL {
  display: none;
}

.vn61j:hover ._2DKs7 {
  display: flex;
}

._33x-2 {
  width: 125px;
  padding-right: 4px;
}

._2Gmd- {
  width: 72px;
  flex: none;
}

._2Dgay {
  width: 97px;
}

._3qAaH:hover {
  background-color: var(--white);
  border-radius: 4px;
}

._3qAaH:hover svg {
  color: var(--blue);
}
