._234X0{
    width: 100%;
    --hPadding: 12px;
    padding-top: 1px;
}

._371IA{
    width: auto;
    max-width: calc(100% - var(--hPadding));
    padding-bottom: 0;
    height: 32px;
    border-radius: 0;
    line-height: 20px;
}

._3qu3Z {
    min-width: 0;
    flex-grow: 0;
}

._3zk6M{
    flex-grow: 1;
    flex-shrink: 0;
    padding-right: var(--hPadding);
}