._1z0Sj {
  position: relative;
}

.uuW88 {
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 16px rgba(16, 16, 16, 0.24);
  border-radius: 4px;
  top: calc(100% + 10px);
  right: -8px;
  height: 26px;
  padding: 0 4px;
}

.uuW88._1CsIY {
  right: 0;
  top: calc(100% + 2px);
}

._2yuW9 {
  color: var(--white);
  display: flex;
  padding: 0 4px;
}

.gI1Qw {
  pointer-events: none;
}

._2_zYR {
  position: absolute;
  right: -8px;
  top: 60px;
}
