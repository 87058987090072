._1Hjc2 {
  font-size: 15px;
  line-height: 20px;
}

._3MT3D {
  margin-top: 24px;
  margin-bottom: 16px;
  line-height: 32px;
}

.RGyhZ {
  display: flex;
  align-items: center;
  flex-direction: column;
}
