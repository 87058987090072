.s0K5l {
  border-left: 1px solid var(--c-base-semilight);
  height: 36px;
  margin: auto 12px;
}

@media (max-width: 720px) {
  .s0K5l {
    display: none;
  }
}
