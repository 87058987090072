.RQvgt {
  position: relative;
  height: 160px;
  border-radius: 8px;
  background: url(/src/pages/BotPage/HomeTab/components/WhatsappAccount/components/WhatsappBuyPhoneDialog/steps/RegisterToReceiveMessages/images/2V59q.svg) center center no-repeat
    var(--c-accent1-extralight);
}

.dz_Wx {
  position: relative;
  height: 160px;
  border-radius: 8px;
  background: url(/src/pages/BotPage/HomeTab/components/WhatsappAccount/components/WhatsappBuyPhoneDialog/steps/RegisterToReceiveMessages/images/26flS.png) center 16px no-repeat
    var(--c-accent1-extralight);
  background-size: 266px auto;
}

.ZntK0 {
  position: absolute;
  top: 102px;
  left: 50%;
  margin-left: 53px !important;
}

._1bLq2 {
  position: absolute;
  z-index: var(--zindex-over-content);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

._2UrAm._1Ny3A {
  opacity: 0.08;
  mix-blend-mode: multiply;
}

._2rRUv {
  padding: 0 0 0 15px;
  margin: 0;
}

._6SjNn {
  height: calc(100% - 100px);
  overflow: hidden auto;
}

._2wsbH {
  position: relative;
  background: url(/src/pages/BotPage/HomeTab/components/WhatsappAccount/components/WhatsappBuyPhoneDialog/steps/RegisterToReceiveMessages/images/22l5J.png) center 32px no-repeat
    var(--c-accent1-extralight);
  background-size: 90% auto;
  height: 60vh;
  border-radius: 8px;
  max-height: 444px;
}

._21sKY {
  display: inline-block;
}
