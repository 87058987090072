.HUS3z {
  flex-grow: 2;
}

._3B2uB {
  flex: none;
  width: 36px;
  height: 36px;
  border-right: 1px solid var(--white);
  background-color: var(--c-base-light);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

._3dVGQ {
  flex-grow: 2;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 12px;
  background-color: var(--c-base-extralight);
}

.RHfVd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background-color: var(--c-base-extralight);
}

.KF8-A {
  position: relative;
  width: 170px;
}

._2R3w6 {
  width: 100%;
  border-radius: 4px;
}

._1F2rR {
  max-width: 154px;
  padding: 0 4px;
  height: 20px;
  position: absolute;
  left: 8px;
  bottom: 8px;
  z-index: 2;
  background-color: var(--c-base-normal);
  opacity: 0.7;
  border-radius: 10px;
}

._17I3q {
  display: none;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: var(--white);
}

.KF8-A:hover ._17I3q {
  display: block;
}
