._2PXtZ {
  display: flex;
  line-height: 24px;
  margin: 0 0 -3px;
  flex-grow: 2;
  justify-content: center;
}

._2PXtZ._1DWqt {
  justify-content: flex-end;
}

._1SYvh._2PXtZ._1DWqt {
  background-color: var(--blue);
}

._2HGtJ {
  display: flex;
  white-space: nowrap;
  padding: 0 8px;
  font-size: 10px;
  color: var(--grey);
  text-decoration: none;
  /* opacity: 0.3; */
}

._2PXtZ._1DWqt ._2HGtJ {
  display: flex;
  white-space: nowrap;
  padding: 0 8px;
  font-size: 12px;
  color: var(--grey);
  text-decoration: none;
}

._1SYvh._2PXtZ._1DWqt ._2HGtJ {
  display: flex;
  white-space: nowrap;
  padding: 0 8px;
  font-size: 12px;
  color: var(--white);
  text-decoration: none;
}

._3qFUI {
  margin: 0 8px 0 0;
}

.jdWPa {
  margin: 0 3px -1px 0;
  height: 10px;
}

._13Ix- {
  color: var(--grey);
  display: inline;
  outline: none;
}

.JtH9A {
  text-decoration: underline;
  cursor: pointer;
}

.JtH9A:hover {
  color: #7a797a;
  text-decoration: none;
}
