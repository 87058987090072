._9FKBM {
  position: relative;
  display: flex;
  justify-content: space-between;
}

._3nbPd {
  width: 100%;
  flex: 1;
}

._3vn4P {
  margin-right: -18px;
}

.gRi6x {
  margin-right: 12px;
}

._2rANp {
  margin-right: 16px;
}

.KtnRC {
  margin-right: 16px;
}

@media (max-width: 720px) {
  ._9FKBM {
    padding-bottom: 16px;
  }

  ._3id1R {
    margin-left: -16px;
  }

  ._3vn4P {
    position: absolute;
    top: 0;
    right: 0;
  }

  ._2rANp {
    width: 100%;
    margin-bottom: 8px;
  }

  .KtnRC {
    margin-bottom: 8px;
  }
}
