:root {
  --tooltip-standart-default-margin: 18px;
  --tooltip-small-default-margin: 13px;
  --tooltip-standart-nib-offset: -7px;
  --tooltip-small-nib-offset: -4px;
}

.Q_yhZ,
._16qDC {
  background-color: var(--black);
}

._16qDC {
  position: absolute;
  transform: rotate(45deg);
}

.Q_yhZ {
  max-width: 298px;
  font-size: 12px;
  letter-spacing: 0;
  color: var(--white);
  border-radius: 4px;
  z-index: var(--zindex-tooltip);
}

._104wf {
  z-index: var(--zindex-tooltip);
}

._2OnLN {
  padding: 16px;
}

._2OnLN ._16qDC {
  width: 16px;
  height: 16px;
}

._3Uxox {
  padding: 15px 16px 16px;
}

._3Uxox._2QLWR {
  padding: 7px 16px 8px;
}

._3Uxox ._16qDC {
  width: 8px;
  height: 8px;
}

._2RBuj._2OnLN,
._3K1td._2OnLN,
._6PPcP._2OnLN {
  margin-bottom: var(--tooltip-standart-default-margin);
}

._2RBuj._3Uxox,
._3K1td._3Uxox,
._6PPcP._3Uxox {
  margin-bottom: var(--tooltip-small-default-margin);
}

._2RBuj._2OnLN ._16qDC {
  bottom: var(--tooltip-standart-nib-offset);
  left: 24px;
}

._3K1td._2OnLN ._16qDC {
  bottom: var(--tooltip-standart-nib-offset);
  left: calc(50% - 12px);
}

._6PPcP._2OnLN ._16qDC {
  bottom: var(--tooltip-standart-nib-offset);
  right: 24px;
}

._2RBuj._3Uxox ._16qDC {
  bottom: var(--tooltip-small-nib-offset);
  left: 24px;
}

._3K1td._3Uxox ._16qDC {
  bottom: var(--tooltip-small-nib-offset);
  left: calc(50% - 4px);
}

._6PPcP._3Uxox ._16qDC {
  bottom: var(--tooltip-small-nib-offset);
  right: 24px;
}

._31nne._2OnLN {
  margin-left: var(--tooltip-standart-default-margin);
}

._31nne._3Uxox {
  margin-left: var(--tooltip-small-default-margin);
}

._31nne._2OnLN ._16qDC {
  left: var(--tooltip-standart-nib-offset);
  top: 50%;
  margin-top: -1px;
}

._31nne._3Uxox ._16qDC {
  left: var(--tooltip-small-nib-offset);
  top: 50%;
}

._1n97E._2OnLN,
.whwBC._2OnLN {
  margin-top: var(--tooltip-standart-default-margin);
}

._2Dv7V._3Uxox,
._1n97E._3Uxox,
.whwBC._3Uxox {
  margin-top: var(--tooltip-small-default-margin);
}

.whwBC._2OnLN ._16qDC {
  top: var(--tooltip-standart-nib-offset);
  left: 24px;
}

.whwBC._3Uxox ._16qDC {
  top: var(--tooltip-small-nib-offset);
  left: 24px;
}

._1n97E._2OnLN ._16qDC {
  top: var(--tooltip-standart-nib-offset);
  right: 24px;
}

._1n97E._3Uxox ._16qDC {
  top: var(--tooltip-small-nib-offset);
  right: 24px;
}

._2Dv7V._3Uxox ._16qDC {
  top: var(--tooltip-small-nib-offset);
  left: calc(50% - 4px);
}

.kOtlQ._2OnLN {
  margin-right: var(--tooltip-standart-default-margin);
}

.kOtlQ._3Uxox {
  margin-right: var(--tooltip-small-default-margin);
}

.kOtlQ._2OnLN ._16qDC {
  right: var(--tooltip-standart-nib-offset);
  top: 50%;
  margin-top: -7px;
}

.kOtlQ._3Uxox ._16qDC {
  right: var(--tooltip-small-nib-offset);
  top: 50%;
  margin-top: -4px;
}

.y-u1k ._16qDC {
  top: -6px;
  left: 4px;
}

._1mYTm {
  top: -30px;
  left: 21px;
}

._2IE5_ {
  bottom: -30px;
  left: 21px;
}

._2Dv7V._2OnLN {
  margin-top: var(--tooltip-standart-default-margin);
}

._2Dv7V._2OnLN ._16qDC {
  top: var(--tooltip-standart-nib-offset);
  left: calc(50% - 8px);
}
