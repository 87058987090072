._23sXL {
  height: 359px;
}

.cropper-view-box {
  outline-color: var(--blue) !important;
}

.cropper-modal {
  opacity: 0.6 !important;
}

.cropper-dashed {
  border: 1px dashed var(--white) !important;
  opacity: 0.8 !important;
  mix-blend-mode: difference;
}

.cropper-dashed.dashed-v {
  border-top: none !important;
  border-bottom: none !important;
}

.cropper-dashed.dashed-h {
  border-left: none !important;
  border-right: none !important;
}

.cropper-point.point-e,
.cropper-point.point-n,
.cropper-point.point-w,
.cropper-point.point-s {
  display: none !important;
}

.cropper-point.point-ne,
.cropper-point.point-nw,
.cropper-point.point-sw,
.cropper-point.point-se {
  background-color: transparent !important;
  height: 20px !important;
  width: 20px !important;
}

.cropper-point.point-ne {
  border-top: 5px solid var(--blue) !important;
  border-right: 5px solid var(--blue) !important;
  top: -3px !important;
  right: -3px !important;
  opacity: 1 !important;
}

.cropper-point.point-nw {
  border-top: 5px solid var(--blue) !important;
  border-left: 5px solid var(--blue) !important;
  top: -3px !important;
  left: -3px !important;
  opacity: 1 !important;
}

.cropper-point.point-sw {
  border-bottom: 5px solid var(--blue) !important;
  border-left: 5px solid var(--blue) !important;
  bottom: -3px !important;
  left: -3px !important;
  opacity: 1 !important;
}

.cropper-point.point-se {
  border-bottom: 5px solid var(--blue) !important;
  border-right: 5px solid var(--blue) !important;
  bottom: -3px !important;
  right: -3px !important;
  opacity: 1 !important;
}

._1jOkJ {
  visibility: hidden;
}

._20Yez {
  margin-right: 16px;
}

._323bo {
  overflow: hidden;
}

._1OAb3 {
  background-color: var(--grey);
  width: 96px;
  margin: 14px 0;
}

._3aZBl {
  width: 100%;
}
