._1xrGG {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid rgba(16, 16, 16, 0.08);
  border-radius: 4px;
  height: 36px;
  opacity: 1 !important;
}

._3a3VK {
  position: absolute;
  left: 33px;
}
