._3WFjb {
  padding: 0 24px;
  width: 100%;
}

._2L4cA + ._2L4cA {
  margin-top: 24px;
}

._1CBYd {
  display: flex;
  align-items: center;
  height: 28px;
  font-size: 18px;
  padding: 8px 0;
  margin-bottom: 6px;
}

._7CBe2 {
  height: 28px;
  cursor: pointer;
  background-color: var(--greyLight20);
  border-radius: 4px;
}

._1JsRl {
  position: relative;
  left: -12px;
  right: 12px;
  width: calc(100% + 24px);
}

._1CBYd > span {
  margin-right: 8px;
}

._2hD2I {
  margin-top: 24px;
  margin-bottom: 32px;
}

.nKxy0 {
  margin-right: 8px;
  font-size: 18px;
}

._3uq_P {
  margin-top: 22px;
  margin-bottom: 24px;
}

._2urb3 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

._29FeV {
  width: 100%;
  margin-bottom: 28px;
  padding: 24px 24px 0;
}

._2GprN {
  display: block;
  width: 100%;
  border: none;
  line-height: 1.2;
  font-size: 15px;
  resize: none;
  padding: 9px 12px;
  background-color: transparent;
}

._29FeV ._1ipYl {
  margin-bottom: 12px;
  font-size: 18px;
}

._2aY2Y {
  margin-top: 18px;
  margin-bottom: 34px;
}

._1d_sx {
  color: var(--blue);
  font-size: 15px;
  line-height: 22px;
  margin-left: 8px;
}

.A3XVu {
  color: var(--red) !important;
}

._1WHNW {
  font-size: 18px;
  line-height: 24px;
  width: 40%;
}

._1tglS {
  font-size: 18px !important;
}

._21Jog {
  color: var(--red) !important;
}

._3g4Y- {
  margin-left: 32px;
  text-wrap: normal;
  font-size: 12px;
  line-height: 16px;
  width: 140px;
  position: relative;
  top: -3px;
}

._3g4Y- a {
  color: var(--blue);
  text-decoration: none;
}

._3g4Y- a:hover {
  text-decoration: underline;
}

._1Ryoy {
  width: 36px !important;
}

._2dnli input {
  height: 36px !important;
}
