._2nvK9 button:not(:first-child) {
  margin-left: 19px;
}

._2nvK9 {
  display: flex;
  flex-direction: row;
}

.eqOTx {
  display: inline-block;
  vertical-align: middle;
  flex-grow: 1;
}
