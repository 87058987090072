._36jhm {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.96);
  box-shadow: 0 8px 32px 0 rgba(32, 32, 32, 0.08),
    0 0 0 1px rgba(16, 16, 16, 0.04);
  overflow: hidden;
  z-index: var(--zindex-dropdown);
  padding: 4px 0;
}

._14u11 {
  display: inline;
}

.yMYoS {
  border: none;
  background: none;
  color: var(--blue);
  outline: none;
}

._1VuAt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._1TWOz {
  margin-right: 4px;
}
