._17fmm {
  display: flex;
}

._3Ph13 {
  background: var(--blueLight30);
}

._2ezga {
  height: 60px;
  min-height: 60px;
}

.buaI2 {
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  display: flex;
}

._26gMN {
  flex: 1 1 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

._3Q7oK {
  width: 100%;
  height: auto;
  flex: auto;
  overflow-x: auto;
}

._2fora {
  flex: 0 0 0;
  height: 100%;
  position: relative;
  padding-left: 100px;
  box-sizing: content-box;
  will-change: flex-basis;
  overflow: hidden;
  /*transition: flex-basis 300ms ease-in-out;*/
}

._2fora._3YPEG {
  flex: 0 0 400px;
  z-index: 2;
}

._1yvB7 {
  min-width: 400px;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: var(--grey-text);
}

._19fpL {
  padding: 12px 0 32px 24px;
}

._1fQor {
  animation: _3f4wW 1000ms infinite;
  margin: 12px 0 0;
}

@keyframes _3f4wW {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 1460px) {
  ._2fora._3YPEG {
    flex: 0 0 490px;
  }

  ._1yvB7 {
    min-width: 490px;
  }
}

@media (max-width: 720px) {
  ._17fmm {
    flex-direction: column;
  }
}
