._19o0I {
  height: 100%;
}

._1Ffzg {
  transform: rotate(90deg);
}

._2tIi1 {
  transform: rotate(-90deg);
}

.gWMan {
  padding: 8px;
}

._3EGe7 {
  border-radius: 4px;
  min-width: 155px;
  display: flex;
}

._3EGe7._1EBrv {
  cursor: pointer;
  background-color: var(--c-base-extralight);
}

._3EGe7._1EBrv div {
  color: var(--c-base-dark);
}

._15Pk6 {
  margin-top: -2px;
  margin-bottom: -6px;
}

._2j76m {
  margin-left: auto;
}

._2j76m path {
  stroke-width: 3px;
}
