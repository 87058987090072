._3XLaq {
  max-width: 278px;
  width: 100%;
  min-width: 204px;
}

._2sX3c {
  padding-right: 10px;
  min-width: 200px;
}

._1cSDX {
  flex-shrink: 0;
}
