.wl0nY {
  background: var(--white);
  border-radius: 12px;
  padding: 32px;
}

@media (max-width: 720px) {
  .wl0nY {
    padding: 16px;
  }
}
