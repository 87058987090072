.n8Mbe {
  display: flex;
  gap: 24px;
  min-height: 80px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--white);
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 20px;
}

.n8Mbe:hover {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.04);
}

._1zbO-,
._2ZOQm {
  flex: none;
  padding: 12px;
  border-radius: 8px;
  background: linear-gradient(
    55deg,
    #7dffd6 1.34%,
    #38e5ac 33.72%,
    #5cb2ff 66.09%,
    #208ef0 98.47%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

._1lU7Q {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

._3y7jK {
  display: flex;
  align-items: center;
  gap: 4px;
}

._1r4Zv {
  text-decoration: underline;
}

._1jlKS {
  width: 100%;
  margin-bottom: 16px;
}

._2ZOQm {
  padding: 4px;
  border-radius: 4px;
}

._2ZOQm svg {
  width: 16px;
  height: 16px;
}

._24V8E {
  display: flex;
  gap: 10px;
  padding: 6px 16px;
}
