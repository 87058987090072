._3XenF {
  height: 1px;
  background-color: var(--c-base-light);
}

._3_XWF {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 20px 20px;
}
