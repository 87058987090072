._1AhM- {
  display: grid;
  grid-gap: calc(var(--unit) * 2);
  grid-template-columns: 20px auto;
  font-size: 15px;
  line-height: 22px;
}

.vfb2F {
  padding-top: 2px;
}
