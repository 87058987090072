._28EfU {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

._3qT92 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

._1_oW3 {
  margin-bottom: 16px;
}
