._1IScK {
  border-top: 1px solid rgba(16, 16, 16, 0.08);
}

._2z97R {
  height: 46px;
  margin-top: 4px;
}

._3Oid1 {
  font-size: 16px;
  line-height: 16px;
  color: var(--black);
  font-weight: 500;
}

.h5aCS {
  font-size: 15px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--black);
}

._3GF87 {
  font-size: 15px;
  line-height: 18px;
  color: #979797;
  margin-top: 4px;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

._3jO0G {
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 12px;
  color: #979797;
}

._3jO0G:hover {
  color: var(--greyDark);
}

._3jO0G._1kGsj {
  color: var(--blue);
  cursor: auto;
}

._3jO0G._6xeVw {
  pointer-events: none;
  color: var(--grey);
}

.FuwYu {
  width: 156px;
  flex: none;
}

.S1TA7 {
  border-radius: 4px;
  overflow: hidden;
  flex-grow: 1;
}

._2wHq- {
  overflow: auto;
  background: rgba(16, 16, 16, 0.02);
  font-size: 13px;
  line-height: 1.3;
  color: var(--greyDark);
  min-height: 100%;
  margin: 0;
  padding: 12px 8px 12px 12px;
  max-height: 270px;
  outline: none;
}
