._3gMxg {
  stroke: var(--white);
}

.ykuRL {
  stroke: var(--black);
}

._16x7u {
  stroke: var(--grey);
}

.OLEsN {
  stroke: var(--blue);
  fill: var(--blue);
}

.NJiPY {
}

._2x94b {
  display: flex;
  justify-content: center;
  align-items: center;
}

._2GHEa {
  min-width: 28px;
  width: 28px;
  height: 28px;
}

._8nPMf {
  min-width: 16px;
  width: 16px;
  height: 16px;
}

._21o9d {
  min-width: 12px;
  width: 12px;
  height: 12px;
}

.w2J2p {
  min-width: 36px;
  width: 36px;
  height: 36px;
}

.forMR {
  min-width: 40px;
  width: 40px;
  height: 40px;
}

._28FXJ {
  margin: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-size: contain;
  border-radius: 2px;
  overflow: hidden;
  background-color: #d8d8d8;
  background-repeat: no-repeat;
  background-position: center center;
}

._3rkZi {
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
}
