.-Il7F {
  position: relative;
  width: 680px;
  min-height: 108px;
  padding: 24px;
  padding-left: 12px;
  margin-bottom: 48px;
  font-size: 15px;
  background-color: #ffffff;
  border: 1px solid var(--greyLight20);
  border-radius: calc(2 * var(--unit));
}

._3ceJB {
  position: relative;
  top: 1px;
  width: 16px;
}
