._1Kt7f {
  width: 504px;
  font-size: 15px;
  line-height: 20px;
  color: var(--black);
}

._2kxlM {
  font-weight: 600;
}

._3Y6SB {
  text-decoration: none;
  color: var(--blue);
  cursor: pointer;
}

._21rMz {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 40px;
}

._2Cehn {
  margin: 20px 0;
}

._30eEg {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

._3-9HF {
  width: 320px;
}
