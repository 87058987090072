.aoaOx {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

._2rte9 {
  display: flex;
  align-items: center;
}
