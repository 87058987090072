._1MLiH {
  color: #979797;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 8px;
}

._1GR-V {
  line-height: 20px;
}

._2dryo {
  padding-right: 8px;
  word-wrap: break-word;
  word-break: break-all;
}
