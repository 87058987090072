.ajiMj {
  border-radius: 8px;
  border: 1px solid var(--c-base-light);
  padding: 20px 20px 16px 20px;
}

._322wA {
  height: 1px;
  background-color: var(--c-base-light);
}
