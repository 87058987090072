._3856U {
  --gap: 16px;
  --joint-gap: 3px;

  display: flex;
  align-items: flex-end;
  margin-bottom: var(--gap);
}

._29k46 {
  --gap: 12px;
}

._3DS7X {
  margin-bottom: var(--joint-gap);
}

._36y-r {
  justify-content: flex-end;
}

._17Knn {
  margin-bottom: var(--joint-gap);
}

._3zTDe {
  padding-right: 64px;
}
