._1nOuF {
  animation: _2skQ9 1s linear infinite;
}

@supports (-webkit-background-clip: text) {
  ._1nOuF {
    color: transparent;
    background: linear-gradient(
      to right,
      var(--grey) 0%,
      var(--grey) 30%,
      var(--black) 50%,
      var(--grey) 70%,
      var(--grey) 100%
    );
    background-size: 200% 100%;
    animation: _1PPJZ 2s linear infinite;
    display: inline-block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@keyframes _1PPJZ {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}

@keyframes _2skQ9 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
