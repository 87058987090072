._12YO5 {
  width: 360px;
}

._1NFxW {
  position: relative;
  height: 60px;
}

._1L1IO {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-4px, 4px);
  background: var(--white);
  padding: 2px;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
}

._3qtIW {
  position: absolute;
  inset: 0;
  border-radius: 50%;
}

.kVuYp {
  border-radius: 100%;
}

.V8Yib {
  padding-top: 8px;
}
