._3LL2a {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #f1f0f0;
  z-index: var(--zindex-overlay);
  align-items: center;
  justify-content: center;
  display: flex;
}

._2DTlF {
  width: 100px;
  height: 107px;
  will-change: transform;
  transform-origin: 50% 50%;
  animation: _2h8-N 1s infinite cubic-bezier(0.71, 0.01, 0.31, 0.99);
  min-width: unset;
  display: block;
}

@keyframes _2h8-N {
  0% {
    transform: scale(0.926, 0.926);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.926, 0.926);
  }
}
