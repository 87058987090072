._3QO3X {
  background-color: var(--c-base-light);
  border-radius: 4px;
  padding: 12px 20px 12px 16px;
}

._3Dsa4 {
  margin: 0 16px;
}

._2u0qZ {
  flex: none;
}

._3S-KP {
  width: 18px;
  height: 18px;
}

.KW6L9 {
  width: 100%;
}
