._1y48d {
  padding: 8px;
}

._3L-Fn {
  border-radius: 4px;
  min-width: 155px;
  display: flex;
  align-items: center;
}

._3L-Fn._3E5Uk {
  cursor: pointer;
  background-color: var(--c-base-extralight);
}

._3L-Fn._3E5Uk div {
  color: var(--c-base-dark);
}

.mnNKX {
  margin-left: auto;
}

.mnNKX path {
  stroke-width: 3px;
}

.lt4k1 {
  position: relative;
}

._3vAHd {
  position: absolute;
  z-index: 2;
  width: 10px;
  height: 10px;
  background-color: var(--c-accent1-normal);
  border-radius: 5px;
  top: 0;
  right: 0;
}
