.HxwkI {
  font: var(--font);
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
}

._1QvhC {
  display: grid;
  max-width: 300px;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 8px;
}

._3M17P {
  transform: rotate(90deg);
  justify-self: center;
}

._2CzC8 {
  grid-area: 1 / 2 / 2 / 7;
  justify-self: center;
}

._3IjVl {
  transform: rotate(-90deg);
  justify-self: center;
}

._1Gbgo {
  justify-self: center;
}

._3VJAw {
}

._2GEDI {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  padding: 4px;
}

._2GEDI:hover {
  background-color: var(--blue);
  color: var(--white);
}

.fyaf0 {
  left: 22px;
  position: absolute;
  top: 15px;
}

.s0rf1 {
  right: 22px;
  position: absolute;
  top: 15px;
}

.H5Xn5 {
  padding: 16px 24px;
  border-top: 1px solid var(--greyLight20);
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  ._1QvhC {
    max-width: 450px;
  }
}
