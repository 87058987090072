.eWBEb {
  padding: 20px 24px 24px;
  border-radius: 4px;
  background-color: var(--c-base-dark);
  background-position: right top;
  background-repeat: no-repeat;
}

._1LhhP {
  margin: 0 auto;
}

._1yzEd {
  margin: 0 auto;
  background-color: var(--c-base-sub);
}

.UH3NE {
  color: var(--c-accent1-secondary);
}

._1yzEd:enabled:hover,
._1yzEd:enabled:focus {
  background-color: var(--c-base-secondary);
}
