._3clij {
  padding: 0 16px;
  border-radius: 8px;
}

._1Elj1 {
  outline: none;
  cursor: pointer;
  background-color: var(--greyLight20);
}

._21Q7k {
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 13px 0 15px;
  border-bottom: 1px solid rgba(16, 16, 16, 0.08);
}

._3eLTS {
  width: 100%;
}

._7PzNi {
  border-bottom: none;
}
