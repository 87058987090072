._7Tn8r {
  padding: 0;
  cursor: pointer;
  color: #c6c6c6;
  background-color: transparent;

  outline: none;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  -webkit-tap-highlight-color: transparent;
}

._2LPYI {
  color: var(--blue);
}
.W8k3c {
  color: #c6c6c6;
}
._1S0uD {
  color: #eb5757;
}
._2BqX8 {
  color: var(--red);
}
