._1dVDg {
}

._19slw {
  width: 100%;
  word-break: break-word;
}

._1O_2b {
  display: contents;
}

@media (max-width: 830px) {
  ._1dVDg {
    flex-direction: column;
  }
}
