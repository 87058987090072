._2_XcN {
  padding: 4px;
}

._24GyH {
  background-image: linear-gradient(238.9deg, #be60ef 1.39%, #6358de 99.06%);
  border-radius: 12px;
  padding: 2px;
}

._2oNGZ {
  border-radius: 10px;
  background-color: var(--cobalt);
}

._2wEh2 {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.FJxzp {
  position: absolute;
  max-width: 40px;
  right: -6px;
  bottom: 0;
}

.wlc8i {
  color: var(--c-base-tertiary);
  display: flex;
  padding: 10px;
  justify-content: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  flex-direction: column;
}

.g8vRA {
  font-size: 12px;
  color: var(--c-magenta-secondary);
}

@media (max-width: 1024px) {
  .wlc8i {
    display: none;
  }

  .FJxzp {
    right: 5px;
    max-width: unset;
  }

  ._2wEh2 {
    height: 48px;
  }
}

@media (max-width: 720px) {
  ._2_XcN {
    padding-left: 22px;
  }

  .wlc8i {
    display: flex;
    font-size: 12px;
  }

  .FJxzp {
    right: -6px;
  }

  ._24GyH {
    max-width: 200px;
  }

  ._2wEh2 {
    height: unset;
  }
}
