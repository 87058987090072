._2tnmJ {
  margin: 8px 16px;
}

._3lqZd {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

._3w-jO {
  height: 36px;
  padding: 0 16px;
}

._3w-jO > span {
  line-height: 32px !important;
}

/*noinspection CssInvalidFunction*/
._1qYDr {
  max-height: 487px; /* for bad browsers */
  max-height: min(487px, calc(90vh - 64px)); /* for good browsers */
}
