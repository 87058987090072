._1jt2a {
  width: 1024px;
  height: 50px;
  margin: -24px -24px 24px;
  border-radius: 7px 7px 0 0;
  padding: 0 24px;
  position: relative;
}

.Qf-Dc {
  opacity: 0.7;
}

.bLYzo {
  margin-right: 12px;
}

._2c7fs {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 2;
  width: 22px;
}
