._7HO1B {
  position: fixed;
  bottom: 0;
  width: 400px;
  height: 44px;
}

._2kszP {
  position: fixed;
  bottom: 0;
  z-index: var(--zindex-overlay);
}

._3iYqV iframe {
  border-radius: 0;
}
