.w4D3G {
  display: flex;
  overflow: hidden;
}

.DpjSM {
  padding-right: 32px; /* calculated in MessagesView */
}

.w4D3G > * {
  flex-shrink: 0;
  margin-right: 8px;
}

._3pBPJ {
  background-size: cover;
  background-repeat: no-repeat;
}

._32tYb {
  padding: 6px 10px;
  font-size: 14px;
}
