._2XwdE {
  width: 100%;
  min-width: unset;
  min-height: 228px !important;
  overflow: hidden;
  position: relative;
  padding: 0 !important;
}

._2fQPC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  font-size: 24px;
  color: var(--black);
  font-weight: 300;
}

._1oCgU {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #767676;
  padding: 0 24px 56px;
}

._31Tnr {
  max-height: 536px;
  overflow: auto;
  border-top: 1px solid var(--greyLight20);
  background-color: var(--white);
}

._3FVsR {
  position: relative;
  border-top: 1px solid var(--greyLight20);
  height: 72px;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  padding: 0 24px;
  background-color: var(--white);
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._3FVsR:first-child {
  border-top: none;
}

._1DPDM {
  display: flex;
  align-items: center;
}

._2cTl5 {
  margin-right: 16px;
  min-height: 40px;
  min-width: 40px;
}

._1ZIII {
  margin: 4px;
}

._2zzhM {
  flex: none;
  font-size: 14px;
  color: #979797;
}

.Gy1JT {
  font-size: 13px;
  line-height: 1.23;
  color: #979797;
  padding: 0 0 5px;
}

._2lAl4 {
  border-bottom: none !important;
}

.BpTx0 {
  font-size: 16px;
  line-height: 20px;
  color: #979797;
}

.cZgsO {
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
}

._2Zwuk {
  display: block;
  margin: -10px auto 50px;
}
