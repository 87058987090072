._2j42N {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: var(--greyDark);
}

._1NUES {
  display: flex;
  flex-direction: column;
  margin: 0 0 8px 0 !important;
  width: 50% !important;
}

._1NUES:nth-last-child(3):nth-of-type(3) {
  width: 100% !important;
}
