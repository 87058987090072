._3C2oY {
  height: 24px;
  width: 24px;
  background: no-repeat left center;
  background-size: contain;
}

.U1JGQ {
  display: flex;
  width: 44px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

._2v_0F {
  width: 48px;
  height: 48px;
}

._2qy8t {
  width: 84px;
  height: 18px;
}
