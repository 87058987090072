.f_UAR {
  --grid-unit: 4px;

  position: relative;
  width: 480px;
  border-radius: 8px;
  background-color: var(--greyLight30);
  box-shadow: 0 8px 64px 0 rgba(20, 20, 20, 0.16);
  outline: none;
}

._17uMJ {
  padding: calc(8 * var(--grid-unit)) calc(10 * var(--grid-unit))
    calc(6 * var(--grid-unit));
  line-height: 24px;
}

._7ftuU {
  width: calc(100% - 24px);
  margin-top: 0;
  margin-bottom: calc(4 * var(--grid-unit));
}

._1kRWH {
  display: flex;
}

._1FneR {
  padding: 0 40px 40px;
  display: flex;
  justify-content: flex-end;
}

._17GC8 {
  height: 1px;
  background-color: rgba(16, 16, 16, 0.08);
}

._3ZGL9 {
  position: absolute;
  top: 32px;
  right: 32px;
  color: var(--black);
}

._2kqQk {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 16px;
}

.UsSkG {
  width: unset !important;
  box-shadow: none;
  background-color: inherit;
}

.UsSkG ._17uMJ {
  padding: 33px 16px 32px;
}

._3u48h {
  padding: 16px 20px;
}

.-waqs {
  top: 16px;
  right: 20px;
}

@media (max-width: 720px) {
  .f_UAR {
    width: 335px;
  }

  .UsSkG {
    width: 100%;
  }
}
