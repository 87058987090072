._1qP0S {
  padding: 10px 12px 10px 8px;
  cursor: pointer;
}

._1qP0S._1Ilod {
  cursor: default;
}

._26OMQ {
  transition: transform 0.3s;
  transform: rotate(0deg);
}

._26OMQ.rPXfC {
  transform: rotate(-180deg);
}

.gIdZZ {
  border-radius: 14px;
  padding: 2px 8px;
  margin-left: 6px !important;
  height: 24px;
}

.vnaIF {
  width: 36px;
  height: 36px;
}

._2KeC9 {
  flex: auto;
  margin-right: auto;
  padding-left: 4px;
  padding-right: 16px;
  overflow: hidden;
}

._1qiXB {
  min-width: 232px;
  align-items: center;
  flex: none;
  border-left: 1px solid var(--white);
  padding-left: 16px;
  justify-content: end;
}

._3dKRm {
  border-left: 1px solid var(--white);
  padding: 0 16px;
}

._3edFV {
  padding: 4px 16px;
  justify-content: flex-start;
  height: 36px;
  margin: auto 0;
  width: 50%;
}

._3hQck {
  color: var(--blue);
  font-weight: 600;
}

@media (max-width: 720px) {
  ._1qP0S {
    flex-wrap: wrap;
    position: relative;
  }

  ._3dKRm {
    justify-content: center;
    width: 100%;
    height: 60px;
    border-left: 0;
    padding-left: 0;
    border-top: 1px solid var(--white);
  }

  ._1qiXB {
    border-left: 0;
    border-top: 1px solid var(--white);
    width: 100%;
    padding-top: 14px;
    padding-left: 0;
    justify-content: flex-start;
  }

  ._2KeC9 {
    width: calc(100% - 70px);
    flex: none;
    height: 56px;
  }

  .vnaIF {
    position: absolute;
    right: 4px;
    top: 10px;
  }
}
