._2qLvL {
  display: flex;
}

._3rwfj {
  display: inline-flex;
}

._3WgU- {
  height: 100%;
}

.umhGB {
  width: 100%;
}

._3PYBC {
  justify-content: space-between;
}

._2tIkX {
  justify-content: center;
}

._25Jqu {
  justify-content: flex-end;
}

._1e3oJ {
  justify-content: center;
}

._29T_m {
  justify-content: flex-start;
}

._3rVWr {
  align-items: baseline;
}

._1aC9T {
  align-items: center;
}

._2N7fw {
  align-items: flex-start;
}

.gNrwu {
  align-items: flex-end;
}

._3o1ij {
  flex-wrap: wrap;
}

.OxELe {
  flex-direction: row-reverse;
}

._2iqgV {
  flex-direction: column;
}

._3Y8e2 {
  flex-direction: column-reverse;
}
