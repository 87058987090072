._1Sccq {
  position: relative;
  height: 36px;
  border-radius: 6px;
  border: 2px solid transparent;
  color: var(--black);
  background-color: var(--white);
  box-shadow: none;
  transition: box-shadow 0.2s ease;
  flex-grow: 1;
  min-width: 0;
}

._1Sccq._2tbpz {
  height: 48px;
}

._10HtX {
  transition: height 200ms;
  height: 36px;
  flex-grow: 1;
  min-width: 0;
}

._10HtX._34wKY {
  transition: height 200ms;
  height: 48px;
  min-width: 0;
}

._10HtX._34wKY._3eVUN {
  transition: height 200ms;
  height: 72px;
  min-width: 0;
}

._3eVUN {
  transition: height 200ms;
  height: 60px;
}

._1Sccq._39Qyp {
  color: #f25f38;
  background-color: var(--greyLight30);
}

._3xKMB {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 3px;
  text-align: center; /* both text-align and justify-content are necessary */
  justify-content: center;
  overflow: hidden;
  color: inherit;
  text-decoration: none;
  outline: none;
}

._3xKMB._13W2y {
  justify-content: flex-start;
  padding: 0 0 0 11px;
  white-space: nowrap;
}

._3Gf2q {
  max-height: 100%;
  word-break: break-word;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

._3xKMB._13W2y ._3Gf2q {
  white-space: nowrap;
  min-width: 0;
  display: block;
  text-align: left;
}

._2tbpz ._3xKMB {
  display: block;
  overflow: visible;
  text-align: left;
  height: 48px;
}

._2tbpz ._3Gf2q {
  font-size: 15px;
  margin: 3px 0 0 7px;
}

._2tbpz ._3OwKW {
  --over-margin: 7px;
  display: inline-block;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: normal;
  opacity: 0.7;
  margin: -2px 0 0 var(--over-margin);
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - var(--over-margin));
}

.parent__shadow-draggable ._1Sccq,
._1Sccq:hover,
._1Sccq:focus {
  color: var(--black);
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.13);
}

._3kcfv::after {
  content: '';
  background: #e9604a;
  width: 10px;
  height: 10px;
  border: 2px solid white;
  position: absolute;
  border-radius: 5px;
  right: 1px;
  top: 1px;
}

._1Sccq._10CAM {
  background-color: var(--blue);
  color: var(--white);
}

._3q0jD {
  background-color: transparent;
  border: none;
  padding: 0;
}

._3Q0AW {
  position: absolute;
  top: 2px;
  right: 0;
  bottom: 2px;
  width: 28px;
  outline: none;
  padding: 0;
  color: #979797;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--white) 24%,
    var(--white)
  );
}

._3Q0AW:focus,
._3Q0AW:hover {
  color: rgb(5, 55, 72);
}

._3Q0AW._3J9nt {
  background-image: linear-gradient(
    to right,
    rgba(32, 142, 240, 0),
    var(--blue) 24%,
    var(--blue)
  );
  color: var(--white);
}

div._1X1dx {
  position: fixed;
  top: -50px !important;
  left: 15px !important;
  width: 298px;
  height: auto;
  min-height: 48px;
  border-radius: 4px;
  background-color: #053748;
  padding: 24px;
  font-size: 13px;
  line-height: 1.23;
  color: var(--white);
  white-space: normal;
}

div._1X1dx::after {
  position: absolute;
  left: -9px;
  top: 19px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 11px 0;
  border-color: transparent #053748 transparent transparent;
}

._1ufEY {
  position: absolute;
  top: -5px;
  right: -3px;
  width: 70px;
  height: 48px;
  border-radius: 6px;
  background-image: linear-gradient(
    to right,
    rgba(32, 142, 240, 0),
    var(--blue)
  );
}

._31MJL {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

._1ufEY._11dxY {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--white)
  );
}

div._1Q7gJ {
  margin-right: 17px;
}
