._1KdFX {
  display: flex;
  align-items: center;
}

._2rEbQ {
  margin-left: 8px;
  color: var(--greyDark);
}

._3V7Re {
  color: var(--greyDark);
  text-decoration: underline;
}

._3V7Re:hover {
  text-decoration: none;
}
