._2bEWk {
  width: 100%;
  height: 414px;
  position: relative;
}

.sKyoY {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #f3f2f2;
  width: 100%;
  z-index: var(--zindex-dropdown);
  opacity: 0.5;
}
