._2_L-K {
  position: relative;
  max-width: 520px;
  margin: 20px 48px;
}

._1YrNR {
  position: sticky;
  top: 0;
  margin-left: 528px;
  margin-top: 20px;
  background-color: var(--c-base-dark);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media (max-width: 720px) {
  ._2_L-K {
    width: 100%;
    margin: 20px 0px;
  }

  ._1YrNR {
    margin-left: auto;
    margin-bottom: 8px;
  }
}
