._39Dyq {
  background-color: var(--white);
  width: 520px;
}

._39Dyq._3pCiw {
  width: 100%;
  text-align: center;
}

._1hTVo {
  justify-content: space-between;
}

._1hTVo._3pCiw {
  flex-wrap: wrap;
  width: 200px;
  margin: 0 auto;
}

._3-oMG {
  padding-right: 20px;
}

._3XE3Q._3pCiw {
  flex: auto;
  margin: 0 -24px;
}
