._2-fMM {
  position: relative;
  animation: _2ZUkI 300ms ease-in-out;
  padding: 12px;
  height: 48px;
  border: 1px solid #eff0f2;
  border-radius: 4px;
}

._2kxfP {
  position: absolute;
  top: -12px;
  right: -12px;
  border-radius: 50%;
  background-color: var(--white);
  border: 1px solid var(--c-base-light);
  width: 24px;
  height: 24px;
}

@keyframes _2ZUkI {
  from {
    opacity: 0.01;
    transform: translateY(5px) scale(0.9, 0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1, 1);
  }
}
