._2iYHh {
  background-color: var(--greyLight20);
  border-radius: 4px;
  overflow: hidden;
  padding-top: 20px;
}

._2B3Yk {
  display: block;
  width: 396px;
  height: 175px;
}
