._10pTc {
  flex: 1 1 auto;
}

.r44lV {
  flex: 1;
}

@media (max-width: 720px) {
  ._10pTc {
    flex-direction: column;
  }

  .r44lV {
    margin-bottom: 24px;
  }
}
