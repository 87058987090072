.eD_On {
  padding: 0;
}

._3VXhy {
  min-height: 50px;
  padding-bottom: 0;
}

._1zWze {
  display: grid;
  grid-template-columns: 207px 120px 87px 40px;
  grid-column-gap: 22px;
  align-items: center;
}

._30pwK {
  height: 36px;
}

._2NWhX {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

._2Byr1 {
  color: var(--grey);
}

._2ofSL {
  display: flex;
  align-items: center;
  height: 36px;
  position: relative;
  left: -23px;
}

._1TvPw path {
  fill: var(--red);
}

._1tnBX path {
  fill: var(--blue);
}

.sXbdf path {
  fill: var(--grey);
}

._1tnBX,
._3ENIO {
  flex: 1;
}

._1TvPw,
._1tnBX {
  margin-right: 8px;
}

._23iPG {
  position: relative;
  left: 1px;
}

._1Yb23 {
  vertical-align: middle;
  display: inline-block;
}
