.NuJpj {
  padding: 18px 24px;
  transition: background-color 0.3s linear;
  overflow: hidden;
  position: relative;
}

._3mOIj {
  background-color: var(--c-base-dark);
}

._2oA1J {
  background-color: var(--c-base-normal);
}

._2vFcS {
  margin: 22px 60px 0px 60px;
  border-radius: 8px;
}
