._18KTP {
  height: 32px;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--greyLight20);
}

.bmDC- {
  object-fit: cover;
  width: 16px;
  height: 16px;
  margin: 7px;
}

.H7W8K {
  transform: rotate(-90deg);
  margin: 0 -7px;
}
