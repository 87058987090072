._3BtJi {
  padding: 0;
}

._3HNoq {
  min-height: 24px;
  margin: 10px 0 24px 0;
  padding: 0;
  border: none;
}

.iGzin {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: var(--black);
  margin-bottom: 8px;
}

._3RgEK {
  border-top: 1px solid var(--greyLight30);
}

._2TUeE {
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
}

._2K6qV {
  color: #de614d;
}

.qHxMD {
  grid-column: span 2;
}

.qHxMD:first-child {
  width: 96px;
}

.qHxMD:nth-child(4) {
  margin-left: 56px;
  margin-right: -16px;
}

.qHxMD:last-child {
  grid-column: span 1;
  width: 24px;
  margin-left: 0;
  margin-right: 0;
}
