.zt5PA {
  padding: 0;
  margin: 0;
  max-height: calc(74px * 6);
  overflow-y: auto;
}

._3p2d7 {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 16px 0;
  list-style-type: none;
  justify-content: space-between;
  border-bottom: 1px solid var(--c-base-light);
}

._3p2d7:last-child {
  border-bottom: none;
}

.yr_UY {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 12px;
  gap: 2px;
  border-radius: 4px;
  background: var(--c-base-extralight);
}

._3sNCU {
  background-color: var(--white);
}
