._36ix_ {
  width: 100%;
}

._3WCpX {
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

._2zGPA {
  display: flex;
  align-items: center;
  height: var(--control-decorator-min-height);
  padding-left: calc(var(--unit) * 3);
  padding-right: calc(var(--unit) * 3);
}

._2zGPA > * {
  width: 100%;
}

.UrPF4 {
  width: 100%;
  text-align: center;
}

._26nCo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 104px;
}

._34cRg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 36px;
}
