._3ZVsD {
  background: var(--grey);
  border-radius: 4px;
  padding: 0 4px;
  transition: opacity 0.5s;
}

._2c4gh {
  opacity: 0.5;
}
