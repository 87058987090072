._2pbJK {
  min-height: 202px;
  margin: 0;
  position: relative;
}

._3Qdqg {
  position: absolute;
  z-index: var(--zindex-over-content);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
}

._1L6F2 {
  height: 170px;
}

._3DrXh {
  width: 100%;
  height: 170px;
  display: block;
  object-fit: cover;
}
