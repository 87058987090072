._3nG7s {
  max-height: 350px;
  max-width: calc(min(800px, 100%));
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;
}

.NAppD {
  overflow: hidden;
  width: 100%;
}
