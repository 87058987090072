.pL35o {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.pL35o {
  padding: 6px 4px 6px 12px;
  margin-top: 4px;
}

.fExlY,
.pL35o:hover {
  background: var(--cobaltExtralight);
}

._1ZmMX {
  pointer-events: none;
}

._1-obA {
  width: 24px;
  height: 24px;
}

.xea5E {
  display: inline-flex;
}

._2Wsla {
  display: inline-block;
  color: var(--c-accent1-normal);
}

._2RMa0 {
  margin: -6px 0 0 12px !important;
  white-space: nowrap;
}
