._3EplU {
  width: 520px;
  height: calc(100% - 24px);
}

.NWhdz {
  width: 100%;
  height: 100%;
}

._1RYNv {
  width: 100%;
}

.n8km1 {
  width: 100%;
  padding-bottom: 40px;
}

@media (max-width: 720px) {
  ._3EplU {
    width: auto;
  }
}
