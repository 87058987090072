._12Zsn {
  position: relative;
  max-width: 100%;
}

._2Amwo {
  height: 28px;
  padding: 0 32px 0 12px;
  border-radius: 14px;
  width: 100%;
  min-width: 0;
  background-color: var(--blueLight30);
  border: 1px solid var(--blueLight30);
}

._2Amwo:hover {
  border: 1px solid var(--blueLight);
}

._2Amwo._463oD {
  border: 1px solid var(--redLight) !important;
}

.K-nFD {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}

.qi0oK {
  max-width: 100%;
}

.yD8Sy {
  background: none;
  border: none;
  outline: none;
  max-width: 100%;
  padding: 0;
  margin-bottom: 2px;
  text-overflow: ellipsis;
}

.AiG_g {
  position: absolute;
  top: 15px;
  left: 12px;
  white-space: nowrap;
  z-index: var(--zindex-over-content);
  pointer-events: none;
}

@media (max-width: 700px) {
  ._2Amwo {
    height: 32px;
  }
}
