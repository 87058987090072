._26-f1 {
  position: relative;
  box-shadow: var(--c-box-shadow);
  border-radius: 12px;
  padding: 20px 24px 24px;
  background-color: var(--white);
}

.UUj2s::first-letter {
  text-transform: capitalize;
}

._1OPIE {
  display: inline-block;
  font-size: 15px;
  color: var(--blue);
  margin-right: 4px;
  font-weight: 600;
}

._3fNY- {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

._3tebi {
  margin-left: auto;
}

._3VU_m * {
  color: var(--red) !important;
}

._3VU_m:hover *,
._3VU_m[data-active='true'] * {
  color: var(--white) !important;
}

._3OyDz > .pane {
  background: var(--churn-survey-gradient);
}

._2dyU7 {
  margin-left: 8px;
}

@media (max-width: 720px) {
  ._26-f1 {
    width: 100%;
  }
}
