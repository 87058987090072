.V7UOY {
  display: flex;
  overflow-x: auto;
  margin: 0px -16px;
  padding: 0 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.V7UOY::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.V7UOY {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
