._12Y2m {
  flex: none;
}

._2akSV {
  padding: 8px 12px 12px 12px;
}

.d14Ub {
  position: relative;
}

.d14Ub:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  display: block;
  /* background: linear-gradient(256deg, #95cdff 0%, rgba(149, 205, 255, 0) 70%); */
  background: #95cdff;
  opacity: 0.64;
  transition: opacity 1s;
}

._316YL.d14Ub:before {
  opacity: 0;
}
