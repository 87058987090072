._1ydkH {
  padding: 12px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--white);
}

._1ydkH,
._2Hpbd {
  border-radius: 8px;
}

._9eaMJ {
  margin-right: 12px;
}

._1ydkH._2HFSm {
  cursor: default;
  opacity: 0.5;
}

._1ydkH._3bkKB {
  cursor: default;
}

._3_jp_ {
  flex: auto;
  margin-right: auto;
  padding-right: 16px;
  overflow: hidden;
}

._32pYu {
  padding-right: 16px;
  justify-content: flex-start;
  height: 36px;
  margin: auto 0;
  width: 50%;
}

._1L5a9 {
  min-width: 232px;
  align-items: center;
  flex: none;
  padding-left: 16px;
  border-left: 1px solid var(--blueLight20);
  justify-content: end;
}

._2PYw0 {
  width: 36px;
  height: 36px;
}

@media (max-width: 720px) {
  ._1ydkH {
    flex-direction: column;
    position: relative;
  }

  ._9eaMJ {
    margin-bottom: 8px;
  }

  ._1L5a9 {
    border-left: 0;
    padding: 0;
  }

  ._2aGuQ {
    width: 100%;
    margin-top: 14px;
  }

  ._3_jp_ {
    padding: 0 0 8px 0;
    border-bottom: 1px solid var(--blueLight20);
    width: 100%;
  }

  ._2PYw0 {
    position: absolute;
    right: 4px;
    top: 10px;
  }

  ._30DQu {
    z-index: 1;
  }
}
