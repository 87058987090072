.FTZvp {
  gap: 8px;
}

._3ACoH {
  flex: 1;
}

/* выравнивание относительно центра иконки radiobutton */
._1YeCb {
  margin-top: -1px;
}
