.OJu7S {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}

._3l82y {
  margin-bottom: var(--unit);
}

._16zft {
  margin-top: calc(3 * var(--unit));
}
