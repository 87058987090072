.UZdb5 {
  width: 1024px;
}

._3_UAa {
  width: 18px;
  margin-right: 8px;
}

._1-h9K {
  margin: 0 -24px;
}

._2dj5j {
  font-size: 9px;
  line-height: 10px;
  color: var(--white);
  font-weight: bold;
  vertical-align: super;
  margin-left: 6px;
}

._1AhFZ {
  margin-bottom: 24px;
}

._14b5c {
  margin-left: 16px;
}
