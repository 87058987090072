._3Ipp_ {
  margin: 20px 120px 16px 28px;
  overflow: hidden;
}

._2hRJk {
  z-index: 100;
  position: absolute;
  right: 24px;
  bottom: 18px;
  width: 36px;
  height: 36px;
  z-index: 100;
}

._2hRJk:hover {
  background-color: var(--c-base-sub);
}

@media (max-width: 720px) {
  ._3Ipp_ {
    margin-top: 4px;
    margin-right: 0;
  }

  ._2hRJk {
    top: -145px;
    bottom: auto;
  }

  ._398hM {
    text-align: center;
  }
}
