.iVHut {
  flex: 1;
}

._2tDBo {
  width: 100%;
}

.bEMey {
  box-shadow: inset 0px 0px 0px 2px var(--c-accent4-secondary);
  background-color: var(--white) !important;
}

._3kLCl {
  position: relative;
}

._3X6KL {
  position: absolute;
  z-index: 1;
}
