._1XR8w {
  background: var(--blueLight30);
}

._1z7K3 {
  flex: 1 1 auto;
}

._3h-pF {
  max-width: 1480px;
  min-width: 980px;
  width: 86%;
  margin: auto;
  padding-bottom: 46px;
}

._1BInS {
  margin-top: 8px;
}

._2tBPq {
  border-radius: 8px;
  padding: 12px;
  min-height: 68px;
  gap: 12px;
}

._2tBPq > div:first-child {
  width: 44px;
  height: 44px;
}

@media (max-width: 1024px) {
  ._3h-pF {
    max-width: none;
    min-width: auto;
    width: 100%;
    padding: 0 16px 100px 16px;
    margin: auto;
  }
  ._16AaF {
    width: 100%;
  }
}
