.VXkqL {
  padding: 12px 0;
  align-items: center;
}

.VXkqL:not(:last-child) {
  border-bottom: 1px solid var(--greyLight10);
}

._3iGPV {
  flex: none;
  width: 40px !important;
  height: 40px !important;
}

._1o2HU {
  width: fit-content;
  display: flex;
  align-items: center;
  color: var(--blue);
  font-size: 15px;
}

._3uwjL {
  margin-top: 2px;
  height: 16px;
}

._208Cu {
  margin-left: 8px;
  margin-right: auto;
  flex: auto;
  overflow: hidden;
}

._1cpx1 {
  flex: none;
}

._1t-Rr {
  z-index: 1;
  box-shadow: 0px 8px 32px rgba(32, 32, 32, 0.08),
    0px 0px 0px rgba(16, 16, 16, 0.04);
}
