._1LzEV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 260px;
}

._1L-Bg {
  inline-size: fit-content;
}
