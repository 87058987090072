._1y-BW {
  position: fixed;
  z-index: var(--zindex-popover-next-1);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

._2VA0T {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000066;
  pointer-events: all;
}

._3pzwC {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  pointer-events: all;
}

._3-xVI {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  pointer-events: all;
}

._360xC {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: all;
}

._3VloY {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  pointer-events: all;
}

._19_p_ {
  position: absolute;
  pointer-events: none;
  box-shadow: 0 0 0 999999px #00000066;
  border-radius: 8px;
}

._3Lv1F {
  position: absolute;
  pointer-events: none;
}
