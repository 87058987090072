._2pShT {
  margin-top: 10px;
}

._3ELEt {
  margin-left: 18px;
}

._10ObM {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}
