._1aD4v {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

._1DYjv {
  display: flex;
  flex: 0 0 auto;
  padding: 0 0 4px;
  margin-right: 8px;
  font-size: 15px;
  line-height: 22px;
  color: var(--greyDark);
  border: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  align-content: center;
  align-items: center;
  background: none;
}

._1aD4v > ._1DYjv:last-child {
  margin-right: 0;
}

._1DYjv:hover {
  border-bottom: 2px solid var(--greyDark);
}

._1DYjv:focus {
  border-bottom: 2px solid var(--greyDark);
  outline: none;
}

._1DYjv._1MlPs {
  color: var(--blue);
  border-bottom: 2px solid var(--blue);
}

._1DYjv svg {
  margin-right: 4px;
  width: 16px;
  height: 16px;
}

._1DYjv._1MlPs svg path {
  stroke: var(--blue);
}
