._4G-OQ > * {
  margin-top: 8px;
}

._4G-OQ {
  padding-bottom: 10px;
}

._3-ZPl {
  position: relative;
  margin-left: -24px;
}
