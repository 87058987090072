._2krF6 {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--c-accent2-tertiary);
  margin: auto;
  margin-left: 4px;
}

.q_91D {
  padding: 4px 8px;
  border-radius: 14px;
  overflow: hidden;
}

._277Nq {
  background-color: var(--c-accent1-light);
  color: var(--c-accent1-dark) !important;
}

._26Ijc {
  background-color: var(--c-accent2-light);
  color: var(--c-accent2-dark) !important;
}

.HkG4S {
  background-color: var(--c-accent3-light);
  color: var(--c-accent3-dark) !important;
}

.HkG4S span {
  color: var(--c-accent3-dark) !important;
}

._2icye {
  background-color: var(--c-purple-light);
  color: var(--c-purple-normal) !important;
}

._2dbuA {
  background-color: var(--c-base-light);
  color: var(--c-base-secondary) !important;
}

.wiEw2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
