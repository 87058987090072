._1-ZnK {
  margin-bottom: 24px;
  margin-left: -4px;
  flex: none;
}

._3DCXn {
  overflow-y: auto;
  overflow-x: hidden;
  flex: auto;
  margin: -4px;
}

._2yfdF {
  height: calc(650px - 152px);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
  grid-template-rows: min-content;
  gap: 18px 16px;
}

._2yfdF._2YaU8 {
  height: calc(650px - 256px);
}

._2Eprj {
  margin: 0;
  padding: 0;
}

._19CwE {
  list-style-type: none;
}

@media (max-width: 720px) {
  ._2yfdF {
    height: 100%;
    gap: 8px 16px;
  }
}
