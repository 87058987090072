._3jEnb {
  width: 100%;
}

.tD6fI {
  flex-grow: 0;
}

._3yH7Y {
  justify-content: center;
}
