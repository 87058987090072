._3cdXc {
  width: 100%;
  text-align: left;
}

._3zN_d {
  min-width: 120px;
  flex-shrink: 2;
}

._13fr6 {
  min-width: 146px;
}
