.wzI9m {
  --btn-min-height: 36px;

  min-height: var(--btn-min-height);
  padding: 0 16px;
  font-size: 15px;
  font-weight: 500;
  font-family: inherit;
  line-height: 1.1;
  white-space: nowrap;
  border-radius: 4px;

  /** reset defaults: */
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
}

._3Ta-6 {
  pointer-events: none;
}

html:not(.focus-not-visible) .wzI9m:focus {
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4);
}

.wzI9m._2k3B5 {
  min-width: 96px;
}

.wzI9m:disabled {
  cursor: default;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.26);
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08);
}

.ZByns {
  --btn-min-height: 28px;

  padding-left: 8px;
  padding-right: 8px;
}

.ZByns._2k3B5 {
  min-width: 64px;
}

.wzI9m._3zFaq {
  min-width: auto;
}

.wzI9m._1q3pD {
  min-width: 152px;
}

._33h0Z {
  font-weight: 400;
}

._25TV0 {
  padding-left: 0;
}

._1CR9x {
  padding-right: 0;
}

._31hKd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._31hKd._1mud_ {
  justify-content: center;
}

._3EAFO {
  display: block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  /**
   * For desired baseline alignment we need to move
   * the text up a little bit. Adding a small padding-bottom is
   * the most robust way to do it without accidentally breaking
   * button height or something else.
   *
   * NOTE:
   * This only works together with "align-items: center" rule
   * for parent `.label` element
   */
  padding-bottom: 2px;
}

._3EAFO._2Cd4w {
  color: rgba(224, 92, 71, 0.95);
}

._3EAFO._1oVtL {
  text-align: left;
}

._1-XUb {
  display: flex;
  width: 28px;
  min-width: 28px; /* disallow shrinking inside flexbox */
  height: 28px;
  margin: 4px;
  align-items: center;
  justify-content: center;
}

._1-XUb._3wN1K {
  margin-right: 0;
}

._1-XUb._945gm {
  margin-left: 0;
}

.ZByns ._1-XUb {
  margin: 0;
}

/** Primary button */
._2CtvE {
  background-color: var(--blue);
  color: var(--white);
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._2CtvE:enabled:hover {
  background-color: var(--blueDark10);
}

._2CtvE:enabled:focus {
  background-color: var(--blueDark20);
}

._2CtvE:enabled:active {
  background-color: var(--blue);
  box-shadow: inset 0 1px 0 0 rgba(16, 16, 16, 0.16);
}

._2CtvE:disabled {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 0.4);
}

._2CtvE._2Cd4w {
  background-color: var(--red);
}

._2CtvE._2Cd4w:active,
._2CtvE._2Cd4w:enabled:active {
  background-color: var(--red);
  background: linear-gradient(
      0deg,
      rgba(16, 16, 16, 0.16),
      rgba(16, 16, 16, 0.16)
    ),
    #e05c47;
  box-shadow: inset 0px 1px 0px rgba(16, 16, 16, 0.16);
}

._2CtvE._2Cd4w:enabled:focus {
  background-color: var(--red);
  box-shadow: 0 0 0 1px #e8a196, 0 1px 0 0 #e8a196;
}
._2CtvE._2Cd4w:hover {
  background-color: var(--red);
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.08);
}

/** Secondary button */
._1XQch {
  color: var(--black);
  background-color: var(--greyLight30);
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._1XQch:enabled:hover {
  background-color: var(--greyLight30);
}

._1XQch:enabled:focus {
  background-color: var(--greyLight30);
}

._1XQch:enabled:active {
  background-color: var(--greyLight20);
  box-shadow: inset 0 1px 0 0 rgba(16, 16, 16, 0.16);
}

/** primaryLanding is a temporary intent to conform to styles on the landing */
.puBbK {
  --btn-min-height: 48px;

  font-size: 20px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  background-color: var(--blue);
  color: var(--white);
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.04);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.puBbK:enabled:hover {
  background-color: #3a5998;
}

.puBbK:enabled:focus {
  background-color: #3a5998;
}

.puBbK:enabled:active {
  background-color: #3a5998;
  box-shadow: inset 0 1px 0 0 rgba(16, 16, 16, 0.16);
}

._2CtvE:disabled {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 0.4);
}

/** Primary button with `white` color variation */
._2CtvE.hVp0l,
.puBbK.hVp0l {
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._2CtvE.hVp0l:enabled:hover,
.puBbK.hVp0l:enabled:hover {
  background-color: var(--greyLight30);
}

._2CtvE.hVp0l:enabled:focus,
.puBbK.hVp0l:enabled:focus {
  background-color: var(--greyLight30);
}

._2CtvE.hVp0l:disabled,
.puBbK.hVp0l:disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

._2CtvE.hVp0l:enabled:active,
.puBbK.hVp0l:enabled:active {
  background-color: var(--greyLight20);
  box-shadow: inset 0 1px 0 0 rgba(16, 16, 16, 0.16);
}

/** Toggle button (like a "radio" button) */
._2bnb6 {
  background-color: transparent;
  color: var(--black);
}

._2bnb6:enabled:hover {
  background-color: #f1f1f1;
}

._2bnb6.wKLN9:enabled:hover {
  background-color: var(--white);
}

._2bnb6:enabled[aria-checked='true'],
._2bnb6:enabled[aria-selected='true'],
._2bnb6.wKLN9:enabled[aria-checked='true'],
._2bnb6.wKLN9:enabled[aria-selected='true'] {
  color: var(--white);
  background-color: var(--blue);
}

._2bnb6._3Y2Jn:enabled[aria-checked='true'],
._2bnb6._3Y2Jn:enabled[aria-selected='true'] {
  color: var(--black);
  background-color: var(--white);
}

._2bnb6.sJ_km:enabled[aria-checked='true'],
._2bnb6.sJ_km:enabled[aria-selected='true'] {
  color: var(--white);
  background-color: var(--blue);
}

/** Default button */
._3JfFD,
._3JfFD:enabled:hover {
  color: var(--black);
  background-color: var(--greyLight30);
}

._3JfFD:enabled:active {
  background-color: #e5e3e3;
  box-shadow: inset 0 1px 0 0 rgba(16, 16, 16, 0.08);
}

._1ePat,
._1ePat:active:hover,
._1ePat:active,
._1ePat:disabled,
._1ePat:enabled:hover {
  color: var(--blue);
  background-color: var(--white);
  box-shadow: 0 0 0 2px var(--blueLight);
  font-size: 15px;
  font-weight: normal;
}

._39P2N,
._39P2N:active:hover,
._39P2N:active,
._39P2N:disabled,
._39P2N:enabled:hover {
  background-color: var(--white);
  font-size: 15px;
  font-weight: normal;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.04);
  padding-bottom: 1px;
}

._39g46 {
  border: none;
  outline: none;
  color: var(--blue);
  border-bottom: 1px dotted var(--blue);
  padding: 0;
  background-color: inherit;
}

._39g46:disabled {
  color: currentColor;
  border-bottom-color: currentColor;
  cursor: not-allowed;
}

._2nzNS {
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 6px;
}
