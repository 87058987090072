.dEH4V {
  margin: -8px 8px -8px 0;
}

._1u03P {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -8px 0 24px;
}

._2OLuQ {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 15px;
}

._1e21K {
  width: 600px;
}
