._2lhCx {
  width: 680px;
  max-height: 0;
  overflow: hidden;
  padding: 0 24px 0 48px;
  opacity: 0;
  transition: max-height 800ms ease, opacity 200ms linear, padding 200ms linear;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
}

._2lhCx._2OmE8 {
  opacity: 1;
  max-height: 500px;
  padding: 16px 24px 24px 48px;
}

._2eMiL {
  font-size: 24px;
  margin: 0 0 12px;
  font-weight: 300;
  line-height: 1.17;
  letter-spacing: -0.5px;
  color: var(--black);
}

._1q5eK {
  margin: 0 0 8px -28px;
  padding: 0 0 0 28px;
  cursor: pointer;
  font-size: 17px;
  line-height: 1.18;
  letter-spacing: normal;
  color: var(--black);
}

._3EtBv {
  display: inline;
  outline: none;
  color: var(--blue);
}

._3EtBv:hover {
  text-decoration: underline;
}

._1q5eK._1AGGw,
._1q5eK._1AGGw ._3EtBv {
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.7);
}

._1q5eK:last-child {
  margin: 0 0 0 -28px;
}

._1QXWc {
  position: absolute;
  margin: 2px 0 0 -26px;
  background-color: transparent !important;
  pointer-events: none;
}

._2CSGC {
  opacity: 0.01;
  max-height: 1px;
}
._2gemM {
  opacity: 1;
  max-height: 200px;
  transition: max-height 0.8s ease, opacity 0.2s ease;
}
.iMHb6 {
  opacity: 1;
  max-height: 200px;
}
._1XCH8 {
  opacity: 0.01;
  max-height: 1px;
  transition: max-height 0.2s ease, opacity 0.2s ease;
}
