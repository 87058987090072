._1f_4s {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: var(--zindex-tooltip);
}

._1dvX9 {
  width: 0;
  position: absolute;
  left: 50%;
  height: 0;
  overflow: visible;
}

@keyframes _3XWRs {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-48px);
  }
}

._3yO4Z {
  position: relative;
  bottom: 0;
  margin: 0 auto;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  height: 44px;
  box-shadow: 0 4px 16px rgba(16, 16, 16, 0.24);
  border-radius: 4px;
  padding: 8px 12px 8px;
  opacity: 1;
  animation: _3XWRs 300ms ease-in-out 0ms 1 none;
  white-space: nowrap;
}

._2w-yc {
  opacity: 0;
}

._2akkZ {
  background-color: var(--black);
}

._2_OXi {
  background-color: var(--red);
}

._1JtDG {
  width: 14px;
  height: 14px;
}

._1JtDG path {
  fill: var(--white);
}

._2eYvA{
  min-width: calc(100vw - 32px);
  white-space: normal;
  height: fit-content;
  align-self: flex-end;
}
