._41ejp {
  padding: 4px 12px;
  border-radius: 14px;
  width: fit-content;
}

._3E_0y {
  display: inline-block;
}

._2fWVz path {
  fill: var(--c-base-light);
}

.w-r12 {
  color: var(--c-base-light);
  font-size: 12px;
  text-decoration: underline;
}

.w-r12:hover {
  text-decoration: underline;
}

._1pXC5 {
  margin-right: -4px;
}
