._1PYG- {
  padding: 12px;
  max-width: 320px;
}

._67ib_ {
  display: block;
  width: calc(100% + 20px);
  margin-top: -10px;
  border-radius: 10px 10px 0 0;
}

.r9ZJE {
  border-radius: 4px;
  border-left: 4px solid var(--c-accent1-tertiary);
  background: var(--c-accent1-secondary);
}

.r9ZJE._3KYPL {
  border-left: 4px solid var(--c-base-extralight);
  background: var(--c-base-extralight);
}

._2PTlo {
  object-fit: cover;
  width: calc(100% + 20px);
  border-radius: 10px 10px 0 0;
  margin: -10px -10px -4px;
  height: 170px;
}
