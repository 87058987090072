.l7KwI {
  width: 560px;
  background-color: var(--greyLight20);
}

._2zp_m {
  padding: 24px 24px 16px;
}

._1t3KK {
  font-size: 18px;
  line-height: 24px;
  color: var(--black);
  font-weight: 500;
}

._2XgJB {
  font-size: 18px;
  line-height: 24px;
  color: var(--black);
  margin-right: 12px;
}

._39nLd {
  flex-grow: 1;
}

._2yXqK {
}

._3o0mn {
  width: 230px;
}

._2yXqK label {
  font-size: 14px;
  white-space: nowrap;
}

._1bqZm {
  font-size: 14px !important;
  line-height: 16px;
  color: var(--black);
}

.Yfksm {
  font-size: 12px;
  line-height: 16px;
  color: var(--greyDark);
}

._3r2wE {
  font-size: 12px;
  line-height: 16px;
  color: #de614d;
  text-align: right;
  padding-top: 4px;
  margin-bottom: -4px;
  height: 0;
}

._3yyYd {
  font-size: 15px;
  line-height: 16px;
  color: var(--black);
}

._2mHbm {
  line-height: 17px;
}

._3RiXf {
  font-size: 15px;
  line-height: 16px;
  color: var(--black);
  margin-left: 8px;
}

._38Kl5 {
  font-size: 12px;
  line-height: 16px;
  color: var(--greyDark);
  width: 400px;
}

._1upyT {
  margin: 0 -8px -2px;
}

.MaWHV {
  font-size: 12px;
  line-height: 16px;
  min-width: 420px;
  text-align: justify;
  padding: 16px;
}

.PC1PY {
  cursor: default;
}
