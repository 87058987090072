._35J2l {
  position: relative;
  padding: 47px 16px 16px;
  border-radius: 8px;
  border: 1px solid var(--greyLight20);
  margin: 24px 24px 0;
}

._3jh-S {
  position: absolute;
  border-radius: 50%;
  background-color: var(--blue);
  top: 16px;
  left: 16px;
}

._2smVM {
  position: absolute;
  top: 20px;
  right: 20px;
}

._2smVM:hover,
._2smVM:focus {
  color: var(--blue);
}
