._22Xbp {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: var(--greyDark);
}

._3L-wj {
  padding-left: 44px;
  padding-top: 24px;
  margin-bottom: 28px;
}

._2_Adu {
  height: 500px;
  width: 600px;
}

._2hvfp {
  height: 400px;
}
