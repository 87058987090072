._3IXbo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: initial;

  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
}

._1NLL7 {
  cursor: pointer;
  outline: none;
}

._2ptCQ {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: -3px;
}

._1pJyw {
  cursor: pointer;
  white-space: nowrap;
  margin-left: 26px;
}

._1Z_9U {
  position: relative;
  margin-left: 4px;
}

._3rki6 {
  padding: 12px 16px;
  padding-top: 0;
}

._1sm7s {
  color: var(--cobaltSemilight);
}

._3_epn {
  /* For firefox */
  scrollbar-color: var(--cobaltSecondary) var(--cobalt);
  scrollbar-width: thin;
}

._3_epn::-webkit-scrollbar {
  width: 12px;
  background: var(--cobalt);
  border: 3px solid transparent;
}

._3_epn::-webkit-scrollbar-thumb {
  background: var(--cobaltSecondary);
  border: 3px solid transparent;
  border-radius: 6px;
  background-clip: content-box;
}

._14lWZ {
  border-left: 1px solid var(--cobaltSub);
  min-width: 260px;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  padding-top: 12px;
  background: var(--cobalt);
}

._14lWZ._2kKPP {
  padding-bottom: 12px;
}

._3wBbz {
  opacity: 0.12;
  pointer-events: none;
}

._1zlb6.WuQRb {
  display: none;
}

@media (max-width: 720px) {
  ._1NLL7 {
    width: 100%;
  }

  ._2ptCQ {
    max-width: 250px;
    color: var(--white);
  }

  ._3Tn4T {
    transform: rotate(-90deg);
  }

  ._14lWZ {
    min-width: 100% !important;
    height: calc(100% - 54px);
    top: 54px !important;
    transform: none !important;
    border-radius: unset;
    border-left: none;
  }

  ._14lWZ._2kKPP {
    padding-bottom: 0;
  }

  ._1pJyw {
    display: none;
  }
}
