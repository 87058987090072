._1JTpm {
  animation: _2Wjq7 1000ms infinite;
}

._2R9Zq {
  width: calc(100% - 64px);
}

._1k2AT {
  width: 100%;
}

._2Hoio {
  position: relative;
}

._2WSCY {
  max-width: 100%;
  min-width: 95px;
  position: relative;
}

._2WSCY:hover {
  cursor: pointer;
}

._1PkZ- {
  position: absolute;
  top: calc(50% - 16px);
}

._3KLSD {
  left: calc(100% + 16px);
}

._2A9kZ {
  right: calc(100% + 16px);
}

._9w8IB.GINyS {
  border-radius: 18px 18px 18px 4px;
}

._9w8IB.Bt50h {
  border-radius: 4px 18px 18px 4px;
}

._9w8IB._1J_sM {
  border-radius: 4px 18px 18px 18px;
}

._1RTXP.GINyS {
  border-radius: 18px 18px 4px 18px;
}

._1RTXP.Bt50h {
  border-radius: 18px 4px 4px 18px;
}

._1RTXP._1J_sM {
  border-radius: 18px 4px 18px 18px;
}

.EDaz6 {
  border-radius: 18px;
}

._5Vra8 {
  width: 100%;
}
