._38LY1 {
  position: relative;
  padding: 47px 16px 16px;
  border-radius: 8px;
  border: 1px solid var(--greyLight20);
  margin: 24px 24px 0;
}

._1FAWq {
  position: absolute;
  border-radius: 50%;
  background-color: var(--blue);
  top: 16px;
  left: 16px;
  padding: 2px;
}

._1vEfv {
  position: absolute;
  top: 20px;
  right: 20px;
}

._1vEfv:hover,
._1vEfv:focus {
  color: var(--blue);
}
