.vK4lC {
  display: inline-block;
  height: fit-content;
  padding: 0 4px;
  vertical-align: 25%;
}

.vK4lC._2ndwF {
  background-color: var(--c-accent3-dark);
}

.vK4lC._3Btku {
  background-color: var(--c-accent2-normal);
}

.vK4lC.agR3k {
  background-color: var(--c-accent4-normal);
}

.vK4lC._1_yjJ {
  background-color: var(--blue);
}
