._2-22v {
  width: 100%;
  padding: 8px 12px 10px;
  white-space: pre-line;
  position: relative;
  word-break: break-word;
}

._10rwA {
  position: relative;
}

.YuVeO._10rwA {
  padding-left: 30px;
}

._3coHv {
  background-color: var(--greyLight20);
}

._3coHv._2McAa {
  border-radius: 18px 18px 18px 4px;
}

._3coHv._2H9Ak {
  border-radius: 4px 18px 18px 4px;
}

._3coHv._2LBZA {
  border-radius: 4px 18px 18px 18px;
}

.YuVeO {
  background-color: var(--violet);
}

.YuVeO._2McAa {
  border-radius: 18px 18px 4px 18px;
}

.YuVeO._2H9Ak {
  border-radius: 18px 4px 4px 18px;
}

.YuVeO._2LBZA {
  border-radius: 18px 4px 18px 18px;
}

.pY3b7 {
  border-radius: 18px;
}
