.rnZYK {
  margin-bottom: 28px;
}

.xR4HO {
  margin-bottom: 28px;
}

._2kUSO {
  display: grid;
  gap: 36px;
  grid-template-columns: minmax(0, 1fr);
}

@media (max-width: 720px) {
  .xR4HO {
    margin-top: 28px;
  }
}
