._3Ir8J {
  position: relative;
}

._2JXyK {
  position: absolute;
  right: 8px;
  z-index: var(--zindex-over-content);
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  outline: none !important;
}

._2JXyK:hover,
._2JXyK._3zngz {
  color: var(--blue);
}

._2JXyK._3zngz {
  cursor: default;
}
