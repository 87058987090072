.rWn10 {
  background-color: var(--white);
  border-left: solid 1px var(--c-base-light);
}

._1sbgr {
  position: relative;
  max-width: 400px;
  min-width: 400px;
}

._1u9W4 {
  overflow-y: auto;
  height: 100%;
}

._1sbgr._2Bx52 {
  max-width: 24px;
  min-width: 24px;
}

._1oL5L {
  background: white;
  width: 100%;
}

._3mamP {
  left: 0;
}

._17gbo {
  margin: 20px 44px 0 24px;
}

._3dyD8 {
  margin: 20px 16px 0 16px;
}
