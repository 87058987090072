._2fUBz {
  display: block;
  width: 100%;
  border: none;
  line-height: 1.2;
  font-size: 15px;
  resize: none;
  padding: 9px 12px;
  min-height: 72px;
  background-color: transparent;
  outline: none;
  font-family: var(--font);
}
