.PhYvO {
  display: flex;
  align-items: center;
  height: var(--control-decorator-min-height);
  padding-left: calc(var(--unit) * 3);
  padding-right: calc(var(--unit) * 3);
}

.PhYvO > * {
  width: 100%;
}
