.lx2xG {
  height: calc(100vh - 10vw - 104px);
}

.HBEtp {
  height: auto;
}

._2pXNs {
  flex-grow: 1;
  overflow: hidden;
}

._1S7BA {
  flex-basis: 20%;
}

._3rPN0 {
  overflow-y: hidden;
}
._3rPN0:hover {
  overflow-y: scroll;
}

._2sZCL {
  padding: 16px;
  background-color: var(--greyLight30);
}

._2Fy7s {
  margin: 0 4px;
}

._14phq {
  padding-top: calc(6 * var(--grid-unit));
}

._1LYf0 {
  position: sticky;
  bottom: 16px;
  z-index: var(--zindex-templates-modal-buttons);
}

._3McM2 {
  position: relative;
  flex-grow: 1;
}

._2AnQ1 {
  height: 320px;
  margin-bottom: 32px;
}

.ZO84q {
  display: flex;
  justify-content: space-between;
}

._2os9l {
  display: flex;
}

._3j7wC {
  margin-top: 2px;
}
