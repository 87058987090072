._1loFF {
  background-color: var(--blue);
}

._3qaLT {
  background-color: var(--blueLight);
}

.cy-TJ {
  line-height: 8px;
  padding: 0px 3px 3px 3px;
  border-radius: 3px;
  margin-right: 4px;
}

.cy-TJ > span {
  line-height: 8px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
}
