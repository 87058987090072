.OFt9v {
  display: grid;
  grid-template-columns: auto 432px;
  background-color: var(--white);
}

._15RTj {
  padding: 44px 32px;
  border-right: 1px solid var(--c-base-light);
}

._3l7BF {
  width: 30%;
  padding: 44px 20px 20px;
  border-radius: 8px;
  background-color: var(--c-base-extralight);
  max-width: 300px;
  min-width: 200px;
}

._2wsYB {
  display: block;
  margin: 0 auto;
}

._14Bot {
  min-width: 120px !important;
}
