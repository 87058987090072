._3EA6E {
  width: 580px;
}

@media (max-width: 720px) {
  ._3EA6E {
    width: unset;
    margin-bottom: 16px !important;
  }
}
