._5nO4b {
  width: 400px;
  height: 246px;
}

._1_bEs {
  display: block;
  margin: 40px auto 0;
}

._2eDem {
  top: 16px;
  right: 16px;
}
