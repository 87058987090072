@media (max-width: 1024px) {
  ._1iEMD {
    width: calc(100vw - 32px);
  }
}

._1dTME {
  border-radius: 4px;
  background-color: var(--c-accent1-normal);
  padding: 2px 4px;
  margin-left: 8px;
}
