._2o5v9 {
  color: var(--greyDark);
}

._3aSW7 {
  position: relative;
  display: inline-block;
}

._3aSW7.jxlc-::after {
  content: '*';
  position: absolute;
  font-size: 15px;
  line-height: 20px;
  color: #d96852;
  top: -4px;
  right: -7px;
  width: 7px;
}

._2Y4kW {
  font-size: 15px;
  line-height: 16px;
  padding: 8px 0 0;
  color: #979797;
}

._1wkbr {
  font-size: 12px;
}

.nTK2g {
  color: var(--black);
}
