._3hE7G {
  padding: 12px 0 45px 24px;
}

._3qnSm {
  animation: RHzWn 1000ms infinite;
  margin: 12px 0 0;
}

@keyframes RHzWn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

._1iQqs {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #373636;
  text-transform: uppercase;
  margin: 8px 0;
}

._26qRq {
  line-height: 16px;
  letter-spacing: normal;
  color: #616060;
  font-size: 13px;
  margin: 0 0 16px;
  width: 338px;
}

@media (min-width: 1460px) {
  ._26qRq {
    width: 441px;
  }
}

._1oY6h {
  color: currentColor;
  text-decoration: underline;
}

._1oY6h:hover {
  text-decoration: none;
}

.pEuZ3 {
  height: 16px;
}

.ZjyZ6 {
  font-size: 13px;
  margin-top: 8px;
}

._2yw4u {
  font-size: 13px;
  color: #616060;
}

._3mSiL {
  width: 338px;
  margin-bottom: 16px;
  margin-top: 4px;
}

@media (min-width: 1460px) {
  ._3mSiL {
    width: 441px;
  }
}

._16uxF {
  color: var(--grey);
}

._3cgHK {
  color: #8a9095;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: var(--zindex-popover) !important;
  opacity: 0.9;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
          user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
}
