._2ei2n path {
  fill: var(--white);
}

._380zm {
  width: 130px;
  height: 130px;
}

._9DE-F {
  height: 100%;
}

.jODIt {
  margin-left: -8px;
}

._3uIAb {
  margin-left: 8px;
}

._1XGaK {
  background-color: var(--white);
  border: 1px solid var(--greyLight30);
  border-radius: 50%;
}
