._3_m_V {
  --border-radius: 18px;
  --border-radius-joint: 4px;

  display: inline-block;
  min-width: 90px;
}

._2i4so {
  max-width: calc(100% - 64px);
}

._3edif {
  font-size: 15px;
  word-break: break-word;
}

/**
 * By western convention, outgoing messages are aligned to the right edge,
 * and incoming messages are alinged to the left edge
 */
.FkvMa {
  background-color: #0084ff;
  color: var(--white);
}

._3A4zM {
  background-color: var(--greyLight20);
  color: var(--black);
}
