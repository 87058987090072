._3Wdlx {
  padding: 0 12px 10px;
}

._1uPhr {
  color: #979797;
  font-size: 15px;
}

._15UC_ {
  font-size: 15px;
  line-height: 1.33;
  color: var(--black);
  padding: 0 12px 28px;
  margin: -4px 0 0;
}

.KCFRW {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  padding: 10px 12px 0;
  color: var(--greyDark);
}

._4Z2jg {
  font-size: 15px;
  line-height: 1.33;
  color: var(--black);
  padding: 20px 12px 0;
}

.jwqRa {
  color: #979797;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 8px;
}
