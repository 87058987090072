._3fn6K {
  background-color: var(--antiqueWhite);
  padding: 12px;
  border-radius: 8px;
}

._1WHUP {
  flex: none;
}

._2mHw7 {
  flex: none;
  width: 20px;
  height: 20px;
  background-color: var(--white);
}
