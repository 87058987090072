._3MCB3 {
  display: flex;
  padding: 0;
  background-color: var(--white);
  width: 597px;
  border-radius: 8px;
  align-items: center;
}
._1p8Vw {
  width: 512px;
}

._3MCB3 > div:nth-child(2) {
  border-radius: 8px 0 0 8px;
}

._3MCB3 > div:last-child {
  border-radius: 0 8px 8px 0;
}

._1Z4CW {
  display: block;
  margin: 1px 0;
  width: 1px;
  height: 66px;
  background: var(--greyLight20);
}

._12PIp {
  color: var(--white);
}

.t7SWP {
  border-radius: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

html:not(.focus-not-visible) .t7SWP:focus {
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4);
}

.v6kwY {
  width: 85px;
  height: 68px;
  cursor: pointer;
  transition: color 0.3s;
  padding: 0;
}

.v6kwY ._3yvgk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.v6kwY:hover {
  background: var(--greyLight20);
}

.v6kwY ._2kkXs {
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin: 0;
}

.v6kwY ._2kkXs svg {
  width: 20px;
  height: 20px;
}

.v6kwY ._3Swd4 {
  font-size: 11px;
  line-height: 16px;
  margin-top: 8px;
  flex-grow: 0;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
}

._1TIQE {
  margin-bottom: 10px;
  flex: 0 1 25%;
  display: flex;
  align-items: flex-start;
  padding: 5px 10px 5px 5px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 6px;
  width: 100%;
}

._1TIQE ._3yvgk {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 0;
}

._1TIQE:hover {
  background-color: var(--greyLight20);
}

._1TIQE ._2kkXs {
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin: 0;
  flex: 0 0 22px;
  padding-left: 2px;
  object-fit: contain;
}

._1TIQE ._2kkXs svg {
  width: 20px;
  height: 20px;
}

._1TIQE ._3Swd4 {
  flex: 1 1 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
  white-space: pre-wrap;
  text-align: start;
}

.Lnxjl {
  position: relative;
}

.Lnxjl:hover {
  background-color: transparent !important;
}

.Lnxjl:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 10px;
  border: 1px solid #e75c4550;
  border-radius: 6px;
}

.Lnxjl ._2kkXs {
  flex: 0 0 auto;
  height: 20px;
  width: auto;
  position: relative;
}

.Lnxjl ._2kkXs svg {
  width: auto !important;
}
