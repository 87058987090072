._1IZbu {
  width: 440px;
}

._316it {
  width: 100%;
}

._3NR5a {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: var(--blue);
  vertical-align: -2px;
}

._31R1B:hover {
  text-decoration: underline;
  color: var(--blue);
}
