._12Dm- {
  width: 100%;
  margin: 8px;
}

._8giWD {
  width: 140px;
}

.k9BHR {
  width: 360px;
  color: var(--black);
}

.Fx1T_ {
  height: 36px;
  width: 36px;
}

._2iAww {
  width: 360px;
}

.mf1VS {
  font-weight: 600;
}
