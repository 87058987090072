._29h-v {
  position: absolute;
  bottom: -16px;
  height: 24px;
  font-size: 11px;
  padding: 0 8px 2px;
  border-radius: 12px;
  border: 2px solid var(--white);
}

._29h-v:hover {
  cursor: pointer;
}

.Z7heR {
  left: 8px;
  background-color: var(--greyLight20);
}

._2Lt8T {
  background-color: var(--greyLight20);
}
