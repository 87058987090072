._1O06t {
  padding-left: 36px;
  padding-top: 16px;
}

._3yCfu {
  width: 1080px;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 24px;
}

._3XjH3 {
  width: 1080px;
  min-height: 732px;
  background-color: var(--greyLight30);
  border-radius: 8px;
}

._3EKH6 {
  flex: none;
  width: 560px;
  background-color: var(--white);
  border-radius: 8px 0 0 8px;
  padding: 24px 24px 24px 32px;
}

._1morp {
  flex: none;
  position: relative;
  width: 520px;
}

.lLwAu {
  margin: -4px 0 32px 8px;
  width: 310px;
  justify-content: space-between !important;
}

._29WIA {
  margin-top: 32px;
  flex: none;
}

._3pDx8 {
  padding: 7px 0 0 12px;
  resize: none;
  width: 100%;
  border: none;
  background-color: transparent;
  min-height: 54px;
  font-size: 15px;
}

._3GRuS {
  min-width: 245px;
  display: inline-block;
  position: relative;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08) !important;
}

._1E9l- {
  margin: -4px -12px 8px;
}

.CTLvM {
  align-items: flex-end;
}

._39j8D {
  height: 24px;
  display: flex;
  outline: none;
}

._2QtCz {
  display: flex;
  align-items: center;
  height: 32px;
}

.kOeKd {
  margin-right: 8px;
}

._130co {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  position: relative;
  top: 1px;
}

._130co path {
  fill: var(--grey);
}
