.PttgJ {
  width: 100%;
}

._1dWmO {
  background-color: var(--c-base-extralight);
  padding: 12px 16px;
  flex: 1;
  border-radius: 4px;
}

._1dWmO.ktfYZ {
  height: 136px;
}

@media (max-width: 700px) {
  .PttgJ {
    overflow-x: auto;
  }

  ._1dWmO {
    flex: none;
    flex-basis: 196px;
  }
}
