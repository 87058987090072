._2uZb8 {
  height: 608px;
}

._3O5_I {
  overflow-y: scroll;
  padding-right: 10px;
}

._2-Cx2 {
  border-radius: 4px;
  padding: 14px;
}

._1KgWJ {
  background-color: var(--blueLight30);
}

._2pIMJ {
  background-color: var(--antiqueWhite);
}

._2fw3H {
  align-self: flex-start;
}

._1M2UP {
  width: 100%;
}
