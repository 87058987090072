.lRkd9 {
  display: flex;
  justify-content: center;
  min-width: 14px;
  min-height: 14px;
  padding: 2px;
  box-sizing: border-box;
  background-color: var(--blue);
  border-radius: 7px;
  color: var(--white);
  font-size: 8px;
  font-weight: bold;
}
