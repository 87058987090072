._1PLYB {
  border-radius: 0 8px 8px 0;
  width: 100%;
  height: 100%;
}

._2Z3E5 {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--black);
  font-weight: 600;
  padding-top: 32px;
}

._3GLet {
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

._1Ri7H {
  width: 450px;
  background: var(--white);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 48px 32px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}

._3ak4g {
  font-size: 28px;
  line-height: 34px;
  color: var(--black);
  font-weight: 500;
  margin-bottom: 8px;
  word-wrap: break-word;
}

._1ko33 {
  font-size: 18px;
  line-height: 24px;
  color: var(--greyDark);
  margin-bottom: 28px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

._2HHQx {
  margin: 0 auto;
  display: inline-block;
}

._3JdL2 {
  height: 48px;
  background: #0d7eff;
  border-radius: 8px;
  color: var(--white);
  font-size: 18px;
  line-height: 24px;
  padding: 0 24px 0 20px;
  font-weight: 500;
}

._1K6KT {
  margin-right: 16px;
}

._2X7hk {
  margin-top: 12px;
  font-size: 15px;
  line-height: 16px;
  color: #666666;
  width: 1px;
  white-space: nowrap;
  flex-wrap: nowrap;
}

._3KebU {
  background: no-repeat center center #dddddd;
  background-size: contain;
  width: 24px;
  height: 24px;
  flex: none;
  border-radius: 12px;
  border: 1px solid #dddddd;
  margin-right: 8px;
}

._2Q5wi {
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  color: #999999;
  margin-left: 8px;
  padding-bottom: 2px;
  border-bottom: 1px solid #999999;
  margin-bottom: -2px;
}

._1pgxw {
  position: absolute;
  top: 20px;
  right: 20px;
}

._1pgxw path {
  fill: var(--grey);
}
