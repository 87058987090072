._2x_IQ {
  background-color: var(--white);
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  border-radius: 4px;
  width: 100%;
}

._1CUbY {
  margin: 10px 0;
  width: 240px;
}

._CdYG.rVi_A {
  color: var(--c-accent2-normal);
}

._CdYG[value=''] {
  background-color: var(--c-base-extralight);
}

._1tDtH:disabled {
  background-color: var(--c-base-extralight);
  box-shadow: none;
}

._1tDtH:disabled ._1N-HJ {
  color: var(--c-base-semilight);
}

._1B8_r {
  min-height: 56px;
  margin: auto;
}

._3tU-1 {
  margin-left: auto;
}

@media (max-width: 720px) {
  ._2x_IQ {
    flex-wrap: wrap;
  }

  ._2kOrD {
    order: 2;
    margin-bottom: 16px;
    min-height: auto;
  }

  ._1CUbY {
    flex: auto;
    flex-basis: 100px;
  }

  ._1tDtH {
    margin: 10px 0;
  }

  ._2eZmO {
    margin-bottom: 16px;
    width: 100%;
  }
}
