._2ScHp {
  display: flex;
  color: var(--grey);
  font-size: 12px;
  line-height: 24px;
  margin: 0 0 -3px;
  flex-grow: 2;
  justify-content: flex-end;
}

._2ScHp.gGHZK {
  color: #7a797a;
}

._38RAa {
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 10px 0 8px;
}

._3o4Bx {
  margin: 0 8px 0 0;
}

.ekrxs {
  margin: 0 3px -1px 0;
}
