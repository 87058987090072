._9IadX {
  margin: 0;
  margin-left: -10px;
  padding-left: 24px;
  width: 192px;
}

._2PMAb {
  margin-bottom: 4px;
}
