.B8Xgd {
  height: 100%;
  width: 100%;
  position: relative;
}

._2-HUX {
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

._1k_MB {
  pointer-events: none;
  z-index: 2;
}

._1k_MB * {
  pointer-events: auto;
}
