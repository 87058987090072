._30cNd {
  padding: 28px 40px;
}

._1HToK {
  height: 36px;
}

._1Poke {
  gap: 8px;
  flex-wrap: wrap;
  align-items: stretch;
}

.WgpBf {
  position: relative;
  gap: 40px 8px;
}

._18Qea {
  width: 50%;
}

._2bnYa {
  width: 50%;
}

.ks2jF {
  position: absolute;
  right: -36px;
  top: 1px;
}

._1IaaX .oaj3h {
  display: none;
  margin-top: 2px;
  margin-left: -12px;
  align-self: flex-start;
}

._1IaaX:hover .oaj3h {
  display: inherit;
}

._1RZ09 {
  position: relative;
  gap: 8px;
}

.BDTac {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  min-width: 100%;
}

.BDTac:hover ._2tc1G {
  text-decoration: underline;
}

._2tc1G {
  position: absolute;
  bottom: -20px;
  left: 127px;
}

@media (max-width: 830px) {
  ._1RZ09 {
    gap: 20px;
  }
}

@media (max-width: 700px) {
  ._30cNd {
    padding: 18px 32px;
  }
  .WgpBf {
    flex-direction: column;
  }
  ._18Qea {
    width: auto;
  }
  ._2bnYa {
    width: auto;
  }
  .ks2jF {
    right: -16px;
  }
  ._1Poke {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 8px;
  }
}
