.OCCjX {
}

.GZl8_ {
  width: 105px;
  flex: none !important;
  position: relative;
}

.zfko7 {
  display: block;
  width: 100%;
  border: none;
  line-height: 1.2;
  font-size: 15px;
  resize: none;
  padding: 9px 12px;
  background-color: transparent;
}

.tdtsg {
  display: flex;
  margin: 0 0 4px;
}

._1uj73 {
  font-size: 15px;
  line-height: 16px;
}
._1uj73._2A83u {
  font-weight: 500;
  color: var(--black);
}

.hduTM {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 8px;
  width: 27px;
  height: 18px;
  font-size: 11px;
  color: var(--white);
  background: var(--red);
  border-radius: 4px;
}

._1cvjW {
  min-height: 50px;
  color: var(--black);
}

._3rO65 {
  margin: 0 0 8px;
}

.DsE7U {
  display: flex;
  align-items: center;
  margin: 0 0 12px;
}

._1zjJt {
  width: 200px;
  margin: 0 12px 0 0;
  flex: none;
}

._1zC6v {
  width: 388px;
  flex: none;
}

._1OCga {
  margin-left: 8px;
}

._3gYR2 {
  color: var(--black);
  display: flex;
  align-items: center;
}

._21qns {
  margin: 0 8px 0 0;
}

.pswpX {
  margin-right: 8px;
  color: var(--greyDark);
}

._2xXtY {
  color: #df624e;
}

._30yVT {
  resize: vertical !important;
  max-height: 400px;
  min-height: 144px !important;
  height: 144px;
}

.cCuP2 {
  width: 136px;
}

._3quV8 {
  width: 508px;
}

.GQRM5 {
  max-height: 118px;
  word-wrap: break-word;
  word-break: break-all;
  overflow: auto;
}

._2fP6- {
  min-width: 0 !important;
}

._2FKMs {
  display: block;
  margin-top: 8px;
}
