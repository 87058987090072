.zFCiz {
  transition: transform 0.3s linear;
}

.zFCiz.Rcjf0 {
  transform: rotate(-90deg);
}

.zFCiz._209Gj {
  transform: rotate(90deg);
}

.C6jj1 {
  margin-right: 60px;
}
