._35P9U {
  flex: 1;
}

._1ibpD {
  width: 100%;
}

._2uyxZ {
  box-shadow: unset !important;
}
