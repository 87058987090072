._3rnwo {
  width: 360px;
}

._3xYnU {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._2WMXI {
  word-wrap: break-word;
}

._2wYtf {
  height: calc(100% - 8px);
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  display: flex;
  margin: 4px;
  padding: 2px 8px 2px 8px;
  background-color: var(--blueLight30);
  border-radius: 4px;
  width: fit-content;
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
  max-width: calc(100% - 40px);
  display: inline-block;
}

._SalZ {
  opacity: 0;
  cursor: default;
}

._3oghC {
  opacity: 0.7;
  cursor: default;
}

._1hiJ9 {
  cursor: pointer;
  width: 252px;
}
