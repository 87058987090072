._1MHmE {
  padding: 0px 12px 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

._1zu4v {
  flex-grow: 1;
}

._2qxsl {
  flex-grow: 0;
  width: 60px;
}

._12CD2 {
  flex-grow: 1;
}

._3y-BY {
  border-radius: 4px;
  background: var(--c-base-light);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 292px;
  aspect-ratio: 1.8;
  overflow: hidden;
  position: relative;
}

._3wFY- {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._1347B {
  border: 2px dotted var(--c-accent1-normal);
}

._4tQxj {
  font-size: inherit;
}

._2xTPc {
  max-width: 100%;
}

._1m4Oe {
  position: absolute;
  z-index: var(--zindex-over-content-controls);
  top: 4px;
  right: 4px;
  padding: 0;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
