._2GYxv {
  border-bottom: 1px solid var(--c-base-light);
  height: 70px;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

._34XLy {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: var(--c-accent1-light);
}

._34XLy._1S-KY {
  background-color: var(--c-accent1-secondary);
  pointer-events: none;
  cursor: default;
}
