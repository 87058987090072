._3boXV {
  font-size: 16px;
  line-height: 1.88;
  position: relative;
  cursor: default;
  color: var(--black);
  transition: color 200ms;
}

._3boXV.yRvcC {
  color: var(--blue);
}

._1HAhC {
  text-decoration: underline;
  text-decoration-color: var(--greyDark);
}

._1HAhC:hover {
  text-decoration: none;
}

._1HAhC._20c6I {
  text-decoration: none;
}

._20c6I {
  display: inline-block;
  background-color: var(--blue);
  margin-left: 8px;
  padding: 4px;
  border-radius: 2px;
  font-size: 11px;
  line-height: 10px;
  color: var(--white);
  font-weight: 500;
}
