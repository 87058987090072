._2ofz5 {
  display: inline-block;
  border-radius: 4px;
  padding: 1px var(--unit) 2px;
  color: var(--white);
  background-color: var(--greyLight20);
  font-size: 11px;
  line-height: 12px;
}

._1q7dg {
  background-color: var(--blue);
}

._2jt6O {
  background-color: var(--green);
}

._2MTBr {
  background-color: var(--red);
}

._3CDcP {
  background-color: var(--blue);
  color: var(--white);
}

._2e7TM {
  background-color: var(--grey);
  color: var(--greyDark);
}

._11Rx0 {
  background-color: transparent;
  color: var(--greyDark);
}

._1gCoE {
  background-color: var(--white);
  color: var(--blue);
}

._1_zqI {
  background: var(--instagram-gradient);
  color: var(--white);
}

.L5ufu {
  background-color: var(--fuchsia);
  color: var(--white);
}

._3PdIM,
.aRPTH {
  height: 28px;
  line-height: 26px;
  border-radius: 4px;
  font-size: 15px;
  padding: 0 8px;
}

._29Iqt {
  padding: 0 2px;
  border-radius: 2px;
}

._1UVpf {
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  height: calc(var(--unit) * 4 - 1px);
  min-width: calc(var(--unit) * 4 - 1px);
}

._1IMou {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
