._2Qs6X {
  position: absolute;
  background-color: var(--greyLight20);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  z-index: 1;
}

._2keSA {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

._2Qs6X,
._2keSA {
  width: calc(100vw - 125px);
}
@media (max-width: 1024px) {
  ._2Qs6X,
  ._2keSA {
    width: calc(100vw - 68px);
  }
}

._19rvc {
  text-align: center;
  margin-top: 160px;
}

._2Bp6R {
  font-size: 36px;
  opacity: 1;
  color: var(--black);
  line-height: 48px;
  margin-bottom: 16px;
  font-weight: 300;
}
