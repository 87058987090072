._18p10 {
  position: relative;
  min-width: 80px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 15px;
  line-height: 17px;
  min-height: 36px;
  padding-left: 4px;
}

._18p10._2F746 {
  padding-left: 0;
  border: none;
  background-color: inherit;
  box-shadow: none;
}

.U-Lr7 {
  color: inherit;
  background-color: #fffefe;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

.C5Dus {
  padding: 0;
}

._2ywGh {
  color: var(--grey);
  background-color: var(--greyLight30);
  border: solid 1px rgba(16, 16, 16, 0.08);
}

.CsXqP {
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4),
    0 0 0 1px rgba(16, 16, 16, 0.08), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._387hX {
  color: #f06040;
  background-color: #fffefe;
  box-shadow: 0 0 0 2px rgba(240, 96, 64, 0.4), 0 0 0 1px rgba(16, 16, 16, 0.08),
    0 1px 0 0 rgba(16, 16, 16, 0.04);
}

.brk6- {
  color: var(--black);
  background-color: #fffefe;
  box-shadow: 0 0 0 2px rgba(240, 96, 64, 0.4), 0 0 0 1px rgba(16, 16, 16, 0.08),
    0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._8jPgE,
._1bgVu {
  color: var(--grey);
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  display: flex;
  justify-content: center;
}

.Ej0IS {
  width: 100%;
  border: none;
  resize: none;
  background-color: transparent;
  margin: 4px 0;
}

.Ej0IS:focus {
  flex-grow: 10;
}

.Ej0IS::placeholder {
  color: transparent;
}

._12H8x {
  margin-left: -8px;
  cursor: text;
}

._1btag {
  margin-right: -8px;
  background-color: var(--white);
  position: relative;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

._1btag::before {
  content: '';
  display: block;
  width: 16px;
  height: 28px;
  background-image: linear-gradient(
    to left,
    var(--white),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  left: -16px;
  top: 0;
}

._3QV9m {
}

._3QV9m::before {
  display: none;
}
