._1__of {
  max-height: 408px;
}

@media (max-width: 720px) {
  ._2wjLI {
    flex: auto;
    margin: 0 16px;
  }
}
