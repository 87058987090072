._2keUB {
  background-color: var(--greyLight30);
  padding: 24px 32px 24px;
}

.ztLuU {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

._13Ht2 {
  flex: auto;
  overflow: hidden;
}

._3MKwI {
  flex: none;
}

._1NJ5b {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  font-size: 12px;
  margin: 0;
}

.KWsPm {
  height: 20px;
  line-height: 18px;
}

.PFttZ {
  text-align: right;
}
