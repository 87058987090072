._3hlfA {
  position: fixed;
  bottom: 20px;
  left: 24px;
  z-index: var(--zindex-modal);
}

@media (max-width: 830px) {
  ._3hlfA {
    left: 50%;
    transform: translateX(-50%);
  }
}
