._1QRQG {
  position: relative;
  width: auto;
  height: 110px;
  border-radius: 8px;
  padding: 0 50px 0 32px;
}

._1hfWZ {
  margin-right: 24px;
  width: 70px;
  height: 70px;
}

._1QyuV {
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 2px;
}

._3nl7h {
  font-size: 15px;
  line-height: 22px;
  color: var(--white);
  opacity: 0.7;
}

._3b3v4 {
  position: absolute;
  z-index: 2;
  top: 16px;
  right: 16px;
  line-height: 16px;
}
