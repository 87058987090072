.Gnvym {
  position: relative;
  height: 36px;
  width: 100%;
  background-color: var(--blueLight30);
  border-radius: 4px;
  padding: 8px;
  flex: 1 1 auto;
}

.uFu5u {
  flex: none;
  visibility: hidden;
}

._3fOP_:hover .uFu5u {
  visibility: visible;
}

._1q5kj {
  flex: none;
  visibility: hidden;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 24px;
}

._3fOP_:hover ._1q5kj {
  visibility: visible;
}
