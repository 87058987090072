._16FWF {
  padding: 8px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  min-height: 44px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  margin-top: 4px;
}

._3-13D {
  background-color: rgba(224, 92, 71, 0.95);
}

._2RPwU {
  width: 412px;
}

._1kZT- {
  width: 100%;
}

._1tdXu {
  font-weight: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: var(--white);
  display: flex;
  align-items: center;
}

._2w8uH {
  margin: 0 4px 0 0;
}

@media (max-width: 830px) {
  ._2RPwU {
    width: 360px;
  }
}
