.jODl_ {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

._3bvR8 {
  margin-right: 4px;
  width: 114px;
  height: 28px;
}

._25o0s {
  display: inline-block;
  width: 110px;
  height: 21px;
  vertical-align: bottom;
  margin-left: 8px;
}

._1e1sb {
  position: relative;
}

.O242d {
  position: absolute;
  right: 44px;
  top: -300px;
}

._1R6-s {
  width: 480px;
  height: 95px !important;
}
