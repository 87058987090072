._3EaNG {
  width: 100%;
}

._2uXGm {
  position: relative;
}

.Nlh6t {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--zindex-over-content);
}
