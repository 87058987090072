.LurBP {
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 24px;
  background-color: #e9e8e8;
  -webkit-user-select: none;
          user-select: none;
  transition: background-color 80ms;
  flex: none;
  outline: none;
}

._1c1ek::after {
  content: '';
  width: 18px;
  height: 18px;
  margin: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24), 0 1px 0 0 rgba(0, 0, 0, 0.08);
  background-color: #fcfcfc;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  pointer-events: none;
  transition: transform cubic-bezier(0.2, 0.7, 0.5, 1) 80ms;
}

._3oPmE {
  background-color: var(--blue);
}

._3oPmE::after {
  transform: translateX(19px);
}

.oP-nE {
  width: 32px !important;
  height: 18px !important;
  border-radius: 18px !important;
}

.f6RpY::after {
  width: 12px !important;
  height: 12px !important;
  margin: 3px;
}

.bkPE3::after {
  transform: translateX(13px) !important;
}

._1KvmZ {
  opacity: 0.7;
}
