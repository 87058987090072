._2ezdO {
  display: grid;
  grid-template-rows: auto;
  white-space: nowrap;
  gap: 4px;
}

._2J-GX {
  height: 82px;
  padding: 8px 10px;
  box-sizing: border-box;
}

._1_PoW {
  align-self: flex-start;
}

._45MNC {
  height: 4px;
  border-top: 1px solid var(--grey);
  grid-column: 1 / -1;
}

.w-Ckf {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 10px;
}

._3p8Vh {
  background-color: rgba(136, 190, 238, 0.1);
  border-radius: 4px;
  min-width: 84px;
}

._3w8KL {
  align-self: center;
}

._1If-J {
  position: relative;
  margin-left: 4px;
}

._1If-J:before {
  content: ' ';
  top: 50%;
  left: 0;
  position: absolute;

  width: 100%;
  height: 0;

  border-bottom: 1px solid var(--greyDark);
}

._1GTc0 {
  margin-left: 16px;
}
