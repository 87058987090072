._28rQC {
  justify-content: center;
  margin-bottom: 24px;
}

._261X- {
  width: 100%;
}

._2J1kI,
._2J1kI:enabled {
  background-color: var(--c-purple-normal);
}

._2J1kI:enabled:hover {
  background-color: var(--violet);
}

@media (max-width: 720px) {
  ._28rQC {
    justify-content: normal;
    overflow-x: auto;
    flex: none;
    width: calc(100% + calc(var(--plans-page-paddind) * 2));
    padding: 16px var(--plans-page-paddind);
    margin: 0 calc(var(--plans-page-paddind) * -1);
    margin-bottom: 8px;
  }
}
