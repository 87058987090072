._3udZm {
  flex: 1 1 100%;
  font-size: 15px;
  border: none;
  padding: 0;
  background-color: transparent;
  outline: none;
}

._31BJJ {
  background-color: var(--greyLight20);
  height: 28px;
  min-height: 0;
}
