._16gYO {
  display: flex;
}

._1lnBP {
  margin-left: 14px;
}

._3Uubc {
  font-size: 19px;
  font-weight: 300;
  line-height: 1.78;
}

._2vqCj {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

._12RuF {
  opacity: 0.3;
  cursor: pointer;
  margin-left: 8px;
}

._12RuF:hover {
  opacity: 1;
}

/* WORKAROUND BECAUSE OF GLOBAL STYLES FROM ANGULAR see packages/dashboard/src/ui/_common/element-state.less */
/* We need to create more specific selector */
._3cNB-._3cNB-._3cNB- {
  opacity: 0.8 !important;
}

/* WORKAROUND BECAUSE OF GLOBAL STYLES FROM ANGULAR see packages/dashboard/src/ui/_common/element-state.less */
/* We need to create more specific selector */
._3cNB-._3cNB-._3cNB-:hover {
  opacity: 1 !important;
}

.QlcsU {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

._1iFaA {
  width: 100%;
  display: flex;
}
