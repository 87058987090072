._3-mvt {
  width: 100%;
  padding: 48px 40px;
  margin: 0 auto;
  max-width: 1040px;
}

._3HYkg {
  width: 100%;
}
