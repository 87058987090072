.WzojC {
  margin: 0 auto;
}

.UW2Ci {
  animation: IHF-v 300ms;
  transform-origin: top;
}

._32R0p {
  width: 100%;
}

@keyframes IHF-v {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  top {
    opacity: 1;
    transform: scale(1);
  }
}
