.jxFLW {
  position: relative;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
}

.qiVf5 {
  margin-right: 8px;
  margin-bottom: auto;
  flex: none;
}

._1DdzL {
  flex-grow: 2;
}

._2yFa_ {
  padding: 16px 0;
}

._288dE {
  margin-left: 16px;
  margin-bottom: 0;
  margin-top: 0;
}

._79Brh {
  width: 100%;
  margin: 0;
}

._288dE button {
  box-shadow: none;
}

._1g_Wy {
  margin-top: 12px;
}

._288dE._3ZtQZ,
._288dE._3ZtQZ button {
  width: 100%;
  margin-left: 0;
}

._1bMOh {
  margin: 0 0 auto 24px;
  cursor: pointer;
}

._1TCb7 {
  position: absolute;
  top: 16px;
  right: 16px;
}

._1IJS2 {
  opacity: 0;
  transform: scale(0.9);
}

._2e2po {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

._3btAL {
  opacity: 1;
}

._2tk2y {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.jxFLW._1PMNP {
  flex-direction: row;
}
