._15W9L {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: url(/src/modern-ui/ListItemWithPicture/pictures/32Uk4.svg) no-repeat center center;
  background-size: cover;
  overflow: hidden;
  flex: none;
  margin-right: 12px;
}

._2dsxP {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

._3-h09 {
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -2px;
}

._1M5Wi {
  display: flex;
  align-items: center;
}
