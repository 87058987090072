._2jM3T {
  max-height: 350px;
  max-width: 800px;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;
}

._1PQta {
  opacity: 0.5;
}

._3PWcI {
  border: 2px solid var(--red);
}

._1poNN {
  position: relative;
  max-width: calc(100% - 100px);
}

._3YK0i {
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  width: 0;
  background-color: var(--blue);
  transition: width ease-in-out 1000ms;
  will-change: width;
}

._2Q8Vo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--grey);
}

._3T6er {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

._2Ft5R {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: var(--black);
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
