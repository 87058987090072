._1bBwD {
  display: flex;
  height: 56px;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  cursor: pointer;
  color: var(--black);
  text-decoration: none;
}

._1bBwD:hover,
._2D2cZ {
  background-color: #e6e5e5;
}

._3MYqg {
  padding: 0;
  margin: 0 16px;
  height: 64px;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
}

._3MYqg:hover {
  background-color: inherit;
}

._1Lm4a {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-grow: 1;
}

._1hmtH {
  display: flex;
}

._3yfiQ {
  min-width: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

._2Po3K {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
}

._7ZLkE {
  height: 22px;
}

._1kB_V {
  pointer-events: none;
  cursor: default;
}

.P7T3w {
  display: flex;
}

._3PU_E {
  border-radius: 4px;
}

._3PU_E:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
