._16WZh {
  width: 100%;
}

._16WZh tbody {
  border-radius: 8px;
}

._16WZh td {
  background-color: var(--greyLight30);
}

._16WZh tr:hover td {
  background-color: var(--white);
}

._16WZh tr:first-child td:first-child {
  border-top-left-radius: 8px;
}
._16WZh tr:first-child td:last-child {
  border-top-right-radius: 8px;
}
._16WZh tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
._16WZh tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

._16WZh th,
._16WZh td {
  padding: 16px 12px;
  text-align: left;
  white-space: nowrap;
}

._16WZh th {
  width: 155px;
}

._16WZh th:first-child {
  width: auto;
}

._16WZh th:last-child {
  width: 80px;
}

._16WZh td {
  cursor: pointer;
}

._ERck {
  visibility: hidden;
}

._16WZh tr:hover ._ERck {
  visibility: visible;
}
