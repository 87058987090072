.cVpxh {
  flex-direction: row;
  width: 100%;
}

._1Vy9R {
  width: 50%;
  flex: none;
}

@media (max-width: 700px) {
  .cVpxh {
    flex-direction: column-reverse;
  }

  ._3hvuo {
    width: 174px;
    height: 104px;
    margin: auto;
  }

  ._1Vy9R {
    width: 100%;
  }
}
