._2Jhbl {
  width: 547px;
  font-size: 17px;
  line-height: 1.47;
  margin-bottom: 24px;
}

._2ysmM {
  font-size: 17px;
}

.QOpld {
  width: 410px;
  height: 100px;
  font-size: 15px;
}
