.oY-ZD {
  height: 100%;
}

._2YZVa {
  cursor: grab;
  flex: 1;
}

._2QLpI {
  margin-right: 8px;
}
