._3dtZO {
  width: 627px;
  background-color: var(--white);
}

._3QJoi {
  top: 25px;
  right: 32px;
}

._3Bywj {
  text-align: center;
}

._15dJd {
  margin: -16px;
  width: 579px;
  height: 351px;
}
