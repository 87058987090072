._1I7gO {
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  color: var(--black);
}

._1hiXa {
  width: 190px;
  margin-bottom: 8px;
}

.BOFK4 {
  margin-bottom: 16px;
}

.gCJ4t {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0 24px;
}

.LF7j_ {
  width: 355px;
  box-shadow: 0 0 20px rgba(20, 20, 20, 0.1);
  border-radius: 16px;
}

.tzV_G {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  flex: 0 0 auto;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: var(--black);
  border-bottom: 1px solid var(--greyLight20);
}

._1dIcH {
  overflow: hidden;
  position: relative;
  height: 355px;
  width: 100%;
  padding: 10px 18px;
}

._3jkXY {
  border-radius: 20px 20px 20px 0;
  padding: 13px 16px;
  background-color: var(--greyLight30);
  width: 306px;
  margin-bottom: 10px;
}

._3ZPMs {
  border-radius: 20px;
  padding: 13px 16px;
  margin-bottom: 10px;
  width: 218px;
  background-color: var(--greyLight30);
}

._3jkXY > div:nth-child(2) {
  background-color: var(--greyLight20);
  width: 110px;
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.YCToW {
  border: 1px solid var(--greyLight30);
  border-radius: 8px;
  width: 240px;
  height: 195px;
}

._1DkAJ {
  padding: 12px 14px;
  height: 125px;
}

._2OAEZ {
  padding: 18px;
  width: 100%;
  height: 68px;
  border-top: 1px solid var(--greyLight30);
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
}

.O2i3i {
  position: absolute;
  top: -15px;
  left: 18px;
  border-radius: 20px;
  width: 300px;
  padding: 13px 16px;
  margin-bottom: 10px;
  background-color: var(--greyLight30);
}

._3jkXY > div:first-child,
._3ZPMs > div:first-child,
.O2i3i > div:first-child {
  background-color: var(--greyLight20);
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

._1HTn4 {
  position: absolute;
  top: 36px;
  left: 18px;
  display: flex;
  flex-direction: row;
}

._3S0_y {
  width: 237px;
  height: 280px;
  margin-right: 18px;
  border: 1px solid var(--greyLight20);
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
}

.d46Z6 {
  position: relative;
  height: 146px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
}

._2aF0Z {
  font-size: 14px;
  line-height: 15px;
  color: var(--white);
  margin-top: 15px;
}

._3sU8t {
  padding: 10px 18px;
  height: 66px;
  width: 100%;
  border-bottom: 1px solid var(--greyLight30);
}

.C-npo {
  font-size: 15px;
  line-height: 22px;
  color: var(--black);
  font-weight: 600;
  margin-bottom: 4px;
}

._15VPr {
  font-size: 13px;
  line-height: 22px;
  color: var(--greyDark);
  font-weight: 200;
}

._1ufQ8 {
  padding: 18px;
  width: 100%;
  height: 68px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
}

.LU3KG {
  background-color: var(--blueLight);
}

._3lWiz {
  background-color: var(--green);
}

._3sam8 {
  display: block;
  width: 100%;
  border: none;
  line-height: 1.2;
  font-size: 15px;
  resize: none;
  padding: 9px 12px;
  background-color: transparent;
  color: var(--black);
  font-weight: 600;
}

._2Ab8N {
  height: 100px;
  font-weight: 400 !important;
  color: var(--black);
  border: none;
  resize: none;
  border-radius: 4px;
}

._2smZY {
  margin-bottom: 10px;
}

._1dMgr {
  position: absolute;
  display: flex;
  top: 18px;
  right: 18px;
  align-items: center;
  z-index: calc(1 + var(--zindex-over-mask));
}

._1fP56 {
  margin-right: 18px;
  font-size: 15px;
  line-height: 16px;
  color: var(--greyDark);
}

.K87o3 {
  white-space: pre-line;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
}

._3w4HY {
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
}

._3E-fh {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

._3E-fh > span {
  width: 100%;
  height: 100px;
}

.NwhOB {
  cursor: pointer;
  font-size: 15px;
  line-height: 22px;
  color: var(--black);
  display: flex;
  width: 100%;
  height: 100%;
  vertical-align: top;

  /** reset defaults: */
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  -webkit-tap-highlight-color: transparent;
}

._25aFG {
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
}

._3nIRt {
  color: var(--red);
}

._2bbqn {
  color: var(--blue);
}

._2gD6v {
  position: relative;
}

._3_VKN {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 2;
}

.TZYls {
  color: var(--red);
  font-size: 12px;
  line-height: 16px;
  width: 179px;
  margin-left: 8px;
}

._3EHPU {
  z-index: var(--zindex-over-mask);
}

._1Lu2s {
  max-height: 110px;
  overflow-y: auto;
  white-space: pre-line;
}

.Y1t8b {
  z-index: var(--zindex-dropdown);
}

._1Lgat {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.oIOuM {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 600;
}

._1zAEv {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
}
