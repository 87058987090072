._10T2i {
  background-color: white;
  width: 520px;
}

._10T2i.DgHiJ {
  width: 100%;
}

._3xF6T {
  flex: auto;
}

._3xF6T.DgHiJ {
  flex-direction: column-reverse;
  margin-left: -24px;
  margin-right: -24px;
}

._3LzQb {
  margin-top: 16px;
  display: flex;
}

._3bVqp {
  background: var(--blueAlice) !important;
  border: 0 !important;
}

._3bVqp.DgHiJ {
  margin: 0 -16px;
}
