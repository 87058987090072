._2up7z {
  border-radius: 8px;
  border: 1px solid var(--c-base-light);
  padding: 16px 12px;
}

.HoE4M {
  width: 100%;
}

._3eJA8 {
  width: 344px;
  overflow: hidden;
}

._2Hwuj,
._3CfnB {
  width: 137px;
}

._1OYY1 {
  width: 36px;
  height: 36px;
}

._1rsry {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.KKanT {
  margin: auto;
}

.jTMKE {
  width: 84px;
  height: fit-content;
}
