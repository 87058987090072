._1pooj {
  display: flex;
  align-items: center;
  background-color: var(--white);
}

._3RKZB {
  height: 124px;
}

._3RKZB > g {
  display: flex;
  align-items: center;
  padding: 22px 0;
}

._3RKZB._2YQYw path {
  fill: var(--c-base-semilight);
}

.lY6-z {
  width: 734px;
}

.xjKyv {
  padding: 20px;
  width: 100%;
}

._2YQYw path {
  fill: var(--c-base-semilight);
}
