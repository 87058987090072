.KfthU {
  display: flex;
  background-color: var(--greyLight20);
  border-radius: 4px;
}

._2H3pt {
  align-self: center;
  margin-left: 7px;
}
