.ZwaAH {
  height: 100%;
  width: 4px;
  position: absolute;
  right: 42px;
  top: 0;
  background-color: var(--c-base-sub);
}

.j-jzh._2eyJ5 {
  background-color: var(--blue);
}

.j-jzh.NAOJS {
  background-color: #be5880;
}

.j-jzh._1Sho1 {
  background-color: var(--c-accent2-normal);
}
