._1xBym {
  width: 252px;
  margin-right: 22px;
}

._3RIXS {
  margin-top: 30px;
  margin-bottom: 6px;
}

._2zFsW {
  font-size: 64px;
  font-weight: 300;
  line-height: 64px;
}

._1kFx3 {
  font-size: 18px;
  line-height: 24px;
}

._33icV {
  width: 232px;
  margin-bottom: 16px;
  color: var(--greyDark);
}

._3BpM2 {
  margin-bottom: 18px;
}

._2LZLq {
  margin-bottom: 12px;
}
