._1wsG4 {
  padding: 16px 32px;
}

._2kSa6 {
  max-width: 50%;
}

._1pLbw {
  margin-bottom: 12px;
}

._3_GyI {
  position: absolute;
  bottom: -2px;
}

._2Bduz {
  color: var(--c-purple-semilight);
  position: relative;
}

._1pxV9 {
  margin-top: 16px;
  margin-bottom: 20px;
  padding-left: 20px;
}

._1pxV9 li {
  margin: 8px 0;
}

._1v6QW {
  color: var(--c-purple-light);
  font-size: 15px;
  margin-bottom: 24px;
  display: inline-block;
}

._2mSfK {
  color: var(--white);
}

._2Ze-A {
  border-radius: 4px;
  border: 1px solid var(--c-purple-semilight);
  color: var(--c-purple-semilight);
  padding: 3px 6px;
  padding-right: 12px;
  display: inline-flex;
  font-size: 15px;
  gap: 2px;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
          user-select: none;
}

@media (max-width: 720px) {
  ._2kSa6 {
    max-width: 100%;
  }

  ._1pLbw {
    margin-bottom: 0;
  }

  ._1wsG4 {
    padding: 16px;
  }
}
