.Hgxmw {
  height: calc(100vh - 204px);
  margin-bottom: -100px;
}

._1lh2Q {
  height: calc(100vh - 204px);
  flex: 1 1 auto;
}

._3-bQ8 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.vRqfv {
  margin-bottom: 16px;
}

._2p3vM {
  margin-bottom: 6px;
  font-size: 24px;
  line-height: 32px;
}

._1_DUY {
  margin-bottom: 16px;
}

._1QaNl {
  margin-top: 40px;
  margin-bottom: 10px;
}

._1QaNl,
._2jWBc {
  display: grid;
  grid-template-columns: 30% 12% 18% 20% 20%;
  width: 100%;
  padding-left: 18px;
  padding-right: 24px;
}

._2jWBc {
  height: 80px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--white);
}

._1QaNl .-ZY3l:last-child {
  position: relative;
  left: 23px;
}

._1crgf {
  position: relative;
}

._2tSMQ {
  position: relative;
  left: 2px;
}

.UoD2T {
  margin-bottom: 12px;
}

._2jWBc:hover:not(._2DSI4) {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.04);
}

._2DSI4 {
  cursor: default;
}

._2DSI4 .-ZY3l:first-child,
._2DSI4 ._21yZr {
  opacity: 0.5;
}

._2a2_c {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 48px;
  width: 48px;
  min-width: 48px;

  margin-right: 24px;

  border-radius: 8px;
  background: no-repeat center center;
  background-size: cover;
  background-color: #f3f2f2;
}

._38Qrl {
  background: #f3f2f2;
}

.-ZY3l {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.-ZY3l:first-child {
  position: relative;
}

._21yZr {
  width: 152px;
  text-align: right;
}

._1vcUY,
._3qr_M,
._3W9gu {
  position: relative;
  display: flex;
  justify-content: flex-start;
}

._3qr_M {
  justify-content: center;
}

._3W9gu {
  justify-content: flex-end;
}

._2Citv {
  display: flex;
  flex-direction: row;
}

._2Citv:hover ._3db0S {
  color: var(--black);
}

._3uAHL {
  width: calc(100% - 12px);
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

._3uAHL:hover span {
  color: var(--black) !important;
}

._1DWX7:hover span:first-child {
  color: var(--black) !important;
}

._1DWX7:hover span:last-child {
  color: var(--blueDark20) !important;
}

._2a2_c:focus {
  outline: none;
}

._3OO6L:focus,
._3uAHL:focus {
  outline: none;
}

._2NgfI:focus {
  outline: none;
}

html:not(.focus-not-visible) ._2NgfI:focus,
html:not(.focus-not-visible) ._2a2_c:focus,
html:not(.focus-not-visible) ._3OO6L:focus,
html:not(.focus-not-visible) ._3uAHL:focus {
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4);
}

.tw6qP {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

._3qlM- {
  color: #c6c6c6;
  margin-left: 8px;
  flex: 0 0 auto;
}

._3uAHL:hover
  ._3qlM-:not(._2NlSL) {
  color: var(--black);
}

._2NlSL {
  color: var(--blue);
}

._1xfi3 {
  transform: rotate(180deg);
}

._3K7_U {
  width: 36px;
  height: 36px;
  background-color: transparent;
}

._3K7_U:disabled {
  box-shadow: none;
}

._9j9rC {
  position: absolute;
  right: 0;
  min-width: 240px;
  background-color: var(--white);
  box-shadow: 0px 8px 32px rgba(32, 32, 32, 0.08),
    0px 0px 0px rgba(16, 16, 16, 0.04);
}

._2OY8c {
  min-width: 240px;
  background-color: var(--white);
  box-shadow: 0px 8px 32px rgba(32, 32, 32, 0.08),
    0px 0px 0px rgba(16, 16, 16, 0.04);
}

._9j9rC {
  top: 24px;
}

._1OmTd {
  padding: 60px 0;
  text-align: center;
}

._3vD7f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 14px;
}

._3HLys {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 2px;
  line-height: 22px;
}

._1lflV {
  margin-top: 2px;
  font-size: 13px;
  line-height: 16px;
  white-space: normal;
  color: var(--greyDark);
}

._3vD7f:hover ._1lflV,
.ZfRSj ._1lflV {
  color: var(--white);
}

._3vD7f:hover .XyZdN,
.ZfRSj .XyZdN {
  color: var(--white);
  border-bottom: 1px dotted rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

._3Xpzs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

._3x0i3 {
  display: flex;
  align-items: center;
}

._3sx7F,
._3zn9o {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--black);
}

._3IIVC {
  color: var(--greyDark);
}

._2i2ua {
  position: absolute;
  left: 72px;
  flex: 1;
  display: flex;
  width: 400px;
  z-index: var(--zindex-over-content-controls);
}

@media only screen and (max-width: 1620px) {
  ._2i2ua {
    width: 240px;
  }
}

._3zn9o {
  padding: 6px 12px;
  margin-right: 16px;
  border-radius: 8px;
  border: 1px solid rgb(174, 209, 245);
}

._2NgfI {
  color: var(--greyDark);
  text-decoration-color: var(--greyDark);
}
._2NgfI:hover,
._4MphM:hover {
  color: var(--black);
  text-decoration-color: var(--black);
}

._34Ynu::after {
  position: absolute;
}

._37OUD {
  color: var(--greyDark);
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
}

._2YpLG {
  width: 100%;
}

._12Z64 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

._12Z64 svg {
  margin-right: 8px;
}

._19BDF {
  height: 22px;
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.mOxI7 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

._8f0LJ {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--greyLight20);
  animation: _1CXZB 1000ms infinite;
}

.mOxI7 > ._8f0LJ {
  margin-bottom: 12px;
}

.mOxI7 > ._8f0LJ ~ ._8f0LJ {
  margin-bottom: 0px;
}

._ywm_ {
  text-wrap: nowrap;
}

@keyframes _1CXZB {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
