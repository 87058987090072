._3TGP- {
  position: relative;
}

._2lHZw {
  position: absolute;
  border: 2px solid var(--cobaltSub);
  width: var(--statusSize);
  height: var(--statusSize);
  top: calc(var(--iconSize) - (var(--statusSize) / 2) - 1px);
  left: calc(var(--iconSize) - (var(--statusSize) / 2) - 1px);

  z-index: var(--zindex-bot-picture-status);
}

._3TqSR {
  --statusSize: 13px;
  --iconSize: 24px;
}

._29Rhk {
  --statusSize: 10px;
  --iconSize: 16px;
}

._332mF {
  --statusSize: 12px;
  --iconSize: 44px;
}

._2XnE2 {
  z-index: var(--zindex-bot-picture-pro);
  position: absolute;
  bottom: 0;
}
