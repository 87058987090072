.LP3Oe {
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.LP3Oe:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

._3dOCM.LP3Oe:hover {
  background-color: unset;
}
