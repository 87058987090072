._8NOdr {
  --side-extend-width: calc(4 * var(--unit));

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: calc(100% + 2 * var(--side-extend-width));
  margin-left: calc(-1 * var(--side-extend-width));
  margin-bottom: var(--side-extend-width);
  padding: calc(6 * var(--unit)) calc(4 * var(--unit));

  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
}

._2wBsr {
  margin-bottom: calc(4 * var(--unit));
}

._3kcjN {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 30px;
  background-color: var(--greenLight);
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  border-radius: calc(8 * var(--unit));
}
._2VWgr {
  position: relative;
  top: 1px;
  right: 6px;
  width: 16px;
}

._1D8ey {
  position: absolute;
  right: 8px;
  top: 8px;
}
