.nD9ro {
  margin-bottom: 20px;
}

.NLc0i {
  padding: 37px;
}

.jb2vF {
  flex: none;
}

.ljBRX {
  height: 300px;
}

._1UdTV:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

._2qLa8 {
  align-self: flex-end;
}

._2bb3W {
  width: 100%;
}

.AcHt6 {
  gap: 16px;
}

@media (max-width: 700px) {
  .AcHt6 {
    flex-direction: column;
  }
  .nD9ro {
    margin-bottom: 32px;
  }
}
