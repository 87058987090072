.LdtAY {
  transform: translateX(-100%);
}

.rXA4n {
  transform: translateX(0%);
  transition: 0.25s ease-in-out;
}

._3OE3h {
  transform: translateX(0%);
}

.efU3J {
  transform: translateX(-100%);
  transition: 0.25s ease-in-out;
}

._15abP {
  transform: translateX(+100%);
}

._3qTJY {
  position: absolute;
  height: 100%;
  width: 100%;
  display: inherit;
}

._2BLFO {
  transform: translateX(0%);
  transition: 0.25s ease-in-out;
}

._3maQc {
  transform: translateX(0%);
}

._3c0qF {
  transform: translateX(100%);
  transition: 0.25s ease-in-out;
}

._2miEA {
  position: relative;
  display: inherit;
  width: inherit;
}
