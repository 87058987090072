._1EMmk {
  transition: max-height 300ms ease-in-out;
}

._2Lkb6 {
  height: 0;
  overflow: hidden;
}

._1Rc4G {
  overflow: visible;
}

.AYlJ_ {
  overflow: hidden;
}

._1g6fL {
  overflow: hidden;
}