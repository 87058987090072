._2ha_v {
  padding: 0 12px;
  height: 100%;
}

._8Vprp {
  margin-top: -2px !important;
  margin-right: 12px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}
