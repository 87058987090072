._1AYLK {
  position: relative;
  width: 100%;
  height: 4px;
  overflow: hidden;
}

._1cVLk {
  height: 4px;
  background-color: var(--blue);
  transition: width 20s cubic-bezier(0.15, 1, 0.4, 1);
}

@keyframes _1bW71 {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
