._2D4nX {
  padding: 4px;
  background-color: var(--c-base-extralight);
  border-radius: 4px;
  gap: 4px;
}

._1MT_v {
  color: var(--c-accent1-normal);
}

._3nnR5 {
  flex-grow: 1;
  flex-basis: 100%;
}
