._1k06h {
  --old-page-padding: 64px; /* fix old padding */
  height: calc(100% + var(--old-page-padding));
  margin: calc(-1 * var(--old-page-padding)) auto 0;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--greyLight20);
}

._207MF {
  font-size: 23px;
  line-height: 26px;
  text-align: center;
  min-height: 90%;
  margin-top: 10px;
}

@media (max-width: 790px) {
  ._1k06h {
    --old-page-padding: 58px;
    height: calc(100% + var(--old-page-padding));
    margin: calc(-1 * var(--old-page-padding)) auto 0;
  }
}
