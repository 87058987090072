._3lS5E {
  width: 504px;
  background-color: var(--greyLight30);
}

._249VK {
  top: 25px;
  right: 32px;
}

._3dF3t {
  display: block;
  margin: 0 0 20px;
  width: 424px;
  height: 176px;
}

._1DceL {
  font-size: 15px;
  line-height: 1.33;
  color: var(--greyDark);
  margin: 0 0 8px;
}
