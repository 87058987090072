._2a56q {
  max-width: 500px;
  white-space: nowrap;
}

._3KUwX {
  width: 100%;
  padding-left: 12px;
  font-weight: 400;
  border: 0;
}

._3KUwX:disabled {
  border: 0;
}

.Yg8ng {
  --placeholder-color: #a2a2a2;
  color: var(--placeholder-color) !important;
}

._2Nt_h {
  margin-top: -16px;
}
