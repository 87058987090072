.NZaBb {
  background-color: var(--c-base-dark);
  background-position: right top;
  background-repeat: no-repeat;
  border-radius: 4px;
  padding: 24px;
}

.xem05 {
  position: relative;
  background: linear-gradient(240deg, #0e68d9 0%, #002876 100%);
  overflow: hidden;
  border-radius: 4px;
  padding: 24px;
}

.ySlMW {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 100%;
}

.LAi0P {
  position: relative;
}

._2vP3O {
  position: relative;
  margin-right: auto;
}

@media (max-width: 720px) {
  .xem05 {
    padding: 0;
  }

  ._2vP3O {
    margin: 0 24px 24px;
  }

  .ySlMW {
    position: static;
    max-height: 400px;
  }

  .LAi0P {
    position: relative;
    padding: 0 24px;
  }
}
