._22XdX {
  position: absolute;
  top: 16px;
  left: 16px;
  right: 20px;
  z-index: var(--zindex-callout);
}

._20XmY {
  padding: 8px 16px;
}
