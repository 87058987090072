.E00dr {
  position: relative;
  width: 100%;
}

._3-Mtg{
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: -12px;
  width: 24px;
  height: 24px;
  background-color: var(--greyLight30);
  border: 1px solid var(--grey);
  border-radius: 50%;
  z-index: var(--zindex-over-content-controls);
}

.E00dr:hover ._3-Mtg {
  display: flex;
}

.E00dr:after{
  content: ' ';
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 48px;
}

._2zkPX {
  width: 50%;
}
