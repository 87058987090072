._2X76e {
  width: 100%;
  max-width: 1040px;
  overflow-x: auto;
  border-radius: 12px;
}

._2rBeH {
  width: 100%;
}

._2rBeH tr:first-child th:first-child {
  z-index: 2;
}

._2rBeH th:first-child,
._2rBeH td:first-child {
  width: 152px;
  position: sticky;
  top: 0;
  left: 0;
}

._2rBeH th:first-child {
  background-color: var(--cobalt);
}

._2rBeH td:first-child {
  background-color: var(--white);
}

._2rBeH th:not(:first-child),
._2rBeH td:not(:first-child) {
  min-width: 64px;
}

._2rBeH th:last-child,
._2rBeH td:last-child {
  padding-right: 12px;
}

._2rBeH th,
._2rBeH td {
  padding-left: 12px;
}

._2rBeH th {
  padding-top: 12px;
  padding-bottom: 12px;
}

._2rBeH td {
  padding-top: 16px;
  padding-bottom: 16px;
}

._2rBeH tr {
  position: relative;
}

._2rBeH tbody tr:not(:first-child)::after {
  content: '';
  display: block;
  height: 1px;
  overflow: hidden;
  background: var(--greyLight20);
  position: absolute;
  left: 12px;
  right: 12px;
}

._2rBeH tr th:first-child,
._2rBeH tr td:first-child {
  text-align: start;
}

._2rBeH tr:last-child td {
  padding-bottom: 20px;
}

._2rBeH tr th:not(:first-child),
._2rBeH tr td:not(:first-child) {
  text-align: end;
}

._1WnMM {
  background-color: var(--cobalt);
}

._2_AGC {
  background-color: var(--white);
}

._35Vyt {
  width: 100%;
}

._3ljVZ {
  text-align: end;
}

._2PIx7 {
  background-color: var(--white);
  width: 110px;
}

._23KyS {
  width: 122px;
  height: 36px;
}

._3z8C0 {
  height: 16px;
  border-radius: 4px;
}
