._3V81i {
  position: relative;
}

.C-pSH {
  position: absolute;
  z-index: var(--zindex-popover);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

._1Dq-5 {
  display: block;
  width: calc(100% + 28px);
  border-radius: 4px;
  height: 588px;
  margin: 0 -14px;
  border: 1px solid var(--greyLight20);
}
