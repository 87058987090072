.zYbf2 {
  position: relative;
  cursor: pointer;
}

._1Z2A6 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
  opacity: 0.01;
}

._2f3aE {
  transform: scale(2) translate(10%, 14%);
}

._10T1i {
  line-height: normal;
  font-size: initial;
}

._37F2t {
  transform: scale(0.001, 0.001);
  pointer-events: none;
}
