._19A-2 {
  display: flex;
  color: #b7b7b7;
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  flex-grow: 2;
  justify-content: flex-end;
}

._19A-2.tMPT9 {
  color: var(--white);
}

.iodwV {
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 8px 0 8px;
}

._2TDg2 {
  margin: 0 8px 0 0;
}

._1yZHd {
  margin: 0 3px -1px 0;
}
