._2CDP6 {
  position: relative;
  width: 0;
  height: 0;
}

._1WRh3 {
  position: absolute;
  z-index: 10;
}

.D7pFM > div {
  height: 100%;
  width: 100%;
}

.D7pFM iframe {
  border-radius: 0px 0px 8px 8px;
}

._3Sd-y {
  background: white;
  border: 1px solid;

  height: 36px;
  width: 100%;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  border: 1px solid rgba(16, 16, 16, 0.08);
  border-radius: 8px 8px 0px 0px;
}

.DKH-u {
  height: 28px;
  width: 28px;
  margin-right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

._34-ET {
  cursor: grab;
}

._3-e-s {
  height: 100%;
  width: 100%;
}

._2bfGa {
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}

._70tvr {
  position: absolute;
  cursor: nesw-resize;
  width: 24px;
  height: 24px;
  left: -12px;
  bottom: -12px;
  z-index: 2;
}
