.Jm8mQ {
  display: inline;
}

._26Wby {
  color: var(--blue);
}

._2GP01 {
  height: 24px;
  background: var(--blue);
  border-radius: 3px;
  font-size: 15px;
  line-height: 18px;
  color: var(--white);
  display: inline-flex;
  align-items: center;
  /*min-width: 42px;*/
  max-width: 226px;
  margin: -2px 1px 1px;
}

.zmUeH {
  z-index: var(--zindex-modal);
  margin: 8px 0;
}

._39Civ {
  color: transparent;
  flex: none;
}

._39Civ._1QqGc {
  margin-left: -1px;
}

._39Civ._1mO6L {
  margin-right: -1px;
}

._3PnCQ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._1OgKR {
  color: #dadada;
  margin: -2px 0;
}

.Dv5MS {
  max-width: 226px;
}
