._3j19- {
  font-size: 15px;
  line-height: 20px;
  color: var(--greyDark);
}

._3j19-:not(:last-child) {
  margin: 0 0 24px;
}

._3j19- a {
  color: var(--blue);
  text-decoration: none;
}

._3j19- a:hover {
  text-decoration: underline;
}

._1s3wE {
  display: flex;
}

._1s3wE > * {
  flex-grow: 1;
}

._1s3wE > *:not(:last-child) {
  margin: 0 24px 0 0;
}
