._1eFQg {
  display: flex;
  height: 32px;
  padding: 0 12px;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
}

._2FbpT {
  color: var(--white);
  background-color: var(--blue);
}

._2FbpT span {
  color: var(--white);
}
