._3W_XI {
  background-color: var(--greyLight20);
  border-radius: 4px;
  padding-top: 8px;
}

._2ZZCc {
  display: block;
  width: 231px;
  height: 141px;
}
