._29jPv {
  display: flex;
  align-items: center;
  position: relative;
  outline: none;
  top: 1px;
  margin-right: 2px;
}

._2-pcx {
  margin-bottom: 24px;
}

._1vZGe {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 118px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 40px;
  pointer-events: none;
}

._1G-_s {
  pointer-events: auto;
}
