._3mtB8 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

._3nEfQ {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 60px;
  background-color: var(--greyLight30);
}

._1kwip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

._18mpk {
  position: relative;
  animation: gJl56 1500ms ease-in-out infinite;
}

@keyframes gJl56 {
  0% {
    transform: scale(0.926, 0.926);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.926, 0.926);
  }
}

._3-7lC {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

._14HLH {
  animation: _3x76p 20s ease-in-out infinite;
}

._36_kh {
  animation: _3m2b5 20s ease-in-out infinite;
}

._2_QA3 {
  animation: _1Vosi 20s ease-in-out infinite;
}

._17RTG {
  animation: _31-1z 20s ease-in-out infinite;
}

._1Kdz4 {
  animation: _1Z5-a 20s ease-in-out infinite;
}

@keyframes _3x76p {
  from,
  21%,
  to {
    opacity: 0;
  }
  1%,
  19% {
    opacity: 1;
  }
}

@keyframes _3m2b5 {
  from,
  19%,
  41%,
  to {
    opacity: 0;
  }
  21%,
  39% {
    opacity: 1;
  }
}

@keyframes _1Vosi {
  from,
  39%,
  61%,
  to {
    opacity: 0;
  }
  41%,
  59% {
    opacity: 1;
  }
}

@keyframes _31-1z {
  from,
  59%,
  81%,
  to {
    opacity: 0;
  }
  61%,
  79% {
    opacity: 1;
  }
}

@keyframes _1Z5-a {
  from,
  79%,
  to {
    opacity: 0;
  }
  81%,
  99% {
    opacity: 1;
  }
}
