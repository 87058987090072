.wlk_x {
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
}

._3RYsQ {
  overflow: hidden;
}

.ORCG4 {
  overflow: hidden;
}

._1F_1e {
  margin-right: 8px;
}
