._14B8x {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: var(--zindex-overlay);
}

._1_OoG {
  position: absolute;
  overflow: hidden;
  width: 200px;
  height: 40px;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0.01;
}

._1_OoG._16b_o {
  transform: scale(0.01, 0.01);
  pointer-events: none;
}

.GxzCy {
  transform: scale(2, 2) translate(10%, 14%);
}
