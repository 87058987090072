._2SQjc {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 16px;
  max-width: 370px;
  gap: 8px;
}

._2Zn_w {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin-right: 32px;
  margin-top: 6px;
}

._2Zn_w:hover {
  cursor: pointer;
}

.MtFwG path {
  fill: var(--grey);
}
