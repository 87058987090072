._3n4Io {
  width: 278px;
  border-radius: 18px;
  background-color: var(--blue);
  padding: 16px;
}

._1-32b {
  width: 68px;
  height: 68px;
  border-radius: 4px;
  overflow: hidden;
  flex: none;
}

._lVBP {
  object-fit: cover;
  display: block;
  width: 68px;
  height: 68px;
}
