._3KpGF {
  width: 100%;
}

._3r7A8 {
  width: 100%;
  height: 96px;
}

._3hCef:hover {
  background: var(--blueLight30);
}

._3hCef {
  width: 55px;
  height: 96px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._3txwW {
  margin-top: 24px;
}

._1P4sQ {
  width: 76px;
  height: 76px;
}

._2-Np_ {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  gap: 20px;
  margin-right: 16px;
}

@media (max-width: 720px) {
  ._2-Np_ {
    margin-right: 0px;
  }

  ._1iSGv {
    flex-direction: column;
  }
}
