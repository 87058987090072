@media (max-width: 720px) {
  ._1rrQV {
    margin-bottom: 12px;
  }

  .QOfwd {
    flex-direction: column;
    align-items: flex-start;
  }
}
