._1yhlQ {
  height: 250px;
  object-fit: contain;
  overflow: hidden;
  position: relative;
  outline: none;
}

._3NeCa {
  border: 1px solid var(--grey);
  width: 150px;
  padding: 16px 24px;
}
