.RMitT {
  flex-shrink: 0;
  background-color: var(--white);
  width: 375px;
  border-right: 1px solid var(--greyLight20);
  position: relative;
  transition: width ease-in-out 0.2s;
}

._2RPZh {
  width: 100%;
  flex-shrink: 1;
  border-right: none;
  background-color: var(--white);
}

.o9RUa {
  width: fit-content;
}

._3gLxi {
  overflow: hidden;
  width: 24px;
}

._2mNuj {
  padding-right: 24px;
}
