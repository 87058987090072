._2CZ9r {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

._2a4c_ {
  display: flex;
}

._1smhu {
  width: 456px;
}
