._3Azrj {
  background-color: var(--c-purple-normal);
  flex-grow: 1;
  display: flex;
  max-width: 100%;
}

._20gEP {
  display: flex;
  height: 100%;
  width: 100%;
}

._3iW5k {
  flex-basis: 50%;
  min-width: 50%;
  color: var(--white);
}

._3iW5k._1AXRo {
  flex-basis: 100%;
}

._2bwTV {
  flex-basis: 50%;
  min-width: 50%;
  background-color: var(--white);
  border-radius: 8px 0 0 8px;
  padding: 24px 20px;
}

@media (max-width: 720px) {
  ._20gEP {
    flex-direction: column;
    height: auto;
    max-width: 100%;
    overflow-x: hidden;
  }

  ._2bwTV {
    flex-basis: 50%;
    border-radius: 8px 8px 0 0;
    padding: 24px 16px;
  }
}
