._11zlr {
  flex: none;
  height: 44px;
  width: 44px;
  border-radius: 4px;
  background-color: var(--greyLight20);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PIyfS {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
