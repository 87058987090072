._2vjao {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: var(--c-accent1-normal);
  position: relative;
}

._2vjao::before {
  content: ' ';
  position: absolute;
  z-index: -1;
  left: -4px;
  top: -4px;
  width: 32px;
  height: 32px;
  background-color: var(--c-accent1-semilight);
  border-radius: 50%;
}

._2vjao._1OpOq::before {
  animation: _3ypyd 1s ease-out 0s infinite normal forwards;
}

._2vjao._1OpOq::after {
  content: ' ';
  position: absolute;
  width: 8px;
  height: 8px;
  left: 8px;
  top: 8px;
  background-color: var(--white);
  border-radius: 1px;
}

._2vjao._18IVO::after {
  content: ' ';
  position: absolute;
  left: 8.5px;
  top: 8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.5px 0 4.5px 7.8px;
  border-color: transparent transparent transparent #ffffff;
  -webkit-transform: rotate(360deg);
}

._2vjao._18IVO::before {
  opacity: 0;
}

._2hk0H {
  width: 32px;
  margin-right: 12px !important;
}

@keyframes _3ypyd {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
