.HcL8e {
  width: 100% !important;
  padding: 6px;
  width: 36px;
  height: 36px;
}

.HcL8e:hover {
  background: var(--greyLight20);
  border-radius: 4px;
}
