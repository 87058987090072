._2ANwN {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #767676;
}

.CjhRx {
  height: 484px;
  width: 600px;
}

._2Qwdu {
  height: 400px;
}
