._43dhx {
  max-width: 956px;
  width: 100%;
  border-radius: 8px;
}

.mwE2t {
  background: var(--white) !important;
}

._3WrXR {
  background: linear-gradient(
    238.9deg,
    #6358de 1.39%,
    #208ef0 99.06%
  ) !important;
}

._1l9ia {
  color: var(--white) !important;
}

._1WcPp {
  position: absolute;
  top: 12px;
  right: 12px;
}

._24rhV {
  width: 100%;
}

._2FUFv {
  width: auto;
}

.TooK5 {
  margin: 0 0 8px;
}

.mze5H {
  margin: 0 24px 0 16px;
}
