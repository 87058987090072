._3pplN {
  pointer-events: all;
  width: 300px;
  position: absolute;
  transition: left 300ms, top 300ms, opacity 300ms;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--greyLight10);
}

._3pplN._2I9Ns {
  transform: translate(-50%, -50%);
}

.AiR1b {
  transition: opacity 300ms;
}

._3j3_I {
  background-color: var(--c-base-light);
  display: flex;
  justify-content: flex-end;
}

._2LgC1 {
  margin: 5px 8px;
  cursor: grab;
  touch-action: none;
}

._2LgC1:active {
  cursor: grabbing;
}

._3-FPo {
  border-radius: 0;
  background-color: var(--white) !important;
}
