._2X33n {
  display: flex;
  flex-direction: column;
  padding: 1px; /* Чтобы не съедался бордер у кнопок */
  margin: 0;
  height: 420px;
  overflow-y: auto;
  gap: 20px;
}

.vr0q3 {
  list-style-type: none;
}

._3zxKN {
  height: 48px;
  margin-left: 20px;
}

._33J29 {
  display: flex;
  gap: 20px;
}

._2IXbu {
  max-width: 490px;
}
