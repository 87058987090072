._3Byx3 {
  font-size: 15px;
  line-height: 20px;
  margin: 8px 0;
}

._1NLzd {
  margin-top: 40px;
  text-align: right;
}

._1NLzd button:first-child {
  margin-right: 16px;
}
