.zhfdn {
  width: 100%;
  text-align: left;
}

@media (max-width: 700px) {
  .zhfdn {
    margin-bottom: 4px;
  }
}
