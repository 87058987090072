.AxfAX {
  background-color: var(--white);
  padding: 72px 40px 32px;
  position: relative;
  width: 100%;
  border-radius: 8px 8px 0 0;
}

._1qzsx {
  width: 80px;
  height: 80px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -24px;
  left: calc(50% - 40px);
  background-color: var(--white);
  border-radius: 50%;
}

._2qR4w {
  width: 64px;
  height: 64px;
}

._1JYU0 {
  padding: 24px 40px 32px;
  background-color: var(--greyLight30);
  width: 100%;
  border-radius: 0 0 8px 8px;
}

._2rZJs {
  width: 100%;
}

._3WTpt {
  width: 100%;
}
