.zBrfS {
  border-radius: 4px;
  width: 100%;
  height: 64px;
}

._2ThC5 {
  padding: 12px;
  width: 182px;
}

._2rZmz ._3okBH {
  color: var(--greyDark) !important;
}

._3WpVD ._3okBH {
  color: var(--c-accent1-semilight) !important;
}

._2rZmz .zBrfS {
  background-color: var(--c-base-light) !important;
}

._3WpVD .zBrfS {
  background-color: var(--c-accent1-secondary) !important;
}

._2rZmz ._2rKrf {
  color: var(--c-base-normal) !important;
}

._3WpVD ._2rKrf {
  color: var(--white) !important;
}

._2rZmz ._1NBb- {
  color: var(--greyDark) !important;
}

._3WpVD ._1NBb- {
  color: var(--white) !important;
}
