.zCIC9 {
  width: 33%;
}

._2sQAR {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 8px;
}

._39I1Z {
  font-size: 16px;
  height: 40px;
  width: 315px;
}

._2A4Yu {
  height: 64px;
}

._2iOHj {
  height: 104px;
  margin-top: 28px;
  margin-bottom: 54px;
}

._25TkQ {
  color: #a9a9a9;
  font-size: 16px;
  height: 20px;
}

.lwuiz {
  font-size: 64px;
  font-weight: 300;
  line-height: 1;
}

._23zzr {
  font-size: 18px;
  line-height: 1.33;
}

._2uTdI {
  margin-top: 14px;
  height: 20px;
  color: var(--greyDark);
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

._3Md11 {
  margin-left: 8px;
}

._2uIaZ {
  color: var(--greyDark);
  text-decoration: underline;
}

._2uIaZ:hover {
  text-decoration: none;
}

._367EO {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

._1egcd {
  animation: _3vRKY 1s infinite
    cubic-bezier(0.71, 0.01, 0.31, 0.99);
}

.swJd4 {
  height: 36px;
}

._1_SwW {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  margin-left: 8px;
}

@keyframes _3vRKY {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}
