._2q4Fs {
  flex: none;
  margin: 0 0 -3px 0;
}

._1Io2q {
  z-index: var(--zindex-popover);
  min-width: unset !important;
  padding: 4px 0 0;
  width: 232px;
}

._3ztJj {
  color: var(--blue);
  display: inline-block;
}

._3ztJj._15EO9 {
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px dotted rgba(255, 255, 255, 0.8);
  margin-bottom: -1px;
}

._1pkRo {
  height: 18px;
  border-radius: 4px;
  background-color: var(--green);
  padding: 0 6px;
  display: inline-flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  color: var(--white);
  margin: 0 0 0 6px;
}

._1pkRo._15EO9 {
  background-color: #fefdfd;
  color: var(--blue) !important;
}
