._1JS4L {
  flex: 1 1 auto;
}

._1PtSX {
  width: 140px;
  flex: none;
}

._3QrsI {
  opacity: 0;
}

._22z0U {
  opacity: 1;
}
