.IEhfJ {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 12px;
  padding: 17px 16px 19px 22px;
}

._23KC7:not(:first-of-type) {
  margin-top: 16px;
}
