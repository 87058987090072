@font-face {
  font-family: 'Ppneuemachina';
  src: url(/fonts/1Vems.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ppneuemachina';
  src: url(/fonts/1Vems.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ppneuemachina';
  src: url(/fonts/1Vems.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Ppneuemachina';
  src: url(/fonts/1Vems.otf) format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

:root {
  --font: 'Inter', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', Arial,
    sans-serif;
  --accent: 'Ppneuemachina', sans-serif;
}
