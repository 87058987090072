:root {
  --blueLight: #88beee;
  --blue: #208ef0;
  --blueLight20: #e8ecf1;
  --blueLight30: #f4f6fa;
  --blueDark10: #2086e2;
  --blueDark20: #1d7acc;
  --blueAlice: #f3f8fd;
  --cobalt: #152442;
  --darkNavy: #1a212d;
  --greenLight: #94d4be;
  --green: #45c195;
  --greenDark10: #3fb289;
  --greenDark20: #3aa27d;
  --redLight: #e1a298;
  --red: #de614d;
  --redDark10: #d69b91;
  --redDark20: #c18b83;
  --yellowLight: #f9ffaf;
  --yellow: #f6e049;
  --yellowDark: #aac600;
  --yellowDark10: #ddcf7d;
  --yellowDark20: #cabd72;
  --yellow-text: #2b3200;
  --white: #ffffff;
  --greyLight30: #f9f8f8;
  --greyLight20: #f3f2f2;
  --greyLight10: #e9e8e8;
  --grey: #c6c6c6;
  --greyDark: #747474;
  --black: #000000;
  --pistachio: #c1de89;
  --mauve: #e2bcff;
  --violet: #6358de;
  --azure: #22aeda;
  --fuchsia: #ce426d;
  --antiqueWhite: #ffebd6;
  --orange: #fd9426;
  --alloyOrange: #ad6418;
  --accent3Orange: #da720b;
  --accent3OrangeSecondary: #ffb74a;
  --accent2Secondary: #1ecc94;
  --overlay: rgba(18, 18, 18, 0.8);
  --mobile-dialog-background: var(--greyLight30);
  --instagram-gradient: linear-gradient(63.69deg, #e8864a 8.93%, #a039a0 74.96%);
  --chatfuel-gradient: linear-gradient(180deg, #208ef0 0%, #be60ef 100%);
  --meta-gradient: linear-gradient(238.9deg, #6358de 1.39%, #208ef0 99.06%);
  --youtube-gradient: linear-gradient(74.57deg, #be60ef 0%, #ff6966 99.67%);
  --churn-survey-gradient: linear-gradient(180deg, #208ef0 0%, #6358de 100%);
  /* New ui kit colors https://www.figma.com/file/P0qAC86lncns2dO8jOgiFG/Design-Library-2.1?node-id=6%3A24 */
  /* Cobalt */
  --cobaltSub: #2c3a55;
  --cobaltSecondary: #5b677d;
  --cobaltTertiary: #8a92a0;
  --cobaltSemilight: #b9bdc6;
  --cobaltExtralight: #f5f6f7;
  /* Blue */
  --blueSecondary: #5cb2ff;
  /* Purple */
  /* Magenta */
  /* Green */
  /* Yellow */
  /* Oragne */
  --orangeNormal: #fd9426;
  /* Red */
  --redNormal: #fa5740;
  --redSecondary: #ff806e;
  --redSemilight: #ffc4bc;
  /* Gradients */
}
/* design library 2.1 */
:root {
  --c-base-white: #fff;
  --c-base-dark: #0c172d;
  --c-base-extralight: #f5f6f7;
  --c-base-light: #eff0f2;
  --c-base-secondary: #5b677d;
  --c-base-tertiary: #8a92a0;
  --c-base-semilight: #b9bdc6;
  --c-base-normal: #152442;
  --c-base-sub: #2c3a55;
  --c-accent1-semilight: #c4e2fe;
  --c-accent1-normal: #208ef0;
  --c-accent1-text: #021889;
  --c-accent1-secondary: #5cb2ff;
  --c-accent1-light: #e0f0ff;
  --c-accent1-dark: #0654c6;
  --c-accent1-tertiary: #95cdff;
  --c-accent1-extralight: #f4f6fa;
  --c-accent2-tertiary: #54e5b7;
  --c-accent2-light: #d0fff0;
  --c-accent2-normal: #0ebc83;
  --c-accent2-text: #00422a;
  --c-accent2-secondary: #1ecc94;
  --c-accent3-light: #ffebd6;
  --c-accent3-normal: #fd9426;
  --c-accent3-dark: #da720b;
  --c-accent3-text: #541e00;
  --c-accent4-light: #fee6e2;
  --c-accent4-semilight: #ffc4bc;
  --c-accent4-normal: #fa5740;
  --c-accent4-tertiary: #ffa895;
  --c-accent4-secondary: #ff806e;
  --c-accent4-dark: #f03c23;
  --c-accent4-text: #580b00;
  --c-magenta-text: #46026a;
  --c-magenta-normal: #be60ef;
  --c-magenta-secondary: #d585ff;
  --c-purple-normal: #6558f6;
  --c-purple-light: #e7e4ff;
  --c-purple-text: #290885;
  --c-purple-semilight: #cecaff;
  --c-accent2-dark: #028f61;
}
