._3l3Tt {
  position: relative;
  width: 302px;
  background: rgba(255, 254, 254, 0.96);
  border: 1px solid rgba(16, 16, 16, 0.1);
  box-shadow: 0 8px 32px rgba(32, 32, 32, 0.08), 0 0 0 rgba(16, 16, 16, 0.04);
  border-radius: 4px;
  padding: 20px 16px;
  z-index: var(--zindex-popover);
  cursor: default !important;
}

.iqsfk {
  width: 100%;
  margin-top: -2px;
  pointer-events: none !important;
}

._3FL9W {
  pointer-events: none;
  position: absolute;
  width: 6px;
  height: 12px;
}

._3FL9W:after {
  content: '';
  display: block;
  box-shadow: 0 8px 32px rgba(32, 32, 32, 0.08), 0 0 0 rgba(16, 16, 16, 0.04);
}

._89ih3 {
  right: -6px;
}

._89ih3:after {
  width: 0;
  border-style: solid;
  border-width: 5.5px 0 5.5px 6px;
  border-color: transparent transparent transparent rgba(255, 254, 254, 0.96);
}

._3dCmG {
  left: -6px;
}

._3dCmG:after {
  width: 0;
  border-style: solid;
  border-width: 5.5px 6px 5.5px 0;
  border-color: transparent rgba(255, 254, 254, 0.96) transparent transparent;
}

.oDqXB {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
