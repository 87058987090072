.FMjg7 {
  display: inline-flex;
  gap: 1px;
}

._2N0Xs {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background: var(--c-accent1-semilight);
}

._1UaAt {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}

._2N0Xs._3j8O- {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background: var(--c-accent1-semilight);
}

._2FxWe {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background: var(--c-accent1-semilight);
}

._2FxWe:hover,
._2FxWe:active {
  background: var(--c-accent1-tertiary) !important;
}

._2N0Xs.x70Hn {
  background: var(--c-base-light);
}
