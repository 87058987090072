.VVQRE {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: calc(var(--unit) * 4);
  background-color: #f1f1f1;
  border-radius: 8px;
}

.tvbXi {
  display: inline-flex;
  max-width: 210px;
}

._2zh2Q {
  margin-right: 16px;
}

._3Bj2o,
._1mnKm {
  background-color: var(--grey);
}

._1mnKm {
  width: 150px;
}

._13xdy {
  width: 100%;
  margin-right: 16px;
}

.rrJHt {
  display: inline-block;
  background-color: var(--grey);
  width: 130px;
}

.z0zHq::placeholder {
  color: var(--black);
}

._2Y1u2 {
  position: relative;
  top: 3px;
  display: inline-block;
  margin: 0 10px;
}

.w6_dC {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

._3nIWO path {
  fill: var(--black);
}

._23-mY path {
  fill: var(--blue);
}

._2l7YP path {
  fill: var(--red);
}

._2ksFr path {
  fill: var(--grey);
}

._2mFkv {
  display: grid;
  grid-auto-flow: column;
  flex: 1;
}

.cu0q4 {
  width: 15px;
  height: 15px;
  position: relative;
  top: -1px;
}

._1liF1 {
  width: 126.5px;
}

._1fRVE:disabled {
  background-color: var(--greyLight30);
}

._1mAQr {
  color: var(--white);
}

._1QqA- {
  height: 16px;
  width: 16px;
  position: relative;
  bottom: -2px;
}
