._1jrYu {
  min-width: 22px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  color: var(--green);
  border-radius: 16px;
  font-size: 11px;
  pointer-events: none;
}

._1jrYu._1m2ML {
  color: #f06040;
}
