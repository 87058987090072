._1GpVm {
  height: 24px;
  width: 24px;
  border-radius: 4px;
}

._1GpVm:hover {
  background: var(--c-purple-semilight);
}

._1GpVm:active,
._1GpVm:focus {
  outline: 1px solid var(--c-purple-semilight);
  outline-offset: 2px;
}
