._3dwMw {
  top: -22px;
  height: 60px;
}

._2voV2 {
  padding: 0 12px;
  height: 100%;
}

._3c-w2,
._1C2C1,
._2uoTR {
  margin-top: -2px !important;
  margin-right: 12px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

._3c-w2 {
  margin-left: auto !important;
}

._365Bd {
  flex: auto;
  overflow: hidden;
}
