._3Y0GW {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: var(--white);
  overflow-x: auto;
}

.-XmiS {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) 2fr 280px;
}

._1pSMQ {
  flex: 1;
}
