._1yNB1 {
  padding-left: 2px;
  margin-left: -2px;
  padding-right: 2px;
  margin-right: -2px;
}

._2-Q9b {
  width: 50%;
  flex-shrink: 1;
}
