._8X-cJ {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._17z5W {
  display: inline-block;
  vertical-align: bottom;
}

.L5TFF {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.-U1hZ {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
