._2oqSu {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  position: relative;
  flex-wrap: nowrap;
}

div._1JeVd {
  flex-wrap: wrap;
}

._27McM {
  cursor: default;
  pointer-events: none;
}

._27McM ._29B4e {
  color: var(--grey);
}

._29B4e {
  min-width: 0;
  font-size: 15px;
  color: var(--black);
  margin-right: 8px;
}

._3kdjq {
  font-size: 11px;
  color: var(--grey);
  white-space: nowrap;
  line-height: 1;
}

._3zWWq {
  font-size: 13px;
  line-height: 1.23;
  color: var(--greyDark);
  padding: 2px 0 0;
  flex-grow: 2;
}

._1QbHx {
  height: 25px;
  line-height: 16px;
  padding: 6px 16px 0;
  outline: none;
}

._1QbHx ._29B4e {
  font-size: 13px;
  color: var(--greyDark);
}

._3u9Js {
  background-color: var(--blue);
  color: var(--white);
}
._3u9Js > div > ._29B4e {
  color: var(--white);
}
._3u9Js > div > ._29B4e span {
  color: var(--white);
}
._3u9Js > div > ._3kdjq {
  color: var(--white);
}
._3u9Js > div > ._3zWWq,
._3u9Js > div > .eM-9Z {
  color: rgba(255, 255, 255, 0.8);
}

._32yTP {
  margin-right: 8px;
}

.eM-9Z {
  display: flex;
  align-items: center;
  margin: 0 8px 0 0;
}
.eM-9Z._27McM {
  opacity: 0.5;
}

._1awYl {
  height: 100%;
  display: flex;
  align-items: center;
  flex: none;
}

._1HUMK {
  padding: 8px 16px;
  min-height: 32px;
  width: 100%;
  min-width: 180px;
  height: auto;
}

._2oqSu {
  font-size: 15px;
  line-height: 20px;
}

._2mMSn {
  font-size: 15px;
  font-weight: 600;
  color: var(--black);
}

._29B4e,
._1QbHx ._29B4e {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._2ExkG {
  padding-bottom: 0;
  font-weight: bold;
  padding-top: 18px;
  height: 44px;
}

._2ExkG > ._29B4e {
  color: var(--black);
  font-size: 15px;
}

._2ExkG:first-child {
  padding-top: 14px;
  height: 40px;
  border-top: none;
}

._2ExkG:not(:first-child) {
  border-top: 1px solid var(--greyLight30);
  margin-top: 7px;
}

.frWOV {
  margin-top: 7px;
  border-top: 1px solid var(--greyLight30);
  height: 1px;
}

._1a7z4 ._29B4e {
  color: var(--blue);
}
