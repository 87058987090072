._1IOKN {
  border: 2px dashed var(--greyLight10);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
}

._2Tovo {
  position: relative;
  margin: 0;
  width: 100%;
}

._1E5hY {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--accent2Secondary);
}
