._30HEP {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 0 24px;
}

._1FPOs {
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}
