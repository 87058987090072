._2-22o {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 30px;
  padding: 0 12px;
  border-radius: 15px;
  background-color: rgb(26 33 45 / 12%);
  white-space: nowrap;
}

._2uyyC {
  background-color: rgb(26 33 45 / 5%);
}

.y-evO {
  background: none;
}

._1rHCP {
  background: rgba(26, 33, 45, 0.2);
}

._2kZjT {
  overflow: hidden;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  text-overflow: ellipsis;
  color: currentColor;
  outline: none;
}

.zbW1X {
  color: currentColor;
  padding-top: 2px;
}

.AHOxg {
  color: var(--white);
}
.AHOxg:hover {
  color: var(--darkNavy);
}

._3vZ5F {
  color: var(--black);
}
._3vZ5F:hover {
  color: var(--blue);
}

._1aNg5 {
  display: flex;
  margin-right: 8px;
}

._2ggnQ {
  display: flex;
  flex: none;
  margin: -4px 8px 0;
}

.iEKKP {
  margin-right: 8px;
  display: flex;
}

._3cFiK {
  margin-left: 8px;
}
