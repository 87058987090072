._2ajqS {
  background: var(--c-base-extralight);
  padding: 12px;
}

._2Q-Nt {
  margin-left: 12px;
}

.nYj70 {
  width: 100%;
}

.EnGS6 {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 176px 1fr;
}

.pp8r9 {
  display: grid;
  gap: 8px;
}

._8g2-9 {
  width: 214px;
}
