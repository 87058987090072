.tc1Cr {
  background: var(--churn-survey-gradient);
  width: 520px;
}

.tc1Cr._2pnVi {
  background: transparent;
  width: 100%;
}

._3jTkh {
  margin-left: -12px;
  margin-top: -12px;
}

._3jTkh._2pnVi {
  flex-direction: column;
  margin: 0 16px;
  flex: auto;
}

._1kzh5,
._2NeyD {
  flex-grow: 1;
  margin-left: 12px;
  margin-top: 12px;
}

._3jTkh._2pnVi ._1kzh5,
._3jTkh._2pnVi ._2NeyD {
  margin: 0;
  flex: auto;
}

._3jTkh._2pnVi ._1kzh5 {
  margin-bottom: 8px;
}

._3eQb7 {
  margin: 10px 0;
}
