._2X4Tj {
  width: 600px;
}

._255PL {
  padding-top: 32px;
  margin: 0 auto 32px;
}

.JdZl1 {
  width: 150px;
  height: 150px;
  display: block;
  border-radius: 4px;
  overflow: hidden;
  flex: none;
}
