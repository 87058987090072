.faigb {
  min-width: 600px;
  box-shadow: var(--c-box-shadow);
  border-radius: 12px;
  background-color: var(--white);
  overflow: hidden;
}

._1JDNh {
  display: block;
  padding: 20px 0px 0px 24px;
}

._3zirN {
  padding: 0 24px 0 24px;
  display: block;
}

.MadK1 {
  height: 8px;
  margin: 0 24px;
  border-bottom: 1px solid var(--greyLight10);
}

._1PRm2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

._2vAry {
  grid-column: initial;
  display: flex;
  /*
	 * Нужно для корретной отрисовки headers, тк для последней колонки
	 * не рисуется заголовок и ее ширина 24px
	 */
  min-width: 24px;
}

._2vAry:first-child {
  margin: 0 16px 0 24px;
  min-width: 100px;
  order: 1;
}

._2vAry:nth-child(2) {
  width: 236px;
  margin-right: 32px;
  flex: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  order: 2;
}

._2vAry:nth-child(3) {
  width: 124px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  order: 3;
}

._2vAry:last-child {
  margin: 0 24px 0 20px;
  grid-column: initial;
  order: 4;
}

._1Uc_1 {
  flex-grow: 1;
}

@media (max-width: 720px) {
  ._1PRm2 {
    flex-wrap: wrap;
  }

  ._2vAry {
    justify-content: flex-end;
    align-items: flex-start;
  }

  ._2vAry:first-child {
    order: 1;
  }

  ._2vAry:nth-child(2) {
    order: 3;
    align-items: flex-start;
  }

  ._2vAry:nth-child(3) {
    order: 4;
  }

  ._2vAry:last-child {
    order: 2;
    align-items: flex-end;
  }
}
