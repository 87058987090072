._3r0uy {
  height: 92px;
  width: 100%;
  align-items: center;
  background-color: var(--greyLight30);
  border-top: 1px solid var(--c-base-light);
}

._3r0uy:hover {
  background-color: var(--white);
}

._1gzIR {
  max-width: 132px;
  padding: 8px;
  padding-left: 20px;
  width: 12%;
  box-sizing: border-box;
  overflow: hidden;
}

._3r0uy:hover ._19mjs {
  display: none;
}

.j1R9o {
  display: none;
}

._3r0uy:hover .j1R9o {
  display: flex;
}
