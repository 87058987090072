._2TkGn {
  position: absolute;
  top: -41px;
  left: calc(-1 * var(--content-padding-left));
  width: 100%;
  height: 40px;
}

.COnZT {
  position: absolute;
  left: 8px;
  bottom: 6px;
  background-size: 18px auto;
}

._1uspy {
  opacity: 0;
}

.xP9Iw .COnZT {
  opacity: 0.3;
}

.xP9Iw .COnZT:hover {
  opacity: 0.9;
}

.xDLoB {
  position: absolute;
}
