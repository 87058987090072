._2ibgM {
  flex: none;
  width: 320px;
  padding: 36px 24px 24px;
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);
}

._3eWE9 {
  margin-top: auto;
}

@media (max-width: 720px) {
  ._2ibgM {
    width: 260px;
    padding: 36px 16px 16px;
  }
}
