.JfUrK {
  width: 152px;
  flex: none;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  color: var(--black);
}

.yfiwi {
  margin: 0 0 32px;
}

.yfiwi:last-child {
  margin: 0;
}

._1KMS3 {
  flex: none;
  width: 382px;
}
