._32AVh {
  font-size: 12px;
  margin-bottom: 4px;
}

._2y3ro {
}

._1GEWP {
  width: 12px;
  height: 12px;
  color: #e9604a;

  /* fix baseline alignment */
  position: relative;
  bottom: -2px;
}

._115zT {
  width: 26px;
  padding-left: 3px;
  flex-shrink: 0;
}
