._1Kw0i {
  color: #a3a3a3;
  font-size: 15px;
}

._3kzUJ {
  display: inline-block;
  padding: 5px var(--bubble-padding-horizontal, 12px);
  border-radius: 1em;
  border: 1px solid #e9e8e8;
}
