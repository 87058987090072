._2bRp2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  cursor: pointer;
}

._4a8HO {
  cursor: pointer;
  pointer-events: auto;
}

._2bRp2 {
  cursor: default;
  padding: 0 4px 0 12px;
}

._2bRp2:not(:first-child) {
  margin-top: 16px;
}
