._3sXo1 {
  margin-right: auto;
  padding: 8px;
  overflow: hidden;
  flex: none;
  width: 28%;
  box-sizing: border-box;
}

._6AddL {
  display: flex;
}

._2DSTD {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--greyLight10);
}

._2qMPl {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._2IbIb {
  cursor: default;
  pointer-events: none;
}
