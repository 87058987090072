._1AfjY {
  align-self: stretch;
  width: 4px;
  border-radius: 2px;
  background-color: var(--greyLight20);
}

._2MH9T {
  height: 249px;
  width: 140px;
  object-fit: cover;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid var(--grey);
}
