.HmvhZ {
  width: 588px;
  background: var(--white);
}

._1Z_1L {
  width: 100%;
}

.MRog1 {
  margin: 4px -16px 16px -16px;
  display: flex;
  flex-direction: column;
}

._28830 {
  background: var(--blueLight30);
}

.nSXQ6 {
  font-size: 0;
  position: relative;
  right: 24px;
  top: 4px;
}

._2L3qJ {
  margin-left: auto;
  margin-bottom: 8px !important;
}

._1O7oU {
  margin-bottom: 4px !important;
}

._2Fl76 {
  width: 100%;
}

._6ECQO {
  margin-top: 12px;
}

._1hra9 {
  margin-left: auto;
}

._1GbpJ {
  margin-top: 4px;
}

._29lMb {
  margin-top: -4px;
}

._2lspl {
  margin: 13px 0 15px 0;
}

.I86he {
  text-decoration: line-through;
}

._1pxmy {
  flex: none;
  width: 100%;
}

._3JXXo {
  flex: none;
}

._2WVib {
  border-radius: 0;
  padding: 0;
}

._2WVib:first-of-type {
  border-radius: 8px 8px 0 0;
}

._2WVib:last-of-type {
  border-radius: 0 0 8px 8px;
}

.QoDq6 {
  margin-right: 16px;
  margin-left: 40px;
  position: relative;
}
