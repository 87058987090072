.Pjyjw {
  display: flex;
  list-style-type: none;
}

._3yeTr {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 16px 10px 10px;
  border-radius: 20px;
  width: 100%;
  background-color: var(--blueLight30);
}

._3yeTr.YvHQv {
  background-color: var(--blue) !important;
}

._3yeTr:hover,
._3yeTr:focus {
  background-color: var(--blueLight30);
}

._3yeTr:disabled {
  pointer-events: none;
  opacity: 0.45;
}

.C_MFI {
  margin: 0;
  padding: 0;
}

._3ezb2 {
  display: flex;
  overflow-x: auto;
}

._2IlOM {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

@media (max-width: 720px) {
  ._3ezb2 {
    margin: 0 -16px;
    padding: 0 16px 10px 16px;
  }
}
