._2f0RI {
  width: 476px;
  position: relative;
}

._2JPRR {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 7px 0 9px 12px;
  font-size: 15px;
  line-height: 1.2;
  background-color: transparent;
}

.UrH1M {
  margin: -4px -12px;
  flex: none;
  width: 500px;
}

.NJHP3 {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: var(--blue);
  vertical-align: -2px;
}

._1JoBd {
  margin: -20px 0 -20px 16px;
  width: 400px;
  flex: none;
}

._13IyC {
  height: 24px;
  display: flex;
  outline: none;
  margin: -2px 0 -7px;
}
