._3pzsy {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 108px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 40%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 40px;
  pointer-events: none;
}

.i1UDY {
  pointer-events: auto;
}

.ac2LU {
  width: 480px;
}
