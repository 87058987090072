.M-GQW {
  height: calc(100vh - 64px);
}

._2iCsL {
  width: 553px;
  height: 600px;
  padding-bottom: 32px;
  background-color: var(--greyLight30);
  border: 1px solid #e8e6e6;
  border-radius: 6px;
}

._3vAEL {
  padding: 32px;
  padding-bottom: 24px;
}

._3ljRR {
  max-height: 464px;
  padding: 0 16px;
}

._1hl2R {
  display: flex;
  height: 44px;
  align-items: center;
}

._2hwDm {
  max-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 44px;
  overflow: hidden;
}

._387vd {
  width: 210px !important;
}

._1JETT {
  width: 154px !important;
}

._387vd,
._1JETT {
  height: 8px;
}

.Dd-CY {
  display: flex;
  margin-left: auto;
  flex-shrink: 0;
}

.Dd-CY button {
  margin-left: 16px;
}

._29p0H {
  opacity: 0.4;
  width: 71px !important;
}

._6tpWE {
  width: 81px !important;
}

._1c4Rx {
  color: var(--greyDark);
  border-bottom: 1px solid var(--greyDark);
}

._1c4Rx:hover {
  text-decoration: none;
}

._38nLZ {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background: no-repeat center center;
  background-color: var(--greyLight20);
  background-size: cover;
  flex-shrink: 0;
}

._2EjVB {
  background-image: url(/src/pages/DeepLinks/assets/iMF6w.png);
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  ._2iCsL {
    width: 360px;
  }

  ._3vAEL {
    padding: 32px 16px 24px;
  }

  ._3ljRR {
    padding: 0 16px;
  }

  ._2hwDm {
    max-width: 123px;
  }

  ._387vd {
    width: 93px !important;
  }

  ._1JETT {
    width: 56px !important;
  }
}
