._30isO {
  width: 600px;
  max-width: 100%;
}

._3S2MD {
  margin-bottom: -2px;
}

@media (max-width: 720px) {
  ._3S2MD {
    width: 18px;
    height: 18px;
  }
}
