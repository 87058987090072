._2z59f {
  background-color: var(--yellowLight);
  border-radius: 8px;
  padding: 12px 12px 8px 12px;
}

._3EXy9 {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--yellow-text);
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

._1VxG5 {
  resize: none;
  width: 100%;
}
