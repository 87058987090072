._19jG4 {
  width: 100%;
  padding: 24px 24px 24px 120px;
  border-radius: 4px;

  background: url(/src/pages/Plans/components/WhatsappPhoneCallout/images/dXwUD.svg),
    radial-gradient(
      ellipse,
      var(--c-accent2-tertiary) 0%,
      var(--c-accent2-light) 50%
    ),
    var(--c-accent2-light);
  background-size: auto auto, 100% 300%;
  background-position: 24px 0, -260px 50%;
  background-repeat: no-repeat, no-repeat;
}

._18tmr {
  padding: 80px 16px 16px;
  background-position: 24px -10px, -130px 100%;
}

._3d6mG {
  max-width: 480px;
}

._2TFsl {
  min-width: 160px !important;
}

._2ZfVw {
  min-width: 100% !important;
}
