._34Fob {
  padding: 14px 0 6px;
  flex: 0 0 160px;
  z-index: var(--zindex-left-navigation-panel);
  overflow-y: auto;
}

._15iqM {
  display: flex;
  flex-direction: column;
}

._2pcQo {
  flex-basis: 68px;
}

._3i2a_ {
  padding-bottom: 8px;
}

._3bxht {
  display: flex;
}

._2-KQK {
  pointer-events: none;
  opacity: 0.3;
}

._2t3ak {
  pointer-events: none;
}

._3IBQ2 {
  height: 100%;
}

._1dJyr {
  margin-bottom: 8px;
}

@media (max-width: 720px) {
  ._15iqM {
    max-width: 100%;
  }

  ._34Fob {
    padding-top: 22px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: var(--zindex-left-navigation);
  }

  ._3IBQ2 {
    overflow-y: auto;
  }

  ._3bxht {
    display: none;
  }

  ._2qNpQ {
    height: 60px;
  }

  ._1dJyr {
    margin-bottom: 0;
  }
}
