._12CXb {
  cursor: pointer;
  color: var(--blue);
  padding: 6px 16px 8px;
  font-size: 15px;
  line-height: 18px;
  width: 100%;
  min-width: 180px;
  min-height: 32px;
  height: auto;
}

._1lD5d {
  color: var(--white);
  background: var(--blue);
}
