._2P4OQ {
  font-size: 15px;
  line-height: 18px;
  color: var(--greyDark);
  margin-top: 32px;
  margin-bottom: 8px;
}

._3b2JW {
  width: 376px;
}

._1gS4_ {
  flex: none;
  margin-left: 16px;
  width: 144px;
}

._2F_uj {
  color: var(--greyDark);
  font-size: 15px;
  line-height: 16px;
  padding-left: 28px;
}

._3h701 {
  color: #b3b3b3;
  margin-right: 8px;
}

._5ihlr {
  margin: 2px 8px 0 0;
}

._1WLPp {
  margin-top: 32px;
}
