.zfNqp {
  min-width: 0;
}

.MK3XG {
  flex: none;
}

._1fg-x {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 18px;
  line-height: 24px;
}

._31Rm- {
  flex: auto;
  overflow: hidden;
}

._265M0 {
  margin-top: -4px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
