._1-ReY {
  margin-top: 24px;
  margin-bottom: 24px;
}

._1EGeD {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 24px;
  max-width: 496px;
}

._2dFsF {
  text-decoration: none;
  color: #448af1;
}
