._3yRsa {
  position: relative;
}

.DN9sa {
  position: absolute;
  top: 0;
  right: -50px;
}

._3yRsa._2uqQX {
  opacity: 0;
  position: absolute;
}
