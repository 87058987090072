._3YAHL {
  background-color: var(--c-accent3-light);
  padding: 16px 32px;
  margin: -32px -32px 24px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

._3YAHL._19xGU {
  margin: -16px -16px 16px;
}
