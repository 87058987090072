._1lwu5 {
  position: relative;
  overflow: hidden;
  max-height: 300px;
}

._2IVYJ {
  overflow-y: auto;
  max-height: min(308px, 40vh);
  position: relative;
}

._3QMWh {
  height: 100%;
  max-height: 100%;
}

._1zFMN {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

._1ksfx {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  pointer-events: none;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
