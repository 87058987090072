._1QYq6 {
  width: 100%;
}

._8gu4i > div {
  min-height: 56px;
  word-break: break-word;
}

._1n_aO {
  margin: 0 0 8px 12px;
}
