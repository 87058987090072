._1kfLF {
  position: relative;

  width: 1024px;
  min-height: 370px;
  margin: 0 auto 32px;
  padding: 30px 26px;

  border-radius: 7px;
  background-color: var(--white);
}

._2ytVG::before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  content: '';

  border-radius: 8px;
  background: url(/src/assets2/images/28XgI.svg) no-repeat center center
    var(--white);

  z-index: 10;
}

._3XN19 {
  margin-bottom: 35px;
}

._1npsL {
  margin-top: -12px;
  margin-right: -2px;
}

._3ivGR {
  border: 1px solid var(--grey);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-decoration: none;
  color: var(--grey);
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._1GQ_I {
  width: 252px;
  margin-right: 22px;
}

._1T4Cw {
  margin-bottom: 6px;
}

._3qYiX {
  margin-bottom: 6px;
}

._22iYQ {
  font-size: 64px;
  line-height: 64px;
}

._3eJtw {
  font-size: 18px;
  line-height: 24px;
}

.khEi_ {
  width: 232px;
  margin-bottom: 16px;
  color: var(--greyDark);
}

.GZkoW {
  margin-bottom: 18px;
}

.QNLhC {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._39WpI {
  display: flex;
  align-items: center;
}

._1IY8s {
  margin-top: 14px;
  height: 20px;
  color: var(--greyDark);
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

._2hKuM {
  margin-left: 8px;
  color: var(--greyDark);
}

._1DMlP {
  color: var(--greyDark);
  text-decoration: underline;
}

._1DMlP:hover {
  text-decoration: none;
}

._3cZTt {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--greyDark);
  display: inline !important;
  outline: none;
}

._3cZTt:hover {
  text-decoration: none;
}
