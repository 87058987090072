.FKjW2 {
  background: no-repeat center center #f0f0f0;
  display: inline-block;
  overflow: hidden;
  background-size: cover;
  border-radius: 50%;
}

._1jxaU {
  position: relative;
  height: 40px;
  width: 40px;
}

._2V3b- {
  position: absolute;
  bottom: -4px;
  right: -4px;
}
