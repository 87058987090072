._3tPBR {
  display: flex;
  height: 26px;
  flex-direction: column;
  overflow: hidden;
}

._3tPBR:first-child {
  margin-bottom: 19px;
}

._1RmX_ {
  height: 100%;
}

._1RmX_:first-child {
  margin-bottom: 15px;
}

._2a5hn {
  margin: auto;
  margin-left: 12px;
  width: 100%;
}

.DXfWW {
  width: 100%;
}

._2vzDG {
  padding-left: calc(-6 * var(--unit));
}

._1sFn0 {
  display: inline-flex;
  margin-left: calc(6 * var(--unit));
  margin-bottom: calc(1 * var(--unit));
  white-space: nowrap;
}

._1sFn0:first-child {
  width: 120px;
}

.mYyCC {
  position: relative;
}

._1S-sT {
  margin-right: 24px;
}

._2aku4 {
  position: absolute;
  right: -20px;
  top: 3px;
}

._2aku4 path {
  fill: var(--blue);
}

._2GLnW,
._3wBzG,
._2Fotf {
  display: flex;
  align-items: center;
  line-height: 22px;
  z-index: 1;
}

._1pN1L,
._3wBzG {
  height: 24px;
  outline: none;
}

._1_sqC {
  align-self: center;
  position: relative;
  top: -1px;
  width: 8px;
  height: 8px;
  fill: var(--blue);
  margin-left: calc(2 * var(--unit));
}

._13MIh {
  border-bottom: 1px dashed;
  cursor: pointer;
  position: relative;
  bottom: -2px;
}

._13MIh:hover {
  border-bottom-style: solid;
  text-decoration: none;
}

._27V3t {
  margin-right: 16px;
  position: relative;
  left: 8px;
}

._1pN1L {
  display: flex;
  height: 24px;
  border-radius: 4px;
  position: relative;
  left: -8px;
}

._1pN1L:disabled {
  opacity: 1;
}

._1pN1L:hover {
  background-color: var(--greyLight20);
}

._1UwKS {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  margin: 4px 4px 0;
  position: absolute;
  left: -22px;
}

._2CoT5 {
  transform: none;
}
