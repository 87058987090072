._2w2I- {
  margin: 2px 4px 4px 0;
  cursor: pointer;
  position: relative;
  height: 28px;
}

._2w2I-.EoTyV {
  color: var(--blue);
  box-shadow: 0 0 0 2px var(--blueLight);
}

._3Nh6E {
  padding: 0 8px;
  cursor: pointer;
  position: relative;
  height: 28px;
  max-width: inherit;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: var(--white);
  font-size: 15px;
  font-weight: normal;
  box-shadow: 0 0 0 1px rgba(16, 16, 16, 0.04), 0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._20L5c,
._20L5c:enabled:active,
._20L5c:disabled,
._20L5c:enabled:hover {
  background-color: var(--white);
  font-size: 15px;
  min-width: 64px;
  max-width: 196px;
}

._2P3rX {
  margin: 0 0 0 8px;
}

._3lQ1K {
  opacity: 0.4;
}

._1UVYo {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

._30AYd {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

._3o04x {
  border: none;
  outline: none;
  position: absolute;
  color: var(--greyDark);
  top: 0;
  right: 0;
  width: 28px;
  height: 28px;
  padding: 0;
  background-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--white) 24%,
    var(--white)
  );
}

._3o04x:hover {
  color: var(--blue);
}

._2nHQh {
  margin-top: -3px;
}

._3rIrS {
  margin-top: -1px;
}

._37oY_ {
  margin-top: 4px;
}

._2eAkg {
  color: var(--red);
  box-shadow: 0 0 0 2px var(--red);
}

._1nMF7 {
  color: var(--white);
  font-size: 15px;
  white-space: nowrap;
  background-color: var(--red);
  display: inline-block;
  border-radius: 4px;
  height: 28px;
  position: absolute;
  top: -40px;
  left: -25%;
  padding: 5px 12px;
  width: 282px;
  text-align: center;
}

._2frTG {
  border: 7px solid transparent;
  border-top: 7px solid var(--red);
  position: absolute;
  top: -12px;
  left: 50%;
}
