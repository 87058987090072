._39LI0 {
  width: 592px;
}

._1tzpR {
  text-align: center;
  margin-bottom: 24px;
}

._3Mkrt {
  font-size: 15px;
  line-height: 20px;
  margin: 8px 0;
}

.CFJaT {
  font-weight: 600;
}

.h9Jq_ {
  width: 26px;
  height: 26px;
  border-radius: 26px;
}

._22QpK {
  margin-top: 40px;
}

.BlZg8 {
  margin-left: 8px;
}

@media (max-width: 720px) {
  .BlZg8 {
    margin-left: 0px;
    margin-top: 8px;
  }
}
