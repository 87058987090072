.jC1as {
  position: absolute;
  left: 0;
  bottom: 16px;

  height: 36px;
  width: 520px;
}

.SaIsk {
  width: 100%;
}

._3cx9r {
  width: 100%;
  margin-top: 8px;
}
