._3eG7f {
  padding: 4px;
  background-color: var(--c-base-extralight);
  border-radius: 4px;
  gap: 4px;
}

.SqrEk {
  color: var(--c-accent1-normal);
}
