._2zF34 {
  flex-grow: 1;
  min-width: 600px;
}

._2zF34._1F6b3 {
  min-height: 384px;
}

._2zF34._2dC9z {
  min-height: 600px;
}

._2wHwr {
  box-shadow: var(--c-box-shadow);
  border-radius: 12px;
}

._2wHwr._2zF34 {
  flex-shrink: 1;
}

._3xh8o {
  margin: 8px 24px 4px 24px;
}

._2bDhk {
  display: grid;
  gap: 24px;
  grid-template-columns: 332px 600px;
}

@media (max-width: 1120px) {
  ._2bDhk {
    grid-template-columns: auto;
    align-self: stretch;
  }
}

@media (max-width: 720px) {
  ._2zF34 {
    min-width: 100%;
  }

  ._101hZ {
    min-width: 100%;
  }
}
