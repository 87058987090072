._3Znyw {
  position: fixed;
  z-index: var(--zindex-modal);
  bottom: 20px;
  width: 412px;
  height: 44px;
  left: 24px;
  padding: 8px 8px 8px 12px;
  min-width: 300px;
  transition: width 0.1s ease 0.1s;
}

._3uWHE {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 8px 64px 0 rgba(20, 20, 20, 0.16);
  cursor: pointer;
  border-radius: 4px;
}

._2ffR0 {
  display: flex;
  justify-content: space-between;
}

._1Nh2G {
  color: var(--white);
  font-size: 15px;
  line-height: 27px;
  white-space: nowrap;
}

.tyJWr {
  flex-grow: 1;
}

._1kFkO {
  flex-grow: 0;
  opacity: 0.4;
  padding: 0 8px;
}

._2cor2 {
  background: var(--white);
  width: 28px;
  height: 28px;
  border-radius: 3px;
}

._2xMIo {
  opacity: 0.01;
  bottom: -44px;
}
.I43Fd {
  opacity: 1;
  bottom: 20px;
  transition: opacity 0s ease, bottom 0.2s ease;
}
.XhFhO {
  opacity: 1;
  bottom: 20px;
}
._1LMpW {
  opacity: 0.01;
  bottom: -44px;
  transition: opacity 0.2s ease, bottom 0.2s 0.2s ease;
}

._1OryL {
  pointer-events: none;
}

._3pO4m {
  width: 300px;
}

._1gsxt {
  display: inline-block;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
}

._1GGF5 {
  position: absolute;
  width: calc(100% - 20px);
  top: 8px;
  left: 12px;
  opacity: 0;
  transition: opacity 100ms ease;
}

._1OswT {
  opacity: 1;
  transition: opacity 100ms 100ms ease;
}
