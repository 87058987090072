._2j6AX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  cursor: pointer;
  position: relative;
  padding-right: 24px;
}

._2j6AX::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.pozFB._2j6AX form {
  font-weight: 600 !important;
}

.pozFB._2j6AX p {
  font-weight: 500 !important;
}

.pozFB._2j6AX::after,
._2j6AX:hover::after {
  background-color: var(--greyLight20);
}

._2EFuA {
  color: var(--black);
  outline: none;
}

._17xu8 {
  text-decoration: none;
  color: var(--black);
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

._3y1Eh {
  font-weight: bold;
}

._3y1Eh span {
  /* sorry, guys :( */
  font-weight: bold !important;
}

.sJbGx {
  flex: 0 0 auto;
}

._2BEC3 {
  height: 32px;
  width: 72px;
}

._3TPD0:focus {
  box-shadow: none !important;
}

._29rcC {
  width: 400px;
}

._30wMB {
  display: flex;
  align-items: center;
}

._3FXKX {
  max-width: 236px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pozFB._2j6AX ._3FXKX,
.WcESV._2j6AX ._3FXKX,
._2j6AX:hover ._3FXKX {
  max-width: 212px;
}

._2tmFf:focus {
  outline: none;
}

._2tmFf:focus {
  outline: none;
}

._3zGj7 {
  width: 620px;
  min-height: 300px;
  border-radius: 4px;
  background-color: var(--greyLight20);
  position: relative;
}

._3jYIX {
  border-radius: 4px;
  max-width: 620px;
  max-height: 480px;
  object-fit: contain;
  overflow: hidden;
}

._2yqV9 {
  width: 0;
  height: 0;
  overflow: hidden;
}
