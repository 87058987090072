._2cB-Y {
  width: 64px;
  height: 64px;
  left: 608px;
  top: 158px;
  border: 1px solid rgba(16, 16, 16, 0.08);
  border-radius: 4px;
  background: no-repeat center center var(--white);
  background-size: contain;
  overflow: hidden;
  margin-bottom: 24px;
  flex: none;
}

._1xC3M {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--black);
  margin-bottom: 24px;
}

._9hhB_ {
  display: block;
  text-align: left;
  width: 304px;
  font-size: 15px;
  line-height: 16px;
  color: #979797;
  margin-bottom: 8px;
}

._3wHoB {
  width: 304px;
  margin-bottom: 32px;
}

._264op {
  padding: 8px 0;
}

._3iumX {
  width: 168px;
}

._15KHq {
  font-size: 12px;
  line-height: 16px;
  color: #979797;
  text-decoration: none;
}

._1oBhf {
  display: block;
  text-align: left;
  width: 304px;
  font-size: 12px;
  line-height: 16px;
  color: var(--grey);
  padding-top: 8px;
  margin-bottom: -24px;
  height: 24px;
}
