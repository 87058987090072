._1nJ06 {
  width: 100%;
  text-align: left;
}

._1ASBe {
  font-weight: 400;
  font-size: 15px;
  color: var(--grey);
}
