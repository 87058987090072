._3XrxK {
  animation: uEfRv 0.2s ease;
  will-change: transform;
}

@keyframes uEfRv {
  from {
    opacity: 0;
    transform: translateY(64px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
