._3Fhlo {
  animation: _139Pa 300ms ease-in-out;
  transform-origin: top;
  margin-bottom: 16px;
}

._37bE6 {
  height: 148px;
}

._3Rk3P {
  height: 236px;
}

@keyframes _139Pa {
  0% {
    transform: scale(1, 0.8);
    opacity: 0;
  }

  95% {
    transform: scale(1, 1);
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 720px) {
  ._3Fhlo {
    min-width: 100%;
    height: 250px;
  }
}
