._1IjEy {
  padding-bottom: 14px;
}

._1IjEy::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

._3wgHl {
  display: flex;
  overflow-x: auto;
  width: 100vw;
}

._1KjvU {
  position: relative;
  max-width: 1020px;
}

._1flNx {
  width: 1020px;
  padding-bottom: 14px;
  padding-left: 4px;
}

.D0MAE {
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 36px);
}

._3FMiw {
  left: -70px;
  transform: rotate(-180deg);
}

._29XQF {
  right: -70px;
}
