.vNDAm {
  counter-reset: steps;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.vNDAm li {
  position: relative;
  padding: 3px 0 40px 40px;
}

.vNDAm li:last-child {
  padding-bottom: 0;
}

.vNDAm li:not(:last-child):before {
  content: '';
  position: absolute;
  left: 13px;
  top: 0;
  height: 100%;
  width: 1px;
  z-index: 2;
  border-left: 2px dashed var(--grey);
}

.vNDAm li:after {
  counter-increment: steps;
  content: counter(steps);

  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: var(--white);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 14px;

  font-size: 15px;
  line-height: 22px;
  color: var(--grey);
  font-weight: 600;
}

.vNDAm li[data-label]:after {
  content: attr(data-label);
}

.vNDAm li.wz1fR:after {
  color: var(--blue);
}

.vNDAm li.Yj-L9:before {
  border-left-style: solid;
  border-left-color: var(--blue);
}

.vNDAm li.Yj-L9:after {
  color: transparent;
  background: url(/src/modern-ui/_deprecated/Icon/icons/3bItx.svg) no-repeat center
    center var(--blue);
}

._2QxP9 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: var(--black);
}
