._1O3sG {
  height: 100%;
  width: 100%;
}

._2GGiS {
  height: auto;
  width: 100%;
}

._1aHAu {
  display: flex;
  width: 100%;
  padding-top: 21px;
  padding-bottom: 24px;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  background-color: var(--greyLight20);
}

._3MiiF {
  min-width: 370px;
}
