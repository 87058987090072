._2OaVB,
.JuupZ {
  width: fit-content;
}

.JuupZ label {
  display: flex;
  align-items: center;
}

._1o5gH {
  display: flex;
}

._1iiFD {
  position: relative;
}

._3cgzv {
  position: absolute;
  z-index: 2;
  opacity: 0.5;
  background-color: var(--greyLight30);
  border-radius: 0 0 8px 8px;
  width: 100%;
  height: 100%;
}

._2yhHX {
  width: 60px;
}

._1Xlkd {
  flex: 1 1 100%;
  -webkit-user-select: all;
          user-select: all;
}
