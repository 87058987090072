._2mr9a {
  padding: 32px 40px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 80vw;
  max-height: calc(100vh - 30px);
  position: relative;
  top: 15px;
  overflow-y: auto;
  /*border-radius: 8px 8px 0 0 !important;*/
}

._2mr9a > * {
  margin-bottom: 32px;
}

._2mr9a > div:last-child {
  margin-bottom: 10px;
}

._2hF7h {
  width: 100%;
}

._2hF7h ._3c9FI {
  font-size: 15px;
  line-height: 17px;
  font-weight: bold;
  margin-bottom: 8px;
}

._2hF7h ._2ZvGF {
  font-size: 15px;
  line-height: 20px;
  color: var(--greyDark);
  margin-bottom: 16px;
}

._2hF7h ._2ZvGF p {
  margin: 0;
}

._2hF7h ._2ZvGF a {
  color: var(--blue);
  text-decoration: none;
  outline: none;
}

._2hF7h .pg6-E {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

._3c9FI + .pg6-E {
  margin-top: 16px;
}
