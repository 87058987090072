.iqC7h {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 28px;
  border-radius: 14px;
  border: 1px dashed var(--greyDark);
  align-self: flex-start;
  white-space: nowrap;
}

.iqC7h:hover {
  border-color: var(--black);
}
