._1fZlT {
  position: absolute;
  visibility: hidden;
}

._1fZlT + label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 15px;
  line-height: 16px;
  display: inline-block;
  color: var(--black);
}

._2bTMS + label {
  cursor: default;
}

._1fZlT + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(16, 16, 16, 0.16);
  border-radius: 100%;
  background: transparent;
}

._3MYeI._3_XYA {
  padding-left: 0;
  padding-right: 24px;
}

._3MYeI._3_XYA:before {
  left: unset;
  right: 0;
}

._3MYeI._3_XYA:after {
  left: unset;
  right: 3px;
}

._3CBuT._3_XYA:before {
  top: calc(50% - 8px);
}

._3CBuT._3_XYA:after {
  top: calc(50% - 5px);
}

._1fZlT + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: var(--blue);
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

._1fZlT:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

._1fZlT:focus + label:after {
  opacity: 0.3;
  transform: scale(1);
}

._1fZlT:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
