._3ilZ4 {
  border-radius: 12px;
  padding: 3px;
  background: var(--chatfuel-gradient);
}

._1wlOK {
  background-color: var(--white);
  border-radius: 10px;
  padding: 40px;
}

._2AjjJ {
  opacity: 0.5;
  pointer-events: none;
}

._1MA8n {
  width: 100%;
  display: grid;
  grid-template-columns:
    [message] minmax(0, 2fr) [frequency] minmax(50px, min-content)
    [selectorsBox] minmax(0, 4fr);
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

._1wHGf {
  width: 32px;
  height: 32px;
  flex: none;
}

@media (max-width: 830px) {
  ._1MA8n {
    grid-template-columns: [message] auto [frequency] minmax(50px, min-content) [triangle] 20px;
  }
  ._1wlOK {
    padding: 32px;
  }
}
