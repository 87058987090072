._3_3-a {
  margin-top: 24px;
  margin-bottom: 24px;
}

._3YhxO {
  height: 99px;
  width: 100px;
  background: url(/src/pages/Login/PPX6A.png) no-repeat center center / cover;
}

._2D9-f {
  margin-top: 12px;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #747474;
}

@media (max-width: 400px) {
  ._3_3-a {
    font-size: 18px;
    line-height: 24px;
  }

  ._3YhxO {
    height: 56px;
    width: 56px;
  }
}
