.odYTW {
  padding: 12px;
  border-radius: 4px;
  background: var(--c-base-extralight);
  margin-bottom: 12px;
}

._1Xy0o {
  gap: 8px;
}
