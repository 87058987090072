.WWhis {
  background-color: var(--cobalt);
  border-radius: 12px;
  overflow: hidden;
  flex: none;
}

._1nPxy {
  flex-shrink: 0;
  margin-left: auto;
}
