._2OXK4 {
  --borderStyle: 1px solid;

  padding-bottom: 2px;
  text-decoration: none;
  cursor: pointer;
}
._2OXK4._9pxNs {
  pointer-events: none;
  cursor: default;
}

._3N4JU {
  font-size: 15px;
  line-height: 22px;
}

._3N4JU,
._3N4JU:hover:active {
  color: var(--black);
  border-bottom: var(--borderStyle) var(--grey);
  padding-bottom: 1px;
}
._3N4JU:hover {
  border-bottom: var(--borderStyle) var(--black);
  padding-bottom: 1px;
}
._3N4JU._9pxNs {
  color: var(--grey);
  border-bottom: var(--borderStyle) var(--grey);
  padding-bottom: 1px;
}

.kIQmx {
  font-size: 12px;
  line-height: 16px;

  color: var(--greyDark);
  border-bottom: var(--borderStyle) var(--greyDark);
  padding-bottom: 1px;
}
.kIQmx._9pxNs {
  color: var(--grey);
  border-bottom: var(--borderStyle) var(--grey);
  padding-bottom: 1px;
}

._3u3ZL {
  --external-link-color: var(--blue);
  --external-link-disabled-color: var(--blueLight);
}

._3u3ZL._5cOzL {
  --external-link-color: var(--black);
  --external-link-disabled-color: var(--grey);
}

._3u3ZL {
  font-size: 15px;
  line-height: 22px;

  color: var(--external-link-color);
}
._3u3ZL:hover {
  border-bottom: var(--borderStyle) var(--external-link-color);
}
._3u3ZL._9pxNs {
  color: var(--external-link-disabled-color);
}

._1uYwl {
  font-size: 12px;
  line-height: 16px;

  color: var(--white);
  border-bottom: var(--borderStyle) var(--white);
  padding-bottom: 1px;
}

.Kjm4U {
  font-size: 12px;
  line-height: 16px;
}

._1kPNi {
  font-size: 15px;
  line-height: 22px;
}

._19Uf4 {
  margin-left: calc(2 * var(--unit));
  width: 9px;
  height: 9px;
}

._1KbSq {
  width: 8px;
  height: 8px;
}
._1KbSq path {
  fill: var(--blue);
}

._1aL1M {
  width: 7px;
  height: 7px;
}
._1aL1M path {
  fill: var(--white);
}

._iKBx,
._iKBx:hover {
  text-decoration: none;
  border-bottom: none !important;
  padding-bottom: 0;
}
