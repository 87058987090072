._2QkHS {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: relative;
  border-radius: 4px;
  flex: none;
}

html:not(.focus-not-visible) ._2QkHS:focus-within {
  box-shadow: 0 0 0 2px rgba(32, 142, 240, 0.4);
}

.r9yhK {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--zindex-over-content);
  width: 100%;
  height: 100%;
  opacity: 0.01;
}

._3kFxg {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px #c4c4c4;
  background-color: var(--white);
}

._3kFxg._3sXTM {
  border: 1px solid #d9d9d9;
  background-color: transparent;
}

._3kFxg:hover {
  border-color: #838383;
}

._3kFxg._3sXTM:hover {
  border-color: var(--white);
}

._3kFxg.l2LFw {
  opacity: 0.4;
  pointer-events: none;
}

._3kFxg._1bWTM {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
}

._3kFxg._1bWTM._3sXTM {
  border: 1px solid var(--white);
  background-color: transparent;
  color: var(--white);
}

._3kFxg._1bWTM._1VB-S {
  border: solid 1px #c4c4c4;
  background-color: var(--white);
  color: var(--white);
}

._3kFxg._1-bRT {
  pointer-events: none;
  border: 1px solid #d9d9d9;
  background-color: #ececec;
  color: #adabab;
}

._3kFxg._1-bRT._3sXTM {
  border: 1px solid #d9d9d9;
  background-color: transparent;
  color: #d9d9d9;
}

._1CC7Y {
  display: block;
  margin: -1px -1px 0;
}

._1VB-S > svg._1CC7Y > path {
  fill: black;
}
