._2fUta {
  width: 117px;
  height: 20px;
  font-size: 19px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.05;
  letter-spacing: normal;
  margin-bottom: 16px;
}

.vzG_M {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.Zfgvf {
  width: 81px;
  height: 20px;
  font-size: 15px;
  line-height: 1.33;
  margin-right: 8px;
}

._3coAf {
  color: #8a9095;
  font-size: 15px;
  font-weight: normal;
}

._3WEM0 {
  font-size: 15px;
  color: var(--blue);
  text-decoration: none;
}

._3tChg {
  font-size: 15px;
  line-height: 1.33;
  color: var(--black);
}

._3tChg._1YS3O {
  color: rgba(255, 255, 255, 0.8);
}

._2wQZF {
  color: var(--blue);
  text-decoration: none;
}
._2wQZF._1YS3O {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

._3WEM0:hover,
._1IX1F:hover,
._2wQZF:hover {
  color: #86c6fe;
}

._2wQZF._1YS3O:hover {
  color: #a0d2ff;
}

._4qnU1 {
  font-weight: 400;
}

._1-Q4g {
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  height: 7px;
  width: 7px;
  transform: rotate(-45deg);
  margin-bottom: 4px;
}

._2-Q3- {
  margin-top: 8px;
}

._2SZmG {
  width: 14px;
  height: 14px;
  color: var(--blue);

  /* fix baseline alignment */
  position: relative;
  bottom: -2px;
}

._2t65a {
  width: 20px;
  flex-shrink: 0;
}

._6cIWk {
  color: rgba(0, 0, 0, 0.2) !important;
}
