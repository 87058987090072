._1JIVd {
  display: flex;
  width: 100%;
  height: 36px;
  border: 1px solid var(--c-base-light);
  border-radius: 4px;
  overflow: hidden;
}

.eafqb {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  cursor: pointer;
}

._121Xj {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-right: 1px solid var(--c-base-light);
}

._1JIVd > ._121Xj:last-child {
  border-right: none;
}

.swAlR {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.swAlR:hover:active + .eafqb {
  background-color: var(--c-accent1-secondary) !important;
}

.swAlR:hover:not(.swAlR:disabled) + .eafqb,
.swAlR:checked + .eafqb {
  background-color: var(--c-accent1-tertiary);
}

.swAlR:disabled + .eafqb {
  opacity: 0.5;
}
