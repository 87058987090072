._16wrY {
  outline: none;
  position: relative;
  top: 1px;
}

._3aNsg {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
}

._37MLU {
  position: relative;
  bottom: -3px;
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
}

._1tpcC {
  padding: 0;
  border: none;
  background-color: transparent;
}

.focus-not-visible ._1tpcC:focus {
  outline: none;
}

._1e6qG {
  display: block;
}

._1e6qG + ._1e6qG {
  margin-top: 15px;
}

.Yt8zF {
  vertical-align: bottom;
}

._38NUS {
  margin-left: 10px;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
}

._1l0B2 {
  color: #747474;
  margin-left: 4px;
}

._3CtoB {
  margin: 0;
  border: none;
  padding: 0;
}

._2AexI {
  height: 230px;
  overflow-y: auto;
  padding: 10px 0;
}
