._31Hdx {
  width: calc(100% + 16px);
  margin: 0 -8px;
  height: 465px;
}

._1F8pP > div::-webkit-scrollbar {
  display: none;
}

._1F8pP > div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.DqAwX {
  text-align: center;
}

.rMjhu {
  height: 446px;
}

.c5jsH {
  height: 44px;
  width: 100%;
  padding: 6px 8px;
  border-radius: 6px;
}

.c5jsH:hover {
  background-color: var(--greyLight20);
}

._17pgz {
  z-index: var(--zindex-popover);
}
