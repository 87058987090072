.EnHyJ {
  width: 100%;
  padding-left: 50px;
  padding-top: 44px;
  padding-right: 64px;
}

.U87Bt {
  background-color: var(--white);
  border-radius: 6px;
  padding: 30px 24px;
  width: 1025px;
  margin: 0 auto 32px;
}

._28Stv {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 16px;
}

.M5jdl {
  display: flex;
  align-items: center;
}

.M5jdl ._28Stv {
  margin-bottom: 0;
}

._1ZC05 {
  width: 30px;
  height: 30px;
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
}

._2IPtj {
  margin-left: 4px;
  margin-right: 4px;
}

._30niK {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

._2oDM_ {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}

._28F6p {
  margin-top: 28px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

._3UiKj {
  width: 430px;
  margin-bottom: 32px;
}

.p9Hr9 {
  height: 48px;
}

._3Au20 {
  margin-bottom: 16px;
}

._3w-FY {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 4px;
}
._1uLy6 {
  font-size: 16px;
  line-height: 1.38;
  color: var(--greyDark);
}
