._1d2Dr {
  position: relative;
}

._2gDKR,
._3jWsX {
  position: absolute;
  background-color: #053748;
}

._2gDKR {
  bottom: 8px;
  width: 298px;
  padding: 24px;
  font-size: 13px;
  letter-spacing: 1.23;
  color: var(--white);
  border-radius: 4px;
}

._3jWsX {
  width: 16px;
  height: 16px;
  background-color: #053748;
  top: -16px;
  left: 21px;
  transform: rotate(45deg);
}

._2Qkcc {
  position: relative;
  z-index: var(--zindex-popover);
  pointer-events: none;
  font-size: 15px;
  color: var(--white);
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  opacity: 0.96;
  border-radius: 4px;
  background-color: var(--greyDark);
  margin: 4px;
  white-space: nowrap;
}
