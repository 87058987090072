.ege9w {
  background: var(--cobaltSub);
}

.CEylh {
  border-radius: 8px;
  padding: 16px;
}

._32NFo {
  border-radius: 8px;
  padding: 8px;
}

._3vjdT {
  border-radius: 4px;
  padding: 8px 4px;
}

._29eih {
  overflow: hidden;
}

._14Edn {
  display: flex;
  overflow: hidden;
  padding-bottom: 4px;
  margin-bottom: -4px;
}
