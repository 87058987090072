._11Hhv {
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  pointer-events: none;
}

._2tgJ0 {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-1000px, -1000px);
  transform-origin: left top;
  z-index: 100;
  cursor: auto;
  pointer-events: none;
}

._2tgJ0 * {
  pointer-events: auto;
}
