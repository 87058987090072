._28_ry {
}

.tZq96 {
  width: 110px;
}

._3nibd {
  width: 36px;
  padding: 0 !important;
  text-align: center;
  -moz-appearance: textfield;
}

._1Og5g {
  margin-right: 8px;
}

._3nibd::-webkit-outer-spin-button,
._3nibd::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

._1j51A {
  min-width: 110px;
}
