._37RUz {
  position: relative;
  height: 36px;
  border-radius: 6px;
  border: 2px solid transparent;
  background-color: var(--white);
  font-size: 13px;
  line-height: 0.93;
  color: #9b9b9b;
  width: 110px;
  margin-right: 4px;
  flex: none;
}

@media (min-width: 1460px) {
  ._37RUz {
    width: 107px;
  }
}

._37RUz._3-PNT {
  background-color: var(--blue);
  color: var(--white);
}

._37RUz._3B1Lt {
  color: #f25f38;
  background-color: var(--greyLight30);
}

.Qqzd4 {
  white-space: nowrap;
}

.Qqzd4:first-letter {
  text-transform: uppercase;
}

.oB8Q7 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

._3ppW9 {
  position: absolute;
  z-index: var(--zindex-popover);
  width: 178px;
  height: auto;
  top: -2px;
  left: -2px;
  overflow: visible;
  padding: 12px;
  color: var(--black);
}

._2v14X {
  font-size: 15px;
  line-height: 1.33;
  color: var(--black);
  margin: 0 0 8px;
}

._1cUgt {
  display: flex;
  margin: 0 0 8px;
}

._2KMR_ {
  font-size: 15px;
  line-height: 1.33;
  color: #979797;
}

._2oNNX {
  flex: none;
  width: 36px;
  margin: 0 8px 0 0;
}

input._2mUQq {
  text-align: center;
  padding: 0 10px;
}

._3PiZ3 {
  width: 110px;
}

._3PiZ3.Hei-G {
  width: 154px;
}

.DawbC {
  min-width: 0;
}

.TOFtl {
  margin: -36px 0;
}
