._3jfyZ {
  margin: 0 16px 20px;
  font-size: 15px;
  border: 1px solid var(--greyLight20);
  border-radius: 4px;
  padding: 12px 16px;
  z-index: 2;
}

._30yn2 {
  margin: 0 8px 12px;
  padding: 12px;
}

.k74vQ {
  width: 100%;
  line-height: 24px;
  -webkit-appearance: none;
          appearance: none;
  border: none;
  resize: none;
  background-color: transparent;
  margin-top: 12px;
}

._2mwF1 {
  animation: _2mwF1 1s infinite;
}

@keyframes _2mwF1 {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

._1xmB0 {
  padding-top: 13px;
  text-align: right;
}

.hKoBM {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-bottom: 4px;
  overflow-x: auto;
}

._3fG_p {
  margin-top: -12px;
  min-height: 116px;
  overflow: auto;
}

._2tTVm {
  height: auto;
  display: flex;
  flex-direction: column;
}

.j4RAB {
  height: 92px;
}

._3K1P2 {
  opacity: 1;
  transform: translateY(0) scale(1, 1);
}
._1TFMj {
  opacity: 0.01;
  transform: translateY(10px) scale(0.8, 0.8);
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
._3XdDa {
  opacity: 0.01;
  transform: translateY(-10px) scale(0.8, 0.8);
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

._2GICc {
  margin: 0 16px 16px;
}

._1653c {
  margin-top: 4px;
}
