._1NCvt {
  background: var(--grey);
  border-radius: 4px;
  padding: 0 4px;
  transition: opacity 0.5s;
}

._35EV2 {
  opacity: 0.5;
}
