._2rMMw {
  width: 252px;
  margin-right: 22px;
}

.epUkn {
  margin-bottom: 6px;
}

._3us5f {
  margin-bottom: 6px;
}

.MP8MX {
  font-size: 64px;
  line-height: 64px;
}

.KTytV {
  font-size: 18px;
  line-height: 24px;
}

._3so5F {
  width: 232px;
  margin-bottom: 16px;
  color: var(--greyDark);
}

._1eOWk {
  margin-bottom: 18px;
}

._2IjB3:hover {
  text-decoration: none;
}
