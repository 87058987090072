._274SQ {
  position: relative;
  padding: 20px 20px 32px 40px;
  background-color: var(--cobalt);
  border: 1px solid var(--cobalt);
  margin-bottom: 20px;
}

._596Me {
  margin-bottom: 8px;
  gap: 8px;
}

._3HXil {
  max-width: 460px;
}

._3Twyf {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.ag9IO {
  position: absolute;
  right: 58px;
  top: 0;
  height: 100%;
}

._1wcx8 {
  right: -12px;
  top: auto;
  bottom: -4px;
  height: 150px;
}

._11YQx {
  max-width: 72%;
}

@media (max-width: 700px) {
  ._274SQ {
    padding: 32px;
  }
  .QgoP7 {
    margin-bottom: 24px;
  }
  ._596Me {
    margin-bottom: 16px;
  }
}
