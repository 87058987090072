._2O9xQ {
  flex: 0 0;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  will-change: flex-basis;
  z-index: 4;
  background-color: var(--white);
}

._2O9xQ._1wmNF {
  overflow: hidden;
}

.nY-id {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
}

._1doMx {
  padding: 24px 24px 0 24px;
}

.nY-id._1wmNF {
  display: none;
}

._3cADq {
  overflow: auto;
  position: relative;
  flex: 1 1 100%;
  -ms-overflow-style: none;
}

._3cADq::-webkit-scrollbar {
  display: none;
}

._18qr4 path {
  fill: var(--white);
}

._2vinN,
._286Lm {
  position: absolute;
  top: 3px;
  right: -6px;
}

._286Lm._2ooOP circle:nth-child(2),
._2vinN._2ooOP circle:nth-child(2) {
  stroke: var(--blue);
}

._286Lm._2ooOP circle:nth-child(3) {
  fill: var(--blue);
  stroke: var(--white);
}

._175ps {
  position: relative;
  margin-right: 2px;
}

.uzTA8::after {
  content: '';
  width: 6px;
  height: 6px;
}

/* hack against outline in safari around dnd divs */
.nY-id div:focus {
  outline: none;
}

._1xoJ3 {
  margin-right: 4px;
}

.DzTWG {
  margin-left: 12px;
}

._2xPFt {
  position: relative;
  min-height: 25px;
}

.flows .gu-transit {
  display: none;
}

.flows .gu-mirror {
  background-color: #0f0f0f;
  width: 50px !important;
  min-width: 300px;
}

._3ZbaS {
  border-radius: 4px;
}

._2-iOV {
  background-color: var(--white);
  border: 1px solid var(--greyLight30);
  border-radius: 4px;
  margin: 0 2px;
}

._3ozhm {
  background-color: var(--white);
  border: 1px solid var(--greyLight30);
  border-radius: 50%;
  margin: 0 4px;
}

.yekgl {
  z-index: var(--zindex-over-content-controls);
}
