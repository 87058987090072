._1VBSQ {
  display: flex;
  background: var(--blueLight30);
  border-radius: 4px;
  padding: 10px;
  border: 1px solid var(--blueLight30);
}

._1VBSQ:hover {
  border-color: var(--blueLight);
}

._1VBSQ[data-checked='true'] {
  border-color: var(--c-accent1-normal);
}

@media (max-width: 720px) {
  ._1VBSQ {
    background-color: var(--white);
  }
}
