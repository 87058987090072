._25qxD {
  display: flex;
  flex-direction: column;
  align-items: center;
}

._3OjL- {
  margin: 56px 0 18px;
  flex: none;
}

._29yxQ {
  font-size: 23px;
  line-height: 32px;
  color: var(--black);
  margin: 0 0 23px;
  flex: none;
}

.KL7Aa {
  padding: 0 0 120px;
  width: 600px;
  height: 100%;
}

._3uN1k::after {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 120px;
  width: 100%;
  content: '';
  background-image: linear-gradient(
    to bottom,
    rgba(243, 242, 242, 0) 0%,
    var(--greyLight20) 50%,
    var(--greyLight20) 100%
  );
  z-index: var(--zindex-over-content);
  pointer-events: none;
}

._1iQsV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  height: 60px;
  padding: 0 24px;
  font-size: 15px;
  font-weight: 500;
  color: var(--black);
}

._1iQsV:hover {
  background-color: var(--blue);
  color: var(--white);
}

._3BqAo {
  display: flex;
  align-items: center;
}

._264_F {
  flex: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background: no-repeat left top;
  background-size: contain;
  margin: 0 12px 0 0;
}

._1mZo6 {
  max-width: 380px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._2bbTV {
  width: 92px;
}
