.EZdoO {
  color: #979797;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 8px;
}

._2bJJ- {
  line-height: 20px;
}
