._3kzLN {
  width: 100%;
  min-height: 100%;
  padding: 40px 20px;
  counter-reset: firstSessionBlockTitle;
}

._1ot4L {
  width: 100%;
  max-width: 880px;
}

._3Ivh3 {
  width: 100%;
  height: 100%;
}

@media (max-width: 700px) {
  ._3kzLN {
    padding: 24px 20px;
  }
}
