._2JnvD {
  margin-right: 12px;
}

._3E1oY {
  align-items: center;
  flex-direction: row;
}

._2PIpY {
  width: 100%;
}

@media (max-width: 720px) {
  ._2JnvD {
    margin-right: 0;
    margin-bottom: 12px;
  }

  ._3E1oY {
    align-items: flex-start;
    flex-direction: column;
  }
}
