._3z7pu {
  width: 100%;
  box-shadow: none;
  border: 1px solid var(--blue);
}

._3WKly {
  width: 100%;
  height: 36px;
}

._2DKyj {
  padding: 0;
  /* google has custom colors in Google Auth style guide */
  border: 1px solid #4285f4;
  background-color: #4285f4;
}

.FyvHS {
  flex-grow: 1;
  text-align: center;
}
