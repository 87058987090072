._1aYgK {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.f89b6 {
  height: 100%;
  padding: 8px;
  position: relative;
}

._10zBj {
  width: calc(100% - 30px);
  min-width: 146px;
}

._9N7X6 {
  height: 120px;
}

._3jGZQ {
  min-width: 0;
}

._17vw_ {
  line-height: 14px;
}
