.xuiOo {
  position: relative;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  overflow: hidden;
  isolation: isolate;
}

._3kOIJ {
  isolation: isolate;
}

.ukfET {
  width: 140px;
  height: 140px;
  background-color: var(--c-base-sub);
}

._3b2D6 {
  position: absolute;
  background: var(--c-accent1-normal);
  transition: top 1s ease 1s;
  transform: translateX(-50%);
  z-index: 1;
  width: 200%;
  height: 200%;
  left: 50%;
  border-radius: 40%;
  animation: _2vh3C 10s linear infinite;
}

._3b2D6._31Pih {
  background: #be5880; /* color taken from the gradient, those in the palette do not match */
}

._3b2D6._3JMQ3 {
  background: var(--c-accent2-normal);
}

._2Bqi0 {
  background: #0e68d9; /* color taken from the gradient, those in the palette do not match */
  animation: _2vh3C 9s linear infinite;
}

._2Bqi0._31Pih {
  background: #ac4792; /* color taken from the gradient, those in the palette do not match */
}

._2Bqi0._3JMQ3 {
  background: var(--c-accent2-dark);
}

._2AKtx {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

._3P3cB {
  position: absolute;
  z-index: 3;
  top: 50%;
  mix-blend-mode: overlay;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes _2vh3C {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
