._3fmhq {
  padding-bottom: 24px;
  text-align: center;
  white-space: pre-wrap;
}

._36FAp {
  margin-top: -64px;
  height: 100%;
}

._3soUX {
  text-decoration: none;
  color: #448af1;
}
