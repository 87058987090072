._3Icpq {
  background-color: var(--c-base-extralight);
  border: 1px dashed var(--grey);
  border-radius: 4px;
  padding: 32px;
  min-height: 92px;
}

._3Icpq:hover {
  border: 1px dashed var(--greyDark);
}

._2kOVv {
  display: inline-block;
}

.ZwnIC {
  display: none;
}
