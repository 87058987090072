.Eys8C {
  padding: 16px;
  background-color: var(--white);
  border: 2px solid var(--c-base-light);
  border-radius: 16px 16px 0px 0px;
  position: relative;
}

._2XaIF {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 16px;
}

._1uvqn {
  display: flex;
}

.yG5pM {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px;
  border-radius: 50%;
  background-color: var(--greyLight10);
}

._4XR4K {
  padding: 0 36px;
}

._1_aq1 {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1; /* Оверлей должен быть поверх сообщения whatsapp list */
}

._2qtjp {
  width: 100%;
}
