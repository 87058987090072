.Md1b9 {
  padding: 8px;
  padding-left: 12px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: space-between;
  width: 368px;
  border-radius: 4px;
}

.v9gbf {
  height: 20px;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: var(--white);
}
