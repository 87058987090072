._1qVFv {
    width: 100%;
}

.ayBPi{
    display: inline-block;
}

._1LgJm {
    margin: -5px 0;
}