._25a-j {
  width: 636px;
}

._1_8LP {
  padding-right: 40px;
}

._33G2u {
  text-decoration: none;
  color: var(--blue);
}

._33G2u:hover {
  text-decoration: underline;
}

._277gA {
  letter-spacing: -0.1px;
}
