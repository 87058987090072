.KmjFe {
  margin-right: 6px;
}

._29J2M {
  margin-right: 12px;
}

.rz4N- {
  display: flex;
  align-items: center;
}

._61C_7 {
  margin-left: -5px;
}

._1Ttfa {
  width: 214px;
  height: auto;
}

._1IuvH {
  width: 100%;
  height: auto;
  object-fit: contain;
}
