._3bMI0 {
  font-size: 16px;
  color: var(--black);
  cursor: pointer;
  font-weight: 500;
  -webkit-user-select: none;
          user-select: none;
}

._3Q5rJ {
  margin-left: 4px;
  transform: rotate(-90deg);
}

._3Q5rJ.qCd_0 {
  transform: rotate(0deg);
}

.Uy1L1 {
  margin-top: 16px;
}

.O0e0_ {
  height: 36px;
  border-bottom: 2px solid #dfdddd;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
}

._3PTWo {
  margin-top: 24px;
}

._3JQrs {
  width: 220px;
  flex: none;
  padding-right: 5px;
  word-break: break-word;
}

._1-PKk {
  flex-grow: 1;
}

._30hGh {
  border: none;
  background: none;
  outline: none;
  padding: 0;
}
