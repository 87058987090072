._268Pj {
  border: 2px dashed var(--greyLight10);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
}

._16J5q {
  margin: 0;
  width: 100%;
}
