._1GzG8 {
  background: var(--churn-survey-gradient);
  width: 520px;
}

._1GzG8._3sQZW {
  background: transparent;
  width: 100%;
}

._2vA6t._3sQZW {
  flex-direction: column;
  flex: auto;
  margin: 0 16px;
}

.lYzSU {
  background: #ffffff26;
  box-shadow: none;
}

.lYzSU:enabled:hover {
  background: #ffffff30;
}

._2MmcW._3sQZW {
  width: 100%;
}
