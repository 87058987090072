.vgcRF {
  height: 0;
  overflow: visible;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;
}

._2pafI {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  background-color: var(--blueLight);
  height: 4px;
}