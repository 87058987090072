._2wjNo {
  font: var(--font);
  border: none;
  background: none;
  outline: none;
  width: auto;
}

._2awG8 {
  box-shadow: inset 0px 0px 0px 2px var(--c-accent4-secondary);
  color: var(--c-accent4-secondary);
  font-weight: 500;
  background-color: var(--white) !important;
}

._2wjNo::-webkit-calendar-picker-indicator {
  background: none;
  width: 0;
}

._2wjNo::-webkit-clear-button {
  display: none;
}

.Zo6CT {
  box-shadow: none;
  background: none;
  min-width: 24px !important;
  padding: 0;
  color: var(--black);
}

.Zo6CT._2Gs-1 {
  text-decoration: underline;
}
