._38nRH {
  font-size: 15px;
  line-height: 25px;
  padding: 6px 12px 5px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.v165E {
  flex-grow: 1;
  min-height: 100%;
  -webkit-user-select: auto;
          user-select: auto;
}

.v165E._3G7wg {
  white-space: pre !important;
  height: 25px;
  overflow: hidden;
  word-wrap: normal !important;
  word-break: normal !important;
}

._3rI7k {
  pointer-events: none;
  position: absolute;
  left: 12px;
  right: 12px;
  white-space: nowrap;
  overflow: hidden;
  color: var(--grey);
}

._1IJub {
  white-space: normal;
}

.M8ydJ {
  height: 100px;
}

._2spNQ {
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}

._374bU {
  border: 1px solid var(--red);
}

._20fqL {
  width: 100%;
  display: block;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
