._2E0-4 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.qmoBG {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 198px;
  background-color: var(--greyLight10);
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  text-align: center;
}

._3ZFUt {
  padding: 0 16px 16px 16px;
  background-color: var(--greyLight20);
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

._3YxxT {
  width: 100%;
  height: 100%;
  background: no-repeat center center;
  background-color: var(--greyLight20);
  background-size: cover;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

._27CHJ {
  padding: 0 !important;
}

._3yCfE {
  position: absolute;
  top: 0;
  right: 0;
}

.h5S82 {
  font-weight: 600;
}

.pmFD2 {
  color: var(--greyDark);
}

._1xzzM {
  width: 100%;
}
