.lAUpO {
  width: 520px;
  background-color: var(--white);
}

._3CbOZ {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--white);
}

.wVwXK {
  padding: 16px 20px 20px;
  height: 100%;
}

.f5m99 {
  top: 16px;
  right: 16px;
}
