.VHR4L {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: var(--greyDark);
}

.A_S1X {
  padding-left: 44px;
  padding-top: 24px;
  margin-bottom: 28px;
}

._1Il_2 {
  flex-grow: 1;
}

._2BKlj {
  width: 100%;
}
