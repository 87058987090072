._2aXD5 {
  position: relative;
}

.PySsV {
  position: absolute;
  bottom: 4px;
  right: 12px;
}

._1IovJ {
  bottom: 0px;
  right: 0px;
}

._19sV5 {
  bottom: 4px;
  right: 4px;
  background-color: var(--cobalt);
  opacity: 0.7;
  border-radius: 10px;
  padding: 2px 4px;
}
