._2l5W6 {
  --border-radius-joint: var(--message-border-radius-joint, 4px);
  --buttons-border-color: var(--greyLight20);

  color: var(--blue);
  text-align: center;
}

._2l5W6._32cX8 {
  padding-top: 4px;
  padding-bottom: 2px;
  color: var(--black);
}

._1zkj5 {
  border-top: 1px solid var(--greyLight20);
}

._2Zc3f {
  padding: 10px;
}

._2Zc3f._32cX8 {
  padding: 9px 10px 6px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 12px;
  background: white;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

._1Nt2F {
  word-break: break-all;
  color: var(--white);
}

._2Zc3f._3AUxH {
  cursor: pointer;
}
