._3I9p6 {
  z-index: var(--zindex-user-menu);
}

._2UBaA {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: var(--red);
  --stroke-color: transparent;
}

.l0hEZ {
  padding: 8px;
}

._2ZVMT {
  cursor: default;
}

._2Ioe0 {
  width: 100%;
}

@media (max-width: 720px) {
  ._1wxAM {
    height: 40px !important;
    margin-left: 30px;
  }

  ._3I9p6 {
    bottom: 40px;
    top: unset;
    right: unset;
  }
}
