.m5sw_ {
  width: 100%;
}

@media (max-width: 720px) {
  .m5sw_ {
    margin-top: auto;
  }

  ._19bUU {
    flex: auto;
  }
}
