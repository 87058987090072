._3o_Pu {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-top: 80px;
  overflow: hidden;
}

._2vt5f {
  margin: auto;
}

.Zy4IM {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (max-width: 720px) {
  ._3o_Pu {
    padding-top: 60px;
  }

  .Zy4IM {
    top: 10px;
    right: 10px;
  }
}
