._3QtyL {
  margin-bottom: 16px;
  height: 285px;
  width: 100%;
}

._3NiQ- {
  margin-bottom: 8px;
}

._2n7Lb {
  margin-bottom: 40px;
}

.RZoG_ {
  display: flex;
  justify-content: flex-end;
}

._15FO1 {
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._3yO2q {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 450px;
  text-align: center;
}

._11PrX {
  margin-bottom: 16px;
}

._2LC6G {
  margin-bottom: 16px;
}
