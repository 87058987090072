.L0The {
  display: flex;
  justify-content: center;
  padding-top: 80px;
}

._3Bm9B {
  border-radius: 8px;
  max-width: 380px;
  width: 100%;
  background: var(--c-accent4-semilight);
  padding: 12px 16px;
  margin-bottom: -80px;
  position: relative;
}

._1lSgC {
  position: absolute;
  top: -20px;
  right: 12px;
}

@media (max-width: 768px) {
  ._1lSgC {
    width: 80px;
    height: 80px;
    top: auto;
    bottom: -44px;
  }
}
