._2CEyI {
  margin: 24px 0 6px 0 !important;
}

._3PqTE {
  padding: 1px 6px;
  background: var(--greyLight30);
  border: 1px solid var(--grey);
  border-radius: 4px;
  color: #de614d;
}

._34ItL {
  margin: 16px 0 12px;
}

._1Vhd9 {
  width: 200px;
}

._90fos {
  margin-top: 15px;
  font-size: 15px;
  line-height: 22px;
  color: var(--red);
}

._3xOgQ {
  margin-left: 8px;
}

.Ql7Eb {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.EicBu {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 600;
}

._18zr0 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
}

._127lb {
  width: 636px;
}

.-e8JE {
  padding-right: 40px;
}

._1wbNy {
  text-decoration: none;
  color: var(--blue);
}

._1wbNy:hover {
  text-decoration: underline;
}

._1q08l {
  letter-spacing: -0.1px;
}

._1sIVJ {
  margin-right: 8px;
}

._1zrS5 {
  width: 180px;
}

._2-yLn {
  width: 50px;
  text-align: center;
}

._2-yLn::-webkit-inner-spin-button,
._2-yLn::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

._2VoO9 {
  font-weight: 500;
  color: var(--black);
}
