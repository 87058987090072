._1Vvkm {
  width: 60%;
  height: 100%;
  justify-content: flex-end;
}

._3lpK1 {
  padding: 16px 8px 0px 8px;
  width: 20%;
  height: 100%;
  flex: none;
  box-sizing: border-box;
}

.eFLsK {
  margin-bottom: 4px !important;
}
