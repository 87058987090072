._2_7p_ {
  margin-top: 8px;
}

._39TVl {
  padding: 0 16px;
  border-left: 1px solid var(--blueLight20);
}

.jV3SM {
  width: 180px;
  height: 190px;
}

@media (max-width: 720px) {
  ._39TVl {
    border-left: 0;
    padding: 14px 0px 0px;
  }
}
