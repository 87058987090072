._1pZQA {
  border-radius: 4px;
  border: 1px dashed var(--c-base-semilight);
  background-color: var(--c-base-extralight);
  padding: 12px;
  height: 64px;
}

._1pZQA._1ytij {
  opacity: 0.4;
  pointer-events: none;
}

._3H2tg {
  opacity: 1 !important;
  width: 100%;
}

._1pZQA._2l7x4 {
  border-color: var(--c-accent1-normal);
}

._1pZQA._2JNKe {
  border-color: var(--redNormal);
}
