._5W6ra {
  flex: auto;
}

._31rUj {
  margin-left: 20px;
}

._1JUnZ {
  width: 100%;
}

._16lvW {
  flex: auto;
}

._24zD3 {
  padding-bottom: 32px;
}
