.euXqX {
  margin-right: 4px;
  margin-bottom: 4px;
  outline: none;
  border-radius: 3px;
  min-width: 16px;
  max-width: 196px;
  height: 28px;
  line-height: 27px;
  padding: 0 8px;
  color: var(--black);
  font-weight: normal;
  background-color: var(--grey);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.euXqX._14qoH {
  background-color: var(--blue);
  color: var(--white);
}

.euXqX._14qoH:active {
  background-color: var(--blue);
}

._17Frv {
  margin: -3px -13px 0 4px;
  display: inline-block;
  vertical-align: middle;
}
