._3t7nG {
  width: 627px;
  background-color: var(--white);
}

.KxTg2 {
  top: 25px;
  right: 32px;
}

._301p- {
  margin: 0 -40px -40px;
  overflow: hidden;
  border-bottom-right-radius: 8px;
  position: relative;
}

._4di6- {
  height: calc(100vh - 200px);
  max-height: 568px;
  padding: 0 40px 84px;
}

.AsGxk {
  padding: 0 0 8px;
}

._2WLEk {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.07;
  color: var(--black);
  margin: 0 0 16px;
}

._3Yqie {
  display: flex;
  margin: 0 -8px 24px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

._3Yqie._2mOLD {
  background-color: #f1f1f1;
}

._3Yqie.T45Sw {
  cursor: default;
}

._165yu {
  color: rgba(0, 0, 0, 0.2) !important;
}

._3Yqie._3zQDX,
._3Yqie._3zQDX:hover {
  background-color: var(--blue);
  color: #f1f1f1;
}

._3Yqie._3zQDX a {
  text-decoration: underline;
}

.IPvel {
  display: block;
  width: 180px;
  margin: 0 16px 0 0;
  line-height: normal;
}

.E8kPn {
  width: 140px;
}

.AEhY_ {
  font-size: 15px;
  font-weight: bold;
  color: var(--black);
  margin-bottom: 5px;
}

.AEhY_._21FOm {
  color: var(--white);
}

._3Ta0n {
  font-size: 11px;
  color: #979797;
  word-wrap: break-word;
}

._3Ta0n._1ZSpI {
  color: rgba(255, 255, 255, 0.5);
}

._35QIJ {
  font-size: 17px;
  line-height: 1.13;
  letter-spacing: normal;
  color: var(--black);
  margin: 0 0 8px;
}

._35QIJ._2pc9R {
  color: var(--white);
}

._3GQSa {
  font-size: 15px;
  line-height: 1.33;
  color: var(--black);
}

._3GQSa._3_hxz {
  color: rgba(255, 255, 255, 0.8);
}

._1FMvd {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 116px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    var(--white) 52%
  );
  padding: 38px 24px 0 0px;
  text-align: right;
  pointer-events: none;
}

.J5AWy {
  pointer-events: auto;
  margin-right: 20px;
}
