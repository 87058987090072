._1kMht {
  height: 36px;
  border-left: 2px solid var(--c-accent1-secondary);
  padding-left: 8px;
  width: 204px;
}

._1kMht:disabled {
  opacity: 1;
}

._1VjGS {
  width: 100%;
}

._2xqv4 {
  min-width: 0;
}

.tEkAO {
  min-width: 0;
}

._3_YFd {
  border-radius: 4px;
  border: 1px solid var(--c-base-semilight);
  width: 36px;
  height: 36px;
  object-fit: cover;
  overflow: hidden;
  flex: none;
}
