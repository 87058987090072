.PPaHB {
  position: relative;
}

._2fgJ-:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

._1j1DM {
  margin-left: auto;
}

._2k-hQ {
  width: 100%;
}

@media (max-width: 700px) {
  .PPaHB,
  ._1UNZn {
    height: 100%;
  }

  ._2k-hQ {
    margin-top: auto;
  }
}
