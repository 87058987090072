.F200l {
  background: var(--churn-survey-gradient);
  width: 520px;
}

.F200l._10ir- {
  background: transparent;
  width: 100%;
}

.U-4td._10ir- {
  flex-direction: column;
  flex: auto;
  margin: 0 16px;
}

._372lk {
  background: #ffffff26;
  box-shadow: none;
}

._372lk:enabled:hover {
  background: #ffffff30;
}

._1KxFm._10ir- {
  width: 100%;
}

._Iyi9 {
  position: relative;
}

._3Fi0P {
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 100%;
  text-align: center;
}
