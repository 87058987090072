._2bnUS {
  position: relative;
}

._2YA_S {
  position: absolute;
  bottom: -14px;
  left: -14px;
  width: auto;
  max-width: 240px;
  padding-top: 0;
  height: 134px;
  box-shadow: 0 8px 32px 0 rgba(32, 32, 32, 0.08),
    0 0 0 1px rgba(16, 16, 16, 0.08);
}

._1T9tm {
  background-color: transparent !important;
  box-shadow: none !important;
}

._2dvvf {
  height: 32px;
  width: 32px;
  min-width: 32px;
  margin-right: 4px !important;
}

.sPc2W {
  height: 24px;
  width: 24px;
  min-width: 24px;
}

._3yhG0 {
  width: 12px;
  min-width: 12px;
  padding-left: 5px;
}

._3rbfR {
  width: 26px;
  height: 26px;
  background-color: #f1f0f0;
  border-radius: 50%;
  overflow: hidden;
}

.j2en- {
  height: 100%;
}

._8aCYF {
  margin: 0 0 0 -12px;
}

div._3tIqL {
  margin: 0;
}

.Tza7R {
  height: 42px;
  flex-wrap: nowrap;
}

._2jKod {
  margin: 2px 14px 0 0;
}
