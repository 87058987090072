._1g3t3 {
  background-color: var(--c-base-normal);
}

._3apTa {
  color: var(--white);
}

._3apTa:enabled:hover {
  background-color: var(--c-base-secondary);
}

._89jrF {
  width: 100%;
}

@keyframes _2TFaM {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
