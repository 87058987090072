._1VWnj {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  cursor: pointer;
  position: relative;
  padding-right: 16px;
  text-decoration: none;
}

._1VWnj::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

._1VWnj:hover::after {
  background-color: var(--greyLight30);
}

.gpB_t {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
