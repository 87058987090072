._3fOpc {
  padding: 12px 12px;
}

._3RoaF {
  padding: 2px 12px 2px 8px;
}

._3RoaF._2Wm4X {
  border-radius: 4px;
  background-color: var(--c-accent1-light);
}

._31FMH {
  padding: 2px 12px;
}

._1C0-- {
  padding-left: 16px;
}

._2REOj {
  border-radius: 50%;
  color: var(--white) !important;
  background-color: var(--blue);
}

._2OE4L {
  border-radius: 4px;
  background-color: var(--c-accent1-normal);
  padding: 2px 4px;
}
