._3KfFA {
  margin-top: 24px;
  margin-bottom: 24px;
}

._3fghh {
  font-size: 15px;
  line-height: 20px;
}

.ZnNPy {
  text-decoration: none;
  color: #448af1;
}
