.Bv7P1 {
  padding: 8px 16px;
}

._2URvz {
  padding: 8px;
  border-radius: 4px;
  background-color: var(--antiqueWhite);
}

._1S5qs {
  height: 36px;
}

._1qCay::-webkit-outer-spin-button,
._1qCay::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
