.xbvfs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  align-items: start;
}

._1chgy {
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}

._3rjXp {
  width: 100%;
  height: 100%;
  filter: brightness(98%);
}

._2QO6j {
  width: 50%;
  height: 50%;
}

._2iGR5 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--greyLight20);
}

._1j7x9 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 13px 14px 13px;
  background-color: var(--white);
  height: 82px;
}

._3SN9f {
  background-color: var(--greyLight20);
}
