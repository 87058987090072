._1hN8- {
  display: flex;
}

._1hN8- > * {
  flex-grow: 1;
}

._1hN8- > *:not(:last-child) {
  --input-border-top-right-radius: 0;
  --input-border-bottom-right-radius: 0;

  margin-right: 1px;
  /* clip-path: inset(-2px 0px -2px -2px); */ /** remove right box-shadow "border" */
}

._1hN8- > * + * {
  --input-border-top-left-radius: 0;
  --input-border-bottom-left-radius: 0;
}
