.k1rAe {
  width: 510px;
  background: var(--white);
}

._1ZVvg {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 8px;
  color: #979797;
}

._33QCy {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

._2bjRe {
  background: var(--white);
}
