._1CDxb {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 6px 2px 6px;
  max-width: 400px;
  min-width: 35px;
  background: var(--c-base-extralight);
  border-radius: 4px;
}

._3mJiL {
  position: relative;
}

._2DCnd {
  color: var(--c-base-tertiary);
  display: flex;
  justify-content: center;
}
