._2aAFG {
  margin-right: 24px;
  display: flex;
}

.o6wpu {
  padding: 8px;
  min-height: 32px;
  min-width: 32px;
}

._2z-KJ {
  margin: 0;
}

._5T-HR {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 8px;
  margin: 0 -8px;
  border-radius: 8px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

._5T-HR:hover {
  background-color: #f1f1f1;
}

.I8mGS {
  font-size: 15px;
  line-height: 16px;
  color: var(--black);
  margin: 0 0 4px;
}

._3oGmm {
  font-size: 11px;
  line-height: 12px;
  color: #a7a7a7;
  padding: 0 0 2px;
}

._5T-HR._2TdFc {
  background-color: var(--blue);
}

._5T-HR._2TdFc .I8mGS,
._5T-HR._2TdFc ._3oGmm {
  color: var(--white);
}

._5T-HR.rcwv4 {
  pointer-events: none;
  opacity: 0.5;
}

.JJnDR {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent var(--black);
  margin: 0 0 0 8px;
  opacity: 0.2;
}

._3hIOz {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: var(--grey);
  opacity: 0.4;
}

._2Tww9 {
  color: var(--greyDark);
  text-decoration: none;
  white-space: nowrap;
}

._2Tww9:hover {
  text-decoration: underline;
}
