._2MXEv {
  margin-bottom: 40px;
}

._2hml6 {
  margin: '0 10px 0 6px';
}

._1IdnD {
  display: flex;
  justify-content: flex-end;
}
