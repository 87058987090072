._2XI_n {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

._1iLIm {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--grey);
  margin: 3px 8px 0;
}
