.RC_JQ {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 412px;
  z-index: 3;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  cursor: auto !important;
}

._3dJpB {
  position: relative;
  padding: 21px 25px 17px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: var(--white);
}

.R_z4Z {
  display: flex;
  position: absolute;
  top: 27.75px;
  right: 28.75px;
  z-index: 1;
  outline: none;
}

.R_z4Z path {
  fill: var(--black);
}

._2F2S2 {
  position: relative;
  min-width: 464px;
}

._2ooXw{
  overflow: hidden;
}

._2ooXw > div {
  padding: 0;
}
