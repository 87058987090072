._1JW3v {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 22px 24px 24px;
  border-radius: 7px;
  width: 712px;
  min-height: 88px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  background: #ffffff;
}

.OvL8B {
  margin-right: 37px;
}

._3UNnu {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 8px;
}

._3Dh8N {
  display: flex;
  height: 36px;
  width: 450px;
  flex-direction: column;
  justify-content: space-around;
}

._2t395 {
  height: 8px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--greyLight20);
  animation: _3WgbG 1000ms infinite;
}

._1jhjG {
  width: 156px;
}

@keyframes _3WgbG {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
