._2vIVH {
  margin: -8px 8px -8px 0;
}

.tpJQ5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -8px 0 20px;
}

.V0JcP {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 15px;
}

._22bs_ {
  font-size: 15px;
  line-height: 16px;
  color: var(--black);
  margin: 0 0 4px;
}

.P-7Mq {
  font-size: 11px;
  line-height: 12px;
  color: #a7a7a7;
  padding: 0 0 2px;
}

._2B7n0 {
  margin-right: 24px;
  display: flex;
}

._3iqIy {
  color: var(--greyDark);
  text-decoration: none;
  white-space: nowrap;
}

._3iqIy:hover {
  text-decoration: underline;
}

._3a8Os {
}

.kkbVX {
  height: 32px;
  padding-top: 18px;
  margin-bottom: 24px;
}

._3ep95 {
  font-size: 11px;
  line-height: 13px;
  color: #de614d;
}

._3UHPU {
  width: 600px;
}
