._29LmJ {
  height: 30px;
  border-radius: 15px;
  background-color: #eeedee;
  color: var(--black);

  display: inline-flex;
  align-items: center;

  padding: 0 16px;

  -webkit-user-select: none;

          user-select: none;
}

._29LmJ._3-3-A {
  color: var(--white);
  background-color: var(--cobalt);
}

._1WIHm {
  color: inherit !important;
}

._3K6GA {
  display: flex;
  align-items: center;

  cursor: pointer;
}

._3K6GA:hover {
  color: var(--blue);
}

._29LmJ._3-3-A ._3K6GA:hover {
  color: var(--blueLight30);
}

._2D0-g {
  margin-left: -10px;
  margin-right: 2px;
}

._39iHD {
  margin-left: 8px;
  margin-right: -4px;
  cursor: pointer;
}

._39iHD:hover {
  color: var(--blue);
}
