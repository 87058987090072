.VcQzV {
  flex: none;
  position: relative;
}

._3rbDc {
  width: 24px;
  height: 24px;
  background-color: var(--greyLight30);
  border-radius: 4px;
}

._1vBic {
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  z-index: var(--zindex-bot-picture-icon);
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
