._34oy5 {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  flex: none;
}

._1ZqJD {
  z-index: var(--zindex-modal);
  margin: 8px 0;
}

.wG4jE {
  color: #dadada;
  margin: -2px 0;
}

.BZ84l {
  max-width: 226px;
}

._1h0Zj {
  position: absolute;
  right: 0;
}
