._2Jc6M {
  display: none;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  min-height: 28px;
  z-index: var(--zindex-over-content);
}

._21R3H {
  width: 28px;
  height: 28px;
}

._2Jc6M span span {
  margin: 0;
}

._1lg2I {
  position: relative;
  margin-bottom: calc(var(--unit) * 2);
}

._1lg2I:hover ._2Jc6M {
  display: inline-flex;
}

._1lg2I:hover ._21R3H {
  display: none;
}

._3OXbY {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
}

._3OXbY > *:first-child,
._1mgVj {
  overflow: hidden;
}

._1mgVj {
  flex: none;
}

._3OXbY > *:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

._3OXbY > *:last-child,
._3OXbY > *:nth-last-child(2) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

._3ulqP {
  color: #f06040;
  box-shadow: 0 0 0 2px rgba(240, 96, 64, 0.4), 0 0 0 1px rgba(16, 16, 16, 0.08),
    0 1px 0 0 rgba(16, 16, 16, 0.04);
}

._1YEcV {
  height: 100%;
  width: 1px;
  display: inline-block;
  background-color: var(--greyLight30);
}

._36TgR {
  height: 36px;
  display: inline-flex;
  overflow: hidden;
}

._34gNZ,
._4FA-w {
  width: 122px;
}

._34gNZ {
  background-color: var(--greyLight30);
}

._34gNZ div {
  overflow: hidden;
}

.vv-1d,
._1iymN {
  height: 100%;
  background-color: var(--greyLight30);
  padding: 0 8px;
  display: flex;
  align-items: center;
  outline: none;
}

.vv-1d {
  color: var(--blue);
  text-overflow: ellipsis;
}

._19qc9 {
  text-overflow: ellipsis;
  pointer-events: all;
}

._2_5fP,
._1-BkY {
  box-shadow: none !important;
  border-radius: 0;
}

._2wT-H {
  background-color: var(--greyLight30);
  width: 80px;
}

._2u5RN {
  background-color: var(--greyLight30);
  width: 90px;
}

._3TLXm {
  background-color: var(--greyLight30);
  width: 128px;
}

._13lD8 {
  background-color: var(--greyLight30);
  width: 86px;
}

.YjaDt {
  background-color: var(--greyLight30);
  width: 64px;
}

._3bRTi {
  background-color: var(--greyLight30);
  width: 167px;
}

._3ZMDA {
  background-color: var(--greyLight30);
  width: 102px;
}

._10QMM {
  background-color: var(--greyLight30);
  width: 145px;
}

.G5wGj {
  width: 215px;
}

._3gTYP,
.OXWY8 {
  width: 42px;
}

._3gTYP div {
  overflow: hidden;
}

.OXWY8 {
  padding-left: 8px;
}

._2SgBh {
  background-color: var(--greyLight30);
  display: flex;
  align-items: center;
  padding: 6px 6px 5px;
  height: 36px;
}
