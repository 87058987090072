._36vas {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

._1PKjm {
  background: var(--red);
}

._30FPd {
  background: var(--green);
}
