._2m47L {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  height: 36px;
  position: relative;
  background-color: var(--white);
  border: solid 1px var(--white);
  border-radius: 8px;
  color: #aaaaaa;
  cursor: pointer;
  margin-right: 0 !important;
}

._2m47L:hover {
  color: #333333;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.13);
}

._2m47L[disabled] {
  opacity: 0.7;
  pointer-events: none;
}
