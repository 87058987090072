._1ZKlz {
  --border-radius: var(--message-border-radius, 18px);
  --border-radius-joint: var(--message-border-radius-joint, 4px);

  display: inline-block;
  max-width: calc(100% - 64px);
}

.VvpMs {
  background-size: cover;
  background-repeat: no-repeat;
}

._1g7X3 {
  background-color: var(--c-base-light);
}
