._1TMwt {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

._2RWZC {
  opacity: 0.3;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  display: inline-block;
  color: #3c3f40;
  text-decoration: none;
}

._2RWZC:hover {
  color: #448af1;
  opacity: 1;
}

._2ofuo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.eOt4r {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-top: 2px;
}
