._34bdE {
  text-decoration: underline;
}

._34bdE:hover {
  text-decoration: none;
}

._34bdE[disabled] {
  pointer-events: none;
  text-decoration: none;
}
