._28_L0 {
  width: 100%;
}

._1_ilA {
  flex: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--blueLight);
}

._3jZeu {
  flex-grow: 1;
  height: 2px;
  border-radius: 1px;
  background-color: var(--blueLight);
  margin: 0 16px;
}

._3l_vH {
  background-color: var(--cobalt);
}
