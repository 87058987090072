._21jbK {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--greyLight30);
}

._1tcfC {
  height: 100%;
  width: 100%;
  position: relative;
}
