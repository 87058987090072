._2_wHE {
  display: flex;
  flex-direction: column;
}

.RLvEL {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

._1K39L {
  height: 2px;
  width: 2px;
}

._3f7Rb {
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 16px;
}
