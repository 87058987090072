._37edq {
  --min-height: var(--control-decorator-min-height, 36px);
  --input-font-size-local: var(--input-font-size, 15px);

  width: 100%;
  border: none;
  border-radius: var(
      --input-border-top-left-radius,
      var(--control-decorator-border-radius)
    )
    var(--input-border-top-right-radius, var(--control-decorator-border-radius))
    var(
      --input-border-bottom-right-radius,
      var(--control-decorator-border-radius)
    )
    var(
      --input-border-bottom-left-radius,
      var(--control-decorator-border-radius)
    );
  box-sizing: border-box;
  padding: 0 0 2px;
  line-height: calc(var(--min-height) - 2px);
  font-size: var(--input-font-size-local);
  background-color: transparent;
  outline: none;
  font-family: var(--font);
}

@media (pointer: coarse) {
  ._37edq {
    /* TODO: apply to `body` or `:root`? */
    /* Prevent page zooming when inputs are focused */
    --input-font-size-local: 16px;
  }
}

._2vQWC {
  padding-left: 12px;
}

._2eJwn {
  padding-right: 12px;
}

._1pIAG {
  margin-bottom: calc(var(--unit) * 3);
}

._2sPP- {
  color: var(--grey);
}
