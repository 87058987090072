._24KnZ {
  width: 100%;
}

._2-mQS {
  min-width: 236px;
}

._2do9x {
  width: 240px;
}
