._2YJAV {
  width: 1024px;
  line-height: 22px;
  background-color: var(--white);
  --borderColor: var(--greyLight20);
  --backgroundColor: var(--greyLight30);
}

._2YJAV thead {
  background-color: var(--backgroundColor);
}

._2YJAV th {
  font-size: 15px;
  color: var(--black);
  font-weight: 500;
  height: 56px;
  padding: 0 16px;
}

table td {
  color: var(--black);
  font-size: 15px;
}

._2YJAV th:nth-child(2),
._2YJAV td:nth-child(2) {
  text-align: left;
  width: 174px;
  max-width: 174px;
  padding: 0;
}

._2YJAV th:nth-child(3) {
  text-align: left;
}

._2YJAV td:nth-child(3) {
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
}

._2YJAV td:nth-child(n + 4) {
  text-align: right;
  padding-left: 16px;
  padding-right: 16px;
}

._2YJAV th:nth-last-child(2):not(:nth-child(2)),
._2YJAV td:nth-last-child(2):not(:nth-child(2)) {
  padding-left: 16px;
  padding-right: 0;
}

._2YJAV th:last-child,
._2YJAV td:last-child {
  padding: 0 0;
}

._2YJAV
  tbody:not(._1qNU3)
  tr:last-child
  td:nth-child(n + 2):nth-last-child(n + 2) {
  border-bottom: 1px solid var(--borderColor);
}

._2YJAV tbody:hover:not(._1qNU3):not(._1sWtm) tr:last-child td {
  border-bottom: 1px solid var(--borderColor);
}

._2YJAV tbody:not(._1qNU3) {
  cursor: pointer;
}

._2YJAV tbody:hover:not(._1qNU3):not(._1sWtm) {
  background-color: var(--greyLight30);
}

._2YJAV tbody td {
  padding-top: 8px;
  padding-bottom: 0;
}

._2YJAV tbody tr:last-child td {
  padding-bottom: 8px;
}

._2YJAV tbody._1qNU3 tr td {
  padding-top: 16px;
  padding-bottom: 0;
}

._2YJAV tbody._1sWtm {
  cursor: default;
}

._2YJAV ._2yvq4 {
  border-right: 1px solid var(--borderColor);
}

._2YJAV ._3kpw_ {
  padding: 0;
  width: 24px;
}

._2YJAV .clp9s {
  overflow: hidden;
  text-overflow: ellipsis;
}

.zBGUZ {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: var(--blue);
  vertical-align: -3px;
}

._2YJAV td.QQHNW {
  white-space: normal;
  text-align: left;
  padding-left: 12px !important;
}

._2YJAV td.QQHNW._1KzD0,
._2YJAV td.QQHNW._1KzD0 .zBGUZ {
  color: var(--red);
}

._1eoS6,
._37Jtb {
  display: flex;
  align-items: center;
}

._1eoS6:before,
._37Jtb:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 6px;
}

._37Jtb:before {
  background-color: var(--green);
}

._1eoS6:before {
  background-color: var(--red);
}

._1CsL1 {
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 126px;
}

._1CsL1:hover {
  text-decoration: none;
}

._1XOA5 {
  pointer-events: none;
}

._1XOA5 td {
  height: 72px;
}

._2gYzv {
  height: 13px;
  border-radius: 3px;
  padding: 0 3px;
  display: flex;
  align-items: center;
  vertical-align: center;
  margin-right: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: var(--white);
  text-transform: uppercase;
}

._2gYzv._3XhJX {
  background-color: var(--blue);
}

._2gYzv._1vlKo {
  background-color: var(--blueLight);
}

._3Ybxz {
  display: inline-block;
  white-space: nowrap;
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: -7px;
}

._3xeEP {
  display: inline-block;
  white-space: nowrap;
  max-width: 148px;
  overflow: hidden;
  text-overflow: ellipsis;
}

._18XnO {
  pointer-events: auto;
  text-decoration: underline;
  display: inline;
}

._18XnO:hover {
  text-decoration: none;
}

.xenGu {
  padding: 16px 16px 0;
  margin-bottom: -8px;
}

.zBGUZ._1az0S {
  color: var(--grey);
  margin-top: 4px;
}

.kW-jL {
  text-align: center !important;
}

._2-bKF {
  background: white;
  padding: 42px 0 24px;
}
