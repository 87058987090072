.jhtpJ {
  width: 100%;
}

._9Y9zo {
  background: var(--blueLight30);
  width: 357px;
  height: 160px;
  border-radius: 8px;
}

._3zmIY {
  width: 100%;
}

.IlXyO {
  padding: 0 16px 48px;
  width: 100%;
}
