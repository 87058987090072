._13nuz {
  height: 370px;
}

.jJaq7 {
  width: 240px;
}

._3E1y4 {
  margin: 10px;
}

/*
 * Костыль.
 * Гугл карта рисуется быстрее чем инпут из реакт лаяута скопируется в лаяут карты
 * и получается моргание. В документации у них тоже самое
 * https://developers.google.com/maps/documentation/javascript/examples/places-searchbox
 * как фиксить не очень понятно.
 */
._1JOb8 {
  position: absolute;
  top: 56px;
  left: 20px;
  z-index: 100;
}

.pac-container {
  /* Автокомплит гугл карт должен быть выше чем модалка */
  z-index: 2147483644;
}
