._3FvDj path {
  fill: #3b5998;
  background: linear-gradient(238.9deg, #6358de 1.39%, #208ef0 99.06%);
  border-radius: 6px;
}

._3mGeV {
  position: absolute;
  left: 0;
  bottom: 16px;

  height: 36px;
  width: 520px;
}

._3jhk7 {
  min-width: 160px !important;
}

._3bz0y {
  width: 100%;
  margin-top: 12px;
}

._2x43S {
  width: 364px;
}

._369u0 {
  height: 264px;
}

._326Qk {
  width: 460px;
}
