.XI27s {
  cursor: pointer;
  padding: 6px 16px;
}

.XI27s:hover {
  background: var(--greyLight20);
  border-radius: 4px;
}

._3yV1P {
  position: relative;
  width: 16px;
  height: 16px;
}

/* Через display: none не работает, тк почему то меняются иконки мессенджера у темплейт айтемов при наведении */
._3yV1P:hover > ._37Yoi {
  visibility: visible;
}

._3yV1P:hover > ._2CQd5 {
  visibility: hidden;
}

._37Yoi {
  position: absolute;
  visibility: hidden;
}

._2CQd5 {
  position: absolute;
}
