._2NVq4 {
  max-width: 354px;
}

._1p8NY {
  width: 456px;
}

._3KWgX {
  width: 100%;
}
