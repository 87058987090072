._1HX7Y {
  border-radius: 4px;
}

._1HX7Y > * {
  flex-grow: 1;
}

/** gap */
._1HX7Y > *:not(:first-child) {
  margin-left: 1px;
}

._2JLrM {
  background-color: var(--white);
}

.mFm8T {
  background-color: #f1f1f1;
}

._2MTvm {
  background-color: var(--greyLight30);
}
