._3SJnQ {
  height: 24px;
}

._1mf-u {
  display: block;
  height: 24px;
  padding: 4px 10px;
  box-sizing: border-box;
  border-radius: 100px;
  white-space: pre;
}

._1PS5D {
  background-color: var(--violet);
}

.pIWCj {
  background-color: var(--c-accent2-normal);
}
