.mY0-X {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 15px;
}

.Eii4c + .Eii4c {
  margin-top: calc(6 * var(--grid-unit, 4px));
}

._395fE {
  margin-top: calc(10 * var(--grid-unit, 4px));
  text-align: right;
}

._1zh-N {
  margin-top: calc(10 * var(--grid-unit, 4px));
  text-align: right;
}

.focus-not-visible .jOg0p:focus {
  outline: none;
}
