.Z85sG {
  width: 100%;
  background-color: var(--cobaltExtralight);
  border-radius: 4px;
  padding-top: 1px;
}

._2rWIv {
  box-shadow: inset 0 0 0 2 var(--c-accent4-secondary);
  background-color: var(--white) !important;
}

._2pwJM {
  position: relative;
}

._3gEGF {
  z-index: 1;
}
