.uGEUc {
  height: 52px;
}

.JtFLM {
  padding: 16px 8px;
}

._3w3on {
  background: #626e83;
  border-radius: 50%;
}

.LssST {
  margin: 0 7px;
}

._2NbK1 {
  background: #626e83;
  border-radius: 2px;
}
