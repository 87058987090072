._1JAnL {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.9;
  z-index: var(--zindex-over-content);
}
