._1_VUJ {
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
  width: 150px;
}

._33igr {
  flex-shrink: 0;
}
