._3w6K7 {
  background-color: var(--cobaltExtralight);
  border-radius: 4px;
}

._1z77w {
  background-color: var(--cobaltExtralight) !important;
  border-radius: 4px;
  box-shadow: unset !important;
}

._31WD8 {
  padding: 14px 12px;
}

._2oNxP {
  padding: 0 16px 16px 16px;
}
