._2Oqga {
  display: flex;
  overflow: auto;
}

._2Oqga::-webkit-scrollbar {
  display: none;
}

._2Oqga {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.EpDZ3 {
  padding-right: 32px; /* calculated in MessagesView */
}

._2Oqga > * {
  flex-shrink: 0;
  margin-right: 8px;
  overflow: auto;
}

._22T7G {
  padding: 10px 14px;
}

.C-z96 {
  padding: 6px 10px 10px 10px;
}

._1DhMi {
  background-color: var(--greyLight30);
  border: none;
  width: 194px;
}

._2g33l {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.onDC5 {
  display: block;
  width: 194px;
  height: 149px;
  background-color: #22aeda;
}

._2jWwQ {
  font-size: 11px;
  color: var(--black);
  text-decoration: none;
}

._2jWwQ:hover {
  text-decoration: underline;
}
