.OW5g2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  max-width: 300px;
}

.OW5g2:focus {
  outline: none;
}

._4QlEC {
  min-height: 20px;
}

._1F9kW {
  flex: none;
}
