.xGWVA {
  position: relative;
}

._2CBL3 {
  width: 20px;
  height: 20px;
}

._2zhEf {
  position: absolute;
  bottom: 10px;
  /* half of avatar's size + half of icon's size */
  right: calc(10px + 8px);
}
