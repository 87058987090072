._3_d-k {
  width: 100%;
}

._3OBnY {
  padding: 16px;
}

._22nlU {
  margin: 0;
}
