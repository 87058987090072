.cQ9ZJ {
  display: flex;
  align-items: normal;
  flex-grow: 2;
  width: 100%;
}

._1Onc2 {
  flex-grow: 1;
}
