.tpj0O {
  width: 536px;
  height: auto;
  border-radius: 6px;
  background-color: var(--white);
  padding: 36px 40px;
  text-align: left;
}

._2YUTu {
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: var(--black);
  padding: 0;
  margin: 0 0 16px;
}

._2v7ZR {
  font-size: 16px;
  color: #535353;
  font-weight: 300;
  line-height: 21px;
  margin: 0 0 24px;
}

._1n3xe {
  position: relative;
  margin-bottom: 40px;
}

._25FxR {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0 0;
}

._1DOAv {
  display: block;
  color: var(--black);
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

._2iCOB::after {
  content: '*';
  position: relative;
  font-size: 15px;
  line-height: 20px;
  color: #d96852;
  top: -4px;
  right: -1px;
  width: 7px;
}

._1BHNi {
  display: block;
  position: absolute;
  bottom: -22px;
  color: #e9604a;
  font-size: 12px;
  line-height: 16px;
}

.WCsKx {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.WCsKx > div {
  margin-right: 24px;
}
