.bots-list {
  display: block;
  flex-direction: column;
  position: relative;
  margin: 0 auto 100px;
  padding: 0;
  min-width: 1024px !important;
  max-width: 1480px !important;
  /* 100% - (height of the searchbar) - (margin-top of the searchbar) - (height of the header) */
  height: calc(100% - 36px - 32px - 64px);
  width: calc(100% - 224px) !important;
}

._2oxKc {
  margin-top: 32px;
}
