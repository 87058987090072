._2Reh5 {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-mini-onboarding-overlay);
  background-color: #efefef; /* fill color in svg images */
}

._36jcu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: min(100%, 1120px);
  background-repeat: no-repeat;
  background-position: top left;
}

._2p_Kq {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--overlay);
}

._2nuDl {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  max-width: 624px;
}

@media (max-width: 720px) {
  ._2nuDl {
    padding: 0 16px;
    max-width: 100%;
  }

  ._1li8y {
    width: 240px;
  }
}
