._3JXjE {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--greyLight20) no-repeat center center;
  background-size: cover;
  flex-shrink: 0;
}

._1hD5a {
  background-image: url(/src/pages/LiveChat/components/ManageSubscriptions/assets/iMF6w.png);
  background-size: cover;
}

._1YtYV {
  margin-left: auto;
}

._1j6Dn {
  margin-bottom: auto;
}

.iuhEb {
  padding: 12px 2px;
}

._1Yx19 {
  flex: auto;
  position: relative;
  left: -24px;
  right: -24px;
  width: calc(100% + 48px);
  padding: 0 24px;
}

._1T1iN {
  width: 100%;
}

._2blIz {
  height: 100%;
  width: 504px;
}

._2blIz._2_jwM {
  width: 100%;
}

._1LcEO {
  height: 100%;
  display: flex;
  flex-direction: column;
}
