._3njPU {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

._2up8M {
  font-size: 18px;
  line-height: 45px;
  font-weight: 600;
  text-align: center;
}

._1gXmJ,
._1WXvO {
  vertical-align: text-bottom;
}
