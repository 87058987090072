._3c_2M {
  padding: 44px 64px;
}

._2-7kC {
  width: 100%;
}

@media (max-width: 720px) {
  ._3c_2M {
    padding: 20px 32px;
  }
}
