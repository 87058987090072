._2do_F {
  background: var(--c-base-extralight);
  padding: 12px;
  border-radius: 8px;
}

._3vjKs {
  resize: vertical;
  min-height: 100%;
  border: 0;
  max-height: 290px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
}

._3zwMc {
  margin-top: 12px;
  flex-grow: 1;
  overflow: hidden;
}

._3vdOC {
  margin-top: 8px;
}
