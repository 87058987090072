._32v-k path {
  fill: #3b5998;
  background: linear-gradient(238.9deg, #6358de 1.39%, #208ef0 99.06%);
  border-radius: 6px;
}

._2JmGO {
  height: 264px;
}

._1Dojz {
  width: 100%;
  margin-bottom: 6px;
}
