.vzmB4 {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  flex: 1 1 auto;
  min-height: 0;
  overflow: auto;
  padding: 16px;
  height: 482px;
  /* justify-content: flex-end; DO NOT USE: breaks scrolling */
}

.vzmB4 > :first-child {
  /* use !important to prevent breakage from child margin settings */
  margin-top: auto !important;
}

._1woul > * {
  justify-content: flex-end;
}

._2cTW- > * {
  justify-content: flex-start;
}

._3dqUu {
  --gap: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 32px;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
}

._3dqUu > * {
  margin: var(--gap) 0 0 var(--gap);
}

._3_jk- {
  flex: 0 0 auto;
  margin-right: 8px;
}

._1Qd6V {
  flex: 0 1 auto;
  overflow-x: auto;
}

._1Qd6V > * {
  overflow-x: auto;
}

._11jxt,
._1BFTJ,
._1mmBI {
  border-radius: 16px;
}

.UuNQ5 {
  height: 100%;
}

.Blos0 {
  width: 220px;
}

._1hsck {
  padding: 8px 12px 12px 12px;
}

._2dEkH {
  position: relative;
  flex: auto;
  display: flex;
  flex-direction: column;
}
