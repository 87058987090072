._3aRr1 {
  width: 100%;
  min-height: 100%;
  padding: 40px 20px;
}

.cm2d1 {
  width: 100%;
  max-width: 880px;
  height: 100%;
}

@media (max-width: 700px) {
  ._3aRr1 {
    padding: 24px 20px;
  }
}
