._2mMyd {
  margin: 0;
  color: #979797;
  font-weight: normal;
  margin-bottom: 0.5em;
}

._2-qrj {
  padding-right: 8px;
  word-wrap: break-word;
  word-break: break-word;
}

._35isn {
  max-width: 100%;
  margin-left: 16px;
}
