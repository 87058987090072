.XNrBs {
  padding: 36px 40px 40px;
  background-color: var(--white);
  border-radius: 12px;
}

@media (max-width: 830px) {
  .XNrBs {
    padding: 32px;
  }
}
