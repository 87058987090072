._2May1 {
  background: var(--c-base-extralight);
  padding: 10px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

._1W0zn {
  resize: vertical;
  min-height: 100%;
  border: 0;
  max-height: 290px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
}

._3FhPS {
  flex-grow: 1;
  overflow: hidden;
}

._199F3 {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

._2Dv9F {
  flex-basis: 60px;
}

._378GQ:hover {
  border: none;
}
