.DayPicker {
  border-radius: 4px;
}

.CalendarMonth_table {
  border-collapse: separate;
  border-spacing: 10px 12px;
}

.CalendarMonth_caption {
  font: var(--font);
  font-weight: normal;
}

.CalendarDay__default {
  border: none;
  font: var(--font);
  height: 28px !important;
  width: 28px !important;
  position: relative;
  border-radius: 4px
}

.CalendarDay__selected_span {
  background-color: rgba(32, 142, 240, 0.2);
  border: none;
  color: var(--blue);
}

.CalendarDay__hovered_span:not(:hover):after,
.CalendarDay__selected_start:after,
.CalendarDay__selected_span:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 5px;
  background-color: rgba(32, 142, 240, 0.2);
}

.CalendarDay__hovered_span:before,
.CalendarDay__selected_end:before,
.CalendarDay__selected_span:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5px;
  width: 5px;
  background-color: rgba(32, 142, 240, 0.2);
}

.CalendarDay__selected,
.CalendarDay__selected_span:hover,
.CalendarDay__default:hover {
  background-color: var(--blue);
  color: white;
  border: none;
  border-radius: 4px;
}

.CalendarDay__selected_start {
  background-color: var(--blue);
  color: white;
  border-radius: 4px 0 0 4px;
  position: relative;
}

.CalendarDay__selected_end {
  background-color: var(--blue);
  color: white;
  border-radius: 0 4px 4px 0;
}

.CalendarDay__selected:hover {
  background-color: var(--blue);
  border: none;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background-color: rgba(32, 142, 240, 0.2);
  color: var(--blue);
  border: none;
}

.CalendarDay__hovered_span:hover {
  border-radius: 0 4px 4px 0;
}

.CalendarDay__selected_start.CalendarDay__selected_end::after {
  display: none;
}

.CalendarDay__selected_start.CalendarDay__selected_end::before {
  display: none;
}

.CalendarDay__selected_start.CalendarDay__selected_end {
  border-radius: 4px;
}

.CalendarDay__blocked_calendar{
  pointer-events: none;
  background-color: var(--greyLight10);
}
