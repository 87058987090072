._2Uo9n {
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 rgba(32, 32, 32, 0.08),
    0 0 0 1px rgba(16, 16, 16, 0.04);
  background-color: var(--greyLight30);
  z-index: var(--zindex-modal);
  min-width: unset;
  margin: 3px 0;
}

._3G7qo {
  padding: 4px 0;
}

._2Cd81 {
  max-height: 150px;
}

.fOqBG {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: -2px 0 0;
  line-height: 1.2;
}

._2x04c {
  display: none;
}
