._1ZFhS {
  gap: 16px;
}

._3vNNO {
  margin: 0 0 18px;
  position: relative;
}

._1GzwI {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 24px;
}

.CAu5g {
  height: 300px;
}

._3f31g {
  min-width: 120px;
}

._3qkvt {
  display: inline-block;
  vertical-align: -3px;
  margin-left: 2px;
}

._31EpM {
  white-space: nowrap;
}

.x9Sma {
  width: 32px;
  height: 32px;
}
