._48pDG {
  width: 478px;
  flex: none;
}

._3_vmF {
  background-color: var(--white);
  padding: 12px 20px 20px;
  border-radius: 4px;
}

.z-eb5 {
  margin: -4px -12px;
  flex: none;
  width: 460px;
}

.vTxws {
  height: 36px;
}

._34ApN {
  margin-left: -4px;
}
