.cKPgl {
  padding: 8px;
  background-color: var(--cobalt);
  gap: 8px;
  position: relative;
}

.KJpMb {
  flex: auto;
  padding: 6px 0;
}

.o_7eO {
  margin-left: auto;
  transition: opacity 300ms ease-in-out;
}

@media (max-width: 720px) {
  .cKPgl {
    width: 100%;
  }
}
