._9xNbi {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 16px;
}

._1gcfp {
  display: block;
  width: 100%;
  height: 100%;
}

._2QPQY {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 8px 0 0;
}

._3faqB {
  font-size: 18px;
  line-height: 24px;
  color: var(--black);
  font-weight: 600;
}

._1454G {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  color: #c2c7cc;
}

._1454G:hover {
  color: var(--black);
}
