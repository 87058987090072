._3soND {
  position: relative;
}

._3edcS {
  width: 100%;
  height: 36px;
  padding: 0 12px 2px;
  border: 1px solid rgba(16, 16, 16, 0.08);
  border-radius: 4px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

._2Ki0U {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vT0T2 {
  transform: rotateX(180deg);
}
