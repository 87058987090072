._1M0YR {
  background-color: var(--blueLight30);
  border-radius: 4px;
  padding: 16px;
}

.OEO9R {
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;
}

._2aMsa {
  width: fit-content;
}
