._1nqBF {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  position: relative;
}

._3sp6M {
  height: 100%;
  overflow-y: auto;
  padding-top: 10px;
}
