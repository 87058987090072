._1vTNY {
  flex: 1 1 100%;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1088px;
  padding: 0 32px;
}

._2oaEe {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

._25D1r {
  width: 100%;
  padding: 32px;
}

.oqrE5 {
  flex: 1 1 100%;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 874px;
}

._2VsHC {
  position: relative;
  width: 100%;
}

._2-pGp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 720px) {
  ._25D1r {
    padding: 20px;
  }
}
