._1stMO {
  width: 100%;
}

._1stMO:not(:last-of-type) {
  margin-bottom: 16px;
}

.lgvZ_ {
  font-weight: 600;
  color: var(--black);
}
