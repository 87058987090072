._2bhjS {
    cursor: text;
    width: 242px;
}

._1FcjZ{
    width: 242px;
}

._2bMm5 {
    margin: -6px 0;
}

._2U6z7 {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}
