.Rgywn {
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid transparent;
}

._3UZH2,
.Rgywn:hover {
  border: 1px solid var(--greyLight10);
  box-shadow: 0px 0px 0px rgba(16, 16, 16, 0.04),
    0px 8px 32px rgba(32, 32, 32, 0.08);
}
