._18rII {
  margin-right: 32px;
}

.rdij7 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 118px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 40px;
  pointer-events: none;
}

._3B0CN {
  pointer-events: auto;
}

.nSCpS {
  flex: none;
  margin-right: 12px;
}

.nSCpS span {
  margin: -5px;
}

._3XoYB {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 2px solid transparent;
  background: url(/src/modern-components/dialogs/ChooseItemFromListDialog/images/3UZ6B.svg) no-repeat center center;
  background-size: cover;
  overflow: hidden;
  flex: none;
  margin-right: 12px;
}

._1pg0G ._3XoYB {
  border: 2px solid var(--blue);
}

._2v6Dt {
  width: 100%;
  padding: 4px;
  border-radius: 4px;
}

._1pg0G._2v6Dt,
._2v6Dt:hover,
._2v6Dt:focus {
  background-color: var(--greyLight20);
}

._2mAeP {
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -2px;
}

._3ekmi > div::-webkit-scrollbar {
  display: none;
}

._3ekmi > div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

._33jgS {
  background-color: var(--white);
}

._1CUcq {
  display: block;
  width: 214px;
  height: auto;
}

.vXkg3 {
  width: 198px;
  max-height: 34px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black);
}

._3fF19 {
  color: var(--white);
  text-decoration: underline;
}

.QHf29 {
  opacity: 0.4;
}

._1YFta {
  padding: 10px 16px;
  background-color: var(--blueAlice);
  border-radius: 4px;
}
