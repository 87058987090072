._2Vn8d {
  width: 100%;
  padding: 4px 0;
}

.jinX- {
  border: none;
  height: 36px;
  width: 100%;
  line-height: 36px;
  border-radius: 4px;
}
