._1OCCt {
  padding: 0;
  height: 260px;
}

._2hqPO {
  padding: 0 4px 24px;
  white-space: nowrap;
}

._1c9-v {
  height: 48px;
  margin-bottom: 24px;
}

._2xUvy {
  height: 260px;
  padding-right: 8px;
}

.gY0ko {
  min-width: 124px !important;
  margin-left: 16px;
}

._3YAGO {
  width: 354px;
}

.EYRgb {
  width: 100%;
  padding-right: 4px;
}
