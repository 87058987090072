._2KhOM {
  position: relative;
  overflow: hidden;
  height: 8px;
}

._3LIBY {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: transform 200ms ease-in-out;
}

._3Qdk- {
  border-radius: 50%;
  background-color: var(--grey);
  transition: width 200ms ease-in-out, height 200ms ease-in-out;
}

.f3crQ ._3Qdk- {
  background-color: var(--grey);
}

.f3crQ:hover ._3Qdk- {
  background-color: var(--black);
}

._3EdUC ._3Qdk- {
  background-color: var(--blue);
}
