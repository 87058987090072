._1goP4 {
  position: relative;
  margin: auto;
  height: 600px;
  min-height: 550px;
  width: 553px;
  background-color: var(--white);
  border: 1px solid #e8e6e6;
  border-radius: 6px;
}

.ejorJ {
  padding: 32px;
  padding-bottom: 24px;
}

._1Mijj {
  max-height: 382px;
  padding: 0 32px;
}

._1T6nL {
  display: flex;
  height: 44px;
  align-items: center;
}

.Z6fey {
  max-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 44px;
  overflow: hidden;
}

._1RoZg {
  width: 210px !important;
}

._1vIDj {
  width: 154px !important;
}

._1RoZg,
._1vIDj {
  height: 8px;
}

._3M63V {
  display: flex;
  margin-left: auto;
  flex-shrink: 0;
}

._3M63V button {
  margin-left: 16px;
}

.k5ijA {
  position: relative;
  display: flex;
  align-items: center;
}

._36CTs {
  display: flex;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

._6NCjF path {
  fill: var(--red);
}

._1rCw2 {
  opacity: 0.4;
  width: 71px !important;
}

._2seAL {
  width: 81px !important;
}

._2ntm9 {
  color: var(--greyDark);
  border-bottom: 1px solid var(--greyDark);
}

._2ntm9:hover {
  text-decoration: none;
}

._3QQBj {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background: no-repeat center center;
  background-color: var(--greyLight20);
  background-size: cover;
  flex-shrink: 0;
}

._22Sbj {
  background-image: url(/src/pages/SharedFlow/SharedFlowChooseBot/assets/iMF6w.png);
  background-size: cover;
}

._2ykVv {
  background-image: url(/src/pages/SharedFlow/SharedFlowChooseBot/assets/3lVxu.png);
  background-size: cover;
}

._3DFft {
  height: 68px;
  border-top: 1px solid #e8e6e6;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 32px;
}

._1lARc {
  margin-left: auto;
}

@media only screen and (max-width: 600px) {
  .ZbtlN {
    display: none;
  }

  ._1goP4 {
    width: 360px;
  }

  .ejorJ {
    padding: 32px 16px 24px;
  }

  ._1Mijj {
    padding: 0 16px;
  }

  .Z6fey {
    max-width: 123px;
  }

  ._1RoZg {
    width: 93px !important;
  }

  ._1vIDj {
    width: 56px !important;
  }

  ._3DFft {
    padding: 0 16px;
  }
}
