._20Utx {
  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: calc(var(--unit) * 2);
  align-items: center;
}

._1vzg5 {
  position: absolute;
  left: 20px;
  top: -5px;
  width: 16px;
  height: 16px;
  color: var(--red);
  --stroke-color: var(--white);
}
