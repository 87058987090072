._3xFDZ {
  display: flex;
  align-items: center;
  justify-content: center;
}

._2I16V {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: -3px;
}

._6LfFd {
  pointer-events: all;
}

._3FWFu {
  margin-top: 8px;
}

._1cf35 {
  margin-left: 48px;
}
