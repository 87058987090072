._3n3ur path {
  fill: var(--white);
}

._2oIvX {
  color: var(--white);
  font-size: 12px;
  text-decoration: underline;
}

._2oIvX:hover {
  text-decoration: underline;
}
