.CWv1k > div {
  background-color: rgba(0, 0, 0, 0.3);
}

._2nTyK > *,
.CWv1k > div > * {
  color: var(--white) !important;
}

._1wFkV {
  position: absolute;
  background-color: var(--cobalt);
  width: 104px;
  border-radius: 4px;
  margin-left: 1px;
  padding: 10px 0;
}

._33Xx0 {
  left: 64px !important;
  z-index: var(--zindex-left-navigation);
}

.uBo1W {
  position: relative; /* Для счетчика у LiveChat иконки и для new лейблов */
  text-decoration: none;
}

.uBo1W:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.uBo1W._1ZfKh {
  pointer-events: none;
}

.uBo1W._1ZfKh > *,
.uBo1W._1ZfKh a > div > span {
  color: var(--c-base-secondary) !important;
}

._2giM8:hover > *,
.uBo1W:hover a > div > span {
  /* TODO: добавить всем вложенным элементам noColor и убрать сложные вложенные селекторы и селекторы с important */
  /* Надо перебить цвет у Type, потому что он задан у style и имеет больше вес селектора */
  color: var(--white) !important;
}

._3k_mP .uBo1W:hover {
  background-color: unset;
}

._2giM8 {
  min-height: 32px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

._2giM8:hover {
  background-color: var(--blue);
}

._1Np0w {
  box-sizing: border-box;
  margin-right: 4px;
  display: block;
  width: 24px;
  height: 14px;
  font-size: 9px;
  line-height: 10px;
  padding: 2px;
  font-weight: bold;
}

._1HNTC {
  position: absolute;
  top: 12px;
  left: 38px;
}

.VRssO {
  list-style-type: none;
}

._3fGMD {
  position: relative;
}

._3fGMD:hover > div {
  display: block;
}

._1LRS9 {
  position: absolute;
  top: 9px;
  right: 14px;
  box-sizing: border-box;
}
