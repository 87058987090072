._3TRcm {
  resize: vertical !important;
  height: auto;
  word-wrap: break-word;
  word-break: break-all;
}

._3WO0z {
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 12px;
  justify-content: space-between;
}

._21t3f {
  width: 165px;
}

.IEbjf {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  padding-right: 12px;
  margin-top: 16px;
}

.IEbjf svg {
  width: 20px;
  height: 16px;
  margin-right: 10px;
  margin-top: 4px;
}

.c7LOh {
  font-size: 15px;
  line-height: 22px;
}
