._1FlZZ {
  display: flex;
  justify-content: flex-start;
  padding: 40px 40px 36px;
  width: 702px;
  min-height: 246px;
  border: 1px solid var(--greyLight20);
  border-radius: 6px;
}

.xP2Gu {
  margin-right: 16px;
}

._20tf4 {
  background-color: var(--red);
  color: var(--white);
}

._20tf4 ._31zv_ {
  color: var(--white);
}

._3LxFr {
  cursor: auto;
}

._3N8gq {
  font-size: 43px;
}

._31zv_ {
  font-size: 44px;
  line-height: 48px;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
  color: var(--blue);
  font-weight: bold;
}

._2DORu {
  font-size: 16px;
  line-height: 22px;
}

.Lwcs1 {
  font-size: 16px;
  line-height: 22px;
  color: inherit;
  text-decoration: underline;
  text-decoration-color: gba(0, 0, 0, 0.2);
}

.Lwcs1:hover {
  text-decoration: none;
}

._3VjmB {
  display: flex;
}

._1cOpe {
  margin-right: 32px;
  margin-top: 8px;
}

.cMsgp {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 8px;
  cursor: default;
  line-height: 24px;
  letter-spacing: 0.5px;
  height: 25px;
}

._2TBBN {
  padding-bottom: 4px;
  letter-spacing: 0.3px;
}

._2DKsM {
  margin-right: 8px;
  margin-bottom: 8px;
  width: 21px;
  height: 20px;
}

._12LuT {
  text-decoration: underline;
  text-decoration-color: var(--greyDark);
}

._12LuT:hover {
  text-decoration: none;
}
