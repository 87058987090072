._2bgkI {
  display: flex;
  align-items: flex-start;
  position: relative;
}

._28g4h {
  position: absolute;
}

._1O5KC {
  padding-left: 20px;
}

._2VVBu {
  display: inline;
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 14px;
  line-height: 18px;
  min-width: 250px;
  max-width: 320px;
}

._15e1F {
  display: inline;
  color: white;
  text-decoration: underline;
  line-height: 18px;
  position: relative;
  top: -1px;

  /** reset defaults: */
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
          appearance: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
}
