._39iUz {
  display: flex;
}

._2gmTr {
  height: 64px;
  line-height: 64px;
  margin: 0 7px;
  padding: 0 8px;
  color: var(--black);
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(233, 96, 74, 0);
}

._2gmTr:hover {
  color: #e9604a;
}

._2gmTr._2T1sc {
  border-bottom-color: #e9604a;
}
