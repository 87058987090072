._1ZvSk {
  padding: 20px 24px;
  border-radius: 4px;
  background-color: var(--c-base-dark);
}

._15Edj {
  margin-right: auto;
  background-color: var(--c-base-sub);
}

.qmCjw {
  color: var(--c-accent4-secondary);
}

._15Edj:enabled:hover,
._15Edj:enabled:focus {
  background-color: var(--c-base-secondary);
}

@media (max-width: 720px) {
  ._15Edj {
    margin-right: 0;
  }
}
