._36o0B {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blueLight30);
}

._1qxdk {
  position: relative;
  margin: 0;
  width: 100%;
}

._3LBxW {
  position: absolute;
  top: 8px;
  right: 8px;
}
