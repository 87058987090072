._3YMCG {
  width: 100%;
}

._1QYi0 {
  box-shadow: 0 0 0 2px rgba(240, 96, 64, 0.4), 0 0 0 1px rgba(16, 16, 16, 0.08),
    0 1px 0 0 rgba(16, 16, 16, 0.04) !important;
  color: #f06040 !important;
}

._366C0 {
  width: 306px;
}
