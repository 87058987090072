._2sohp {
  width: 100%;
  min-width: 0;
}

._28R94 > div {
  width: 270px;
  padding: 12px 16px 0;
  flex: none;
}

.ROfqv > div {
  width: 254px;
}

.ROfqv > div:first-child {
  padding-right: 12px;
}

.ROfqv > div:last-child {
  padding-left: 12px;
}

._2kgjV {
  position: relative;
}

._2kgjV:after {
  content: '';
  position: absolute;
  left: 270px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.08);
}

._2kgjV._3zHdX:after {
  content: none;
}

._2EvT_ {
  display: inline-block;
  outline: none;
  border-radius: 3px;
  min-width: 16px;
  max-width: 196px;
  height: 25px;
  line-height: 24px;
  padding: 0 8px;
  color: var(--black);
  font-weight: normal;
  background-color: var(--grey);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  vertical-align: -8px;
  margin-bottom: 2px;
  margin-right: 2px;
}

._1a3NV {
  white-space: nowrap;
  display: inline;
}

._1eBWs {
  max-width: 400px;
}

._1gzom {
  width: 100%;
  text-align: left;
}

@media (max-width: 830px) {
  ._29eTN {
    width: 230px;
  }
}
