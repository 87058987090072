._2f9f7 {
  height: 8px;
  border-radius: 4px;
}

._3M5CS {
  animation: _1qdcu 1000ms infinite;
}

@keyframes _1qdcu {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
