._2BQ0C {
  height: 300px;
}

._3WWJX {
  position: absolute;
  bottom: 16px;
  width: 100%;
}

._3_dWZ {
  flex-direction: column;
  margin-top: 12px;
}

._2YkNr {
  margin-left: 4px;
}

._2nAZp {
  margin-top: 6px;
}

._3wln6 {
  margin-right: 6px;
}
