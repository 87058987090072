._3a08N {
  --button-size: 20px;
  --icon-size: 24px;

  position: relative;

  width: var(--button-size);
  height: var(--button-size);
  min-height: var(--button-size);

  padding: 2px;

  background: none;
}

._11_h4 {
  position: absolute;
}

._1zp_Y {
  position: absolute;
  top: 26px;
  right: -4px;
  width: 142px;
}
