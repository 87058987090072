.GmFl7 {
  font-size: 15px;
  line-height: 22px;
  color: var(--black);
  font-weight: 500;
  margin-right: 16px;
}

._2JDgu {
  margin-bottom: 16px;
}

._1AX30 {
  padding: 0 0 24px;
  border-bottom: 1px solid rgba(16, 16, 16, 0.08);
}

._1AX30:last-child {
  border-bottom: none;
}

._2o7x4 {
  margin: -4px -12px;
  width: 390px;
}

.ePoKR {
  height: 30px;
}

._2jSJw {
  width: 310px;
  margin-right: 16px;
}

._2Jr0I {
  width: 54px;
  text-align: right;
  margin-right: -26px;
  padding-right: 15px;
}

._2Jr0I::-webkit-outer-spin-button,
._2Jr0I::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

._25seK {
  margin-top: -2px;
  color: var(--grey);
  pointer-events: none;
}

._3H0pW {
  margin-bottom: 24px;
}

._11rHG {
  box-shadow: 0 0 0 2px rgba(240, 96, 64, 0.4), 0 0 0 1px rgba(16, 16, 16, 0.08),
    0 1px 0 0 rgba(16, 16, 16, 0.04) !important;
  color: #f06040 !important;
}

._2Png4 {
  border-top: 1px solid rgba(16, 16, 16, 0.08);
  padding: 20px 0 8px;
}

._3gx21 {
  font-size: 12px;
  line-height: 16px;
  color: var(--black);
  opacity: 0.3;
  margin-left: 16px;
  width: 192px;
}

.EFTx1 {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  color: var(--black);
}

._22zcP {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--black);
  padding: 22px 0 18px;
}

._3tv30 {
  font-size: 15px;
  line-height: 16px;
  color: #979797;
}

._2yVbB {
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
}

._2yVbB th {
  height: 32px;
  font-size: 15px;
  line-height: 16px;
  color: #979797;
  font-weight: normal;
}

._2yVbB th:not(:last-child) {
  min-width: 116px;
}

._2yVbB td {
  height: 46px;
  font-size: 15px;
  line-height: 18px;
  color: var(--black);
}

._2yVbB tbody tr {
  border-top: 1px solid #f1f1f1;
}

._13iZ4 {
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: var(--black);
}

._3qD17 {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--black);
  opacity: 0.3;
  width: 150px;
}

.PrVvj {
  position: relative;
  margin-bottom: 16px;
}

._2crJY {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--zindex-over-content);
  background-color: rgba(255, 255, 255, 0.8);
}

._3pksz {
  margin-left: 8px;
}

._3YY0N {
  color: var(--green);
}

._3enDe {
  white-space: nowrap;
}

._31xdZ {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.z4f7W {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 600;
}

._2UjrC {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
}
