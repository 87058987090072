._3Fytt {
  background: var(--white);
  border: 1px solid var(--grayLight20);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 18px 28px;
  width: 100%;
}

._26lPf {
  padding: 16px;
  width: 343px;
}

.vdvjH {
  margin-left: auto;
}

.b63Vw {
  width: 100%;
}
