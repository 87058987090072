._1UYhF {
  height: 50px;
  padding: 0 24px;
  position: relative;
  top: -24px;
  left: -24px;
  width: calc(100% + 48px);
  z-index: 1;
  background-color: var(--pistachio);
  border-radius: 8px 8px 0 0;
}

._1Yn3p {
  flex: 0 0 auto;
  width: 18px;
  height: 20px;
  margin-right: 12px;
}

._2eBVl {
  flex: 1 1 100%;
  color: var(--black);
}

._3tvsm {
  flex: 0 0 auto;
}

._3tvsm path {
  fill: var(--black);
}
