._1FEtf {
  display: block;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  text-align: left;
}

._1FEtf[disabled] {
  pointer-events: none;
  opacity: 0.6;
}
