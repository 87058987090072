._1RzVD {
  width: 476px;
  position: relative;
  flex: none;
}

._3dFa- {
  margin: -10px 0 -10px 16px;
  width: 400px;
  flex: none;
}

._21kM6 {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: var(--red);
  vertical-align: -2px;
}
