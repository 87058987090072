._1uCA6 {
  width: 280px;
  background-color: var(--c-base-extralight);
}

._1uCA6._3DCx2 {
  width: 100%;
}

._38PdE {
  width: calc(100vw - 32px);
}

.FRIIE {
  background-color: var(--c-base-extralight);
  border-radius: 2px;
  padding: 10px;
}
