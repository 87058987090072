._1MaMG {
  display: flex;
  align-items: center;
  transition-duration: 200ms;
  transform-origin: center center;
  position: relative;
  top: 1px;
  outline: none;
  margin: 8px 8px 8px 0;
}

._1MaMG path {
  fill: var(--black);
}

._2LB53 {
  width: 100%;
  text-align: left;
}

._2SB_n {
  min-height: 55px;
}
