._1pb7n {
  background: var(--white);
  display: flex;
  gap: 8px;
  padding: 12px 8px;
  box-shadow: 0 0 0 0 rgba(21, 36, 66, 0.25),
    0 8px 32px 0 rgba(21, 36, 66, 0.25);
  border-radius: 8px;
  z-index: 1;
}
