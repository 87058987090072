._2l50R {
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  color: var(--greyDark);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

._1taCa {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 8px;
}
