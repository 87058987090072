._1SUwc {
  display: block;
  width: 16px;
  height: 16px;
  pointer-events: none !important;
}

._1SUwc * {
  pointer-events: none !important;
}

._3l7Yg {
  background-color: white;
  border-radius: 50%;
}

._2SNMb {
  background-color: white;
  box-sizing: border-box;
  border-radius: 4px;
}
