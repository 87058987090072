._2_Qkq {
  position: absolute;
  right: -42px;
  top: 50%;
  z-index: 1;
  transition: opacity 300ms ease-in-out;
}

._2v8KF {
  position: absolute;
  right: 0;
  bottom: 0;
  stroke: var(--c-base-sub);
  transition: stroke 1s;
}

._2s7Cc {
  opacity: 1;
}

._1URsW {
  opacity: 0;
}

._2GnDe {
  z-index: 2;
}

._2GnDe.mcoca {
  stroke: var(--blue);
}

._2GnDe._3NeFb {
  stroke: #be5880;
}

._2GnDe._1Bb0M {
  stroke: var(--c-accent2-normal);
}
