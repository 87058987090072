._234hm {
  margin: -4px -12px;
}

._37k60 {
  width: 294px;
}

@media (max-width: 830px) {
  ._37k60 {
    width: auto;
  }
}
