._2hxdl {
  width: calc(50% - 16px);
  margin-top: 24px;
}

.DXKhs {
  padding-left: 12px;
}

@media (max-width: 720px) {
  ._2hxdl {
    width: 100%;
    margin-top: 16px;
  }

  ._2GbQY {
    width: 100%;
    height: 44px;
  }
}
