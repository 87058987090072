._2vwP1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

._3_8W2 {
  width: 100%;
  text-align: center;
}
