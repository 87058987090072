._2mN8U {
  position: absolute;
  width: auto;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
}

.JYqOJ {
  height: 100%;
  width: 100%;
}

._12VzN {
  flex: 1;
}

._3z7KM {
  height: 100%;
}
