._1abHY {
  height: 420px;
}

._3lLQ6 {
  height: 300px;
}

._2LSLX {
  max-width: 280px;
}
