._22UG3 {
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  font-size: 15px;
  font-weight: 500;
  font-family: inherit;
  line-height: 1.1;
  white-space: nowrap;

  /** reset defaults: */
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
}

._22UG3._21jOg {
  min-width: 96px;
}

._22UG3:disabled {
  cursor: default;
  background-color: transparent;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}

._2ERGl._2MzPA,
._2MzPA {
  padding-left: 0;
}

._2ERGl._1xJc5,
._1xJc5 {
  padding-right: 0;
}

.PLxrI {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._3ToL3 {
  text-align: left;
}

.PLxrI._14p5G {
  justify-content: center;
}

._1GmfX {
  display: block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 2px;
  white-space: pre;
}

._3d8Nd {
  display: flex;
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin: 4px;
  align-items: center;
  justify-content: center;
}

._22UG3:disabled ._3d8Nd {
  opacity: 0.4;
}

._2ERGl ._3d8Nd {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

._3d8Nd._1Yp_C {
  margin-right: 0;
}

._3d8Nd.uN49l {
  margin-left: 0;
}

._2ERGl {
  height: 24px;
  min-height: 24px;
  padding: 0 8px;
}

._3Deh0 {
  height: 28px;
  min-height: 28px;
}

._2ERGl ._3d8Nd,
._3Deh0 ._3d8Nd {
  margin: 0;
}

._12GBC,
._2qGEy {
  height: 36px;
  min-height: 36px;
}

._2qGEy ._14p5G {
  min-width: 96px;
}

.N3ihm {
  z-index: -1;
}

._3se31 {
  width: 100%;
}
