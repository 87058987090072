.JCTJG {
  padding: 20px 24px 24px;
  border-radius: 4px;
  background-color: var(--c-base-dark);
  background-position: right top;
  background-repeat: no-repeat;
}

._26ykE {
  border-radius: 4px;
  background: linear-gradient(240deg, #0e68d9 0%, #002876 100%);
}

._3ASlU {
  margin-left: 22px;
  max-width: 100%;
  max-height: 180px;
}

._3fsWu {
  margin: 0 24px;
}

._35vy9 {
  margin-left: 20px;
  margin-right: auto;
}
