._3HE_e {
  height: calc(100% - 88px);
  overflow: hidden auto;
}

._382GZ {
  width: 234px;
  background-color: var(--blueLight30);
  border: 2px solid var(--blueLight30);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

._382GZ._3-UIe {
  width: 100%;
}

._382GZ._3xpIk {
  border: 2px solid var(--c-accent1-normal);
}

._382GZ[disabled] {
  pointer-events: none;
}

._382GZ[disabled] > * {
  opacity: 0.4;
}

._2DR30 {
  width: 100%;
}

._32AVB {
  width: 16px;
  height: 16px;
  background: var(--white);
  border: 1px solid var(--blue);
  border-radius: 8px;
  flex: none;
}

._32AVB._3xpIk {
  border-width: 4px;
}

._31zzc {
  padding: 0 0 0 15px;
  margin: 0;
}
