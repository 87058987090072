._29FkG {
  position: relative;
  min-width: 204px;
  width: 100% !important;
  max-width: 260px;
  height: 322px;
  border-radius: 12px;
  box-sizing: border-box;
  background-color: var(--white);
}

._1GbB9 {
  margin: -10px;
}

.gyY42 {
  position: absolute !important;
  width: 78%;
  bottom: 10px;
}
