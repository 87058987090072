._1hxVv {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

._1MANn {
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
}

._3liE4 {
  width: 100%;
  max-width: 360px;
}

._3yBKV {
  max-width: 260px;
}
