._3llvq {
  pointer-events: none;
}

._3llvq * {
  pointer-events: auto;
}

._29IJi {
  width: 36px;
  height: 36px;
  background-color: var(--white);
  border: 1px solid rgba(16, 16, 16, 0.08);
  border-radius: 50%;
  outline: none;
}

._1CoPq {
  z-index: var(--zindex-popover);
}
