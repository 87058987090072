._7O9tj {
  flex: 1 1 0;
}

._2SLlI {
  flex-grow: 1;
}

.GUWIz {
  flex-grow: 1;
  min-height: 0; /* super important... for FF */
  height: 0; /* for IE */
}

._2OzE1 {
  flex-grow: 1;
  min-width: 0;
}

.i9Lsp {
  flex-grow: 0;
}

.cqaH1 {
  flex-shrink: 1;
}

._1n9AE {
  flex-shrink: 0;
}
