._1ZKfC {
  text-transform: uppercase;
  outline: none;
  letter-spacing: 0.5px;
  color: #373636;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 6px 0 4px;
  min-width: 0;
  outline: none;
}

div.FpYNw {
  min-height: 21px;
  margin: -1px 0 0 -4px;
  border-radius: 2px;
}

._31GwZ {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
}

._16wD8 {
  border: none;
  height: 21px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #373636;
  text-transform: uppercase;
  padding: 0 0 0 4px;
  background-color: transparent;
  max-width: 100%;
}

._2mcDD {
  display: flex;
  align-items: center;
  height: 12px;
  border-radius: 4px;
  background-color: #53c286;
  padding: 0 3px;
  font-size: 8px;
  font-weight: 600;
  line-height: 8px;
  letter-spacing: 0.5px;
  color: var(--white);
  position: relative;
  bottom: -2px;
  right: -2px;
}

._2mcDD._3_4U_ {
  background-color: #e9604a;
}

._2erW0 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
